import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getVestingsForGrant, useVestings } from "../queries/dashboard";
import { format } from "date-fns";
import { Grant, VestingProjection } from "../types/onBoarding";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      title: { display: false, text: "Date" },
      stacked: true,
      ticks: {
        maxRotation: 0,
        align: "start",
        crossAlign: "start",
      },

    },
    y: {
      title: { display: false, text: "Grants" },
      stacked: true,
    },
  },
};

export function VestingChart() {
  const { filteredVesting } = useVestings();
  const data: ChartData<"bar", number[], unknown> = {
    labels: filteredVesting?.map((v: VestingProjection) => {
      const label = format(new Date(v.date), "MMM`yy");
      return label;
    }),
    datasets: [
      {
        label: "to date",
        data: filteredVesting.map(
          (v: VestingProjection) =>
            v.accumulatedVestedOptionsForGrant - v.vestedOptions
        ),
        backgroundColor: "#E85936", //869EDD
        borderRadius: 500,
      },
      {
        label: "on date",
        data: filteredVesting.map((v: VestingProjection) => v.vestedOptions),
        backgroundColor: "#3E2A35",
        borderRadius: 500,
      },
    ],
  };
  return <Bar options={options as any} data={data} />;
}

export function VestingChartGrant({ grant }: { grant?: Grant }) {
  const vestings = getVestingsForGrant(grant);
  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings?.map((v: VestingProjection) => {
      const label = format(new Date(v.date), "MMM`yy");
      return label;
    }),
    datasets: [
      {
        label: "to date",
        data: vestings.map(
          (v: VestingProjection) =>
            v.accumulatedVestedOptionsForGrant - v.vestedOptions
        ),
        backgroundColor: "#E85936", //869EDD
        borderRadius: 500,
      },
      {
        label: "on date",
        data: vestings.map((v: VestingProjection) => v.vestedOptions),
        backgroundColor: "#3E2A35",
        borderRadius: 500,
      },
    ],
  };
  return <Bar options={options as any} data={data} />;
}
