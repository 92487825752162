import { useEffect } from "react";
import { VStack, Box } from "../components/utils";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Check } from "akar-icons";
import { useDashboard } from "../queries/dashboard";

const Verified: React.FC = () => {
  const router = useIonRouter();
  const { data } = useDashboard();
  useEffect(() => {
    if (router.routeInfo.pathname === "/verified") {
      setTimeout(() => {
        const userName = data?.data?.fullName;
        if (userName) {
          const company = data?.data.employers || [];
          if (company?.length > 0) {
            router.push("/tabs/home");
          }
        } else {
          router.push("/profileInfo");
        }
      }, 1500);
    }
  }, [data, router]);

  return (
    <IonPage>
      <IonContent>
        <VStack className="mt-72 items-center mx-auto max-w-md">
          <Box className="bg-green p-6 rounded-full">
            <Check
              className="animate-tick text-white"
              strokeWidth={3}
              size={96}
            />
          </Box>
          <h6 className="mt-6 text-xl font-medium">Verified!</h6>
        </VStack>
      </IonContent>
    </IonPage>
  );
};

export default Verified;
