import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import AttachFile from "../assets/attachFile.svg";
import {
  ButtonPrimary,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useDashboard, useUpdateGrant } from "../queries/dashboard";
import { format } from "date-fns";
import {
  GrantOnboarding,
  useAddOrEditGrantForm,
} from "../store/useAddGrantForm";
import { useCompany } from "../store/useCompany";
import { useEffect } from "react";
import { getVestingScheduleWithCustomTemplates } from "../utils";
import { EmployerState, GrantEventType } from "../types/onBoarding";

const EditOldGrant: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const { state: company } = useCompany();
  const id = router.routeInfo.pathname.split("/")[2];
  const { mutate: updateGrant } = useUpdateGrant();
  const { data } = useDashboard();
  let employers = data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [];
  if (company && company !== "All") {
    employers = employers.filter((employer) => employer.name === company);
  }
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const employer = employers.find(
    (employer) => employer.id === grant?.employerId
  );
  const customVestingSchedules = employer?.customTemplates || [];
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useAddOrEditGrantForm();
  useEffect(() => {
    setFieldValue("noOfOptions", grant?.noOfOptions);
    setFieldValue("vestedOptions", grant?.events
      .filter((e) => e.type === GrantEventType.Vesting)
      .map((e) => e.noOfOptions)[0]);
    setFieldValue("exercisedOptions", grant?.events
      .filter((e) => e.type === GrantEventType.Exercise)
      .map((e) => e.noOfOptions)[0]);
    setFieldValue("strikePrice", grant?.strikePrice);
    setFieldValue("lastDateToExercise", grant?.lastDateToExercise);
    setFieldValue("employerId", grant?.employerId);
    setFieldValue("grantState", "Accepted");
    setFieldValue("name", grant?.name);
    setFieldValue("id", grant?.id);
  }, [grant, setFieldValue]);

  const fields = [
    "noOfOptions",
    "strikePrice",
    "grantDate",
    "vestingScheduleName",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }
  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const grant: GrantOnboarding = {
      id: form.id || 0,
      name: form.name,
      noOfOptions: form.noOfOptions,
      strikePrice: form.strikePrice,
      exercisedOptions: form.exercisedOptions,
      vestedOptions: form.vestedOptions,
      lastDateToExercise: form.lastDateToExercise,
      vestingScheduleName: form.vestingScheduleName || "",
      vestingSchedule: getVestingScheduleWithCustomTemplates(
        form.vestingScheduleName,
        customVestingSchedules
      ),
      grantDate: form.grantDate,
      grantState: form?.grantState,
      employerId: form?.employerId || 0,
      employerName: "",
      employerState: EmployerState.Resigned,
      currency: form.currency,
      latestSharePrice: 0,
    };
    updateGrant(grant, {
      onSuccess: () => {
        router.push("/tabs/grants");
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft
                className="w-6"
                size={20}
                onClick={() => router.goBack()}
              />
            )}
            <p className="text-primary text-lg font-semibold">
              Edit Grant Details
            </p>

            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <VStack className="w-full gap-3 mt-4">
            <Label className="font-medium">Granted Options</Label>
            <Input
              onChange={(e) => {
                setFieldValue(
                  "noOfOptions",
                  parseInt(e.target.value, 10)
                );
              }}
              onBlur={(e) => {
                setFieldTouched("noOfOptions");
              }}
              value={form.noOfOptions}
              type="number"
            />
            <Error
              visible={
                !!(touched?.noOfOptions && errors?.noOfOptions)
              }
              text={errors?.noOfOptions}
            />
            <Label className="font-medium">Vested Options</Label>
            <Input
              onChange={(e) => {
                setFieldValue(
                  "vestedOptions",
                  parseInt(e.target.value, 10)
                );
              }}
              onBlur={(e) => {
                setFieldTouched("vestedOptions");
              }}
              value={form.vestedOptions}
              type="number"
            />
            <Error
              visible={
                !!(touched?.vestedOptions && errors?.vestedOptions)
              }
              text={errors?.vestedOptions}
            />
            {form.vestedOptions > 0 && (
              <>
                <Label className="font-medium">Exercised Options</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue(
                      "exercisedOptions",
                      parseInt(e.target.value, 10)
                    );
                  }}
                  onBlur={(e) => {
                    setFieldTouched("exercisedOptions");
                  }}
                  value={form.exercisedOptions}
                  type="number"
                />
                <Error
                  visible={
                    !!(touched?.exercisedOptions && errors?.exercisedOptions)
                  }
                  text={errors?.exercisedOptions}
                />
              </>
            )}
            <Label className=" font-medium">Strike Price</Label>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) =>
                  setFieldValue("currency", e.target.value)
                }
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue(
                    "strikePrice",
                    parseFloat(e.target.value)
                  );
                }}
                onBlur={(e) => {
                  setFieldTouched("strikePrice");
                }}
                value={form.strikePrice}
                type="number"
              />
            </HStack>
            <Error
              visible={
                !!(touched?.strikePrice && errors?.strikePrice)
              }
              text={errors?.strikePrice}
            />
            {form.vestedOptions > 0 && <>
              <Label className=" w-full font-medium">Last Date to Exercise</Label>
              <Input
                onChange={(e) => {
                  setFieldValue("lastDateToExercise", new Date(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("lastDateToExercise");
                }}
                value={format(new Date(form?.lastDateToExercise || new Date()), "yyyy-MM-dd")}
                type="date"
              />
            </>}
            <VStack className="items-center gap-1">
              <HStack className="gap-2 mt-6">
                <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                <p className="text-secondary text-xs font-medium">
                  Attach your grant letter
                </p>
              </HStack>
              <p className="text-xs">(optional)</p>
            </VStack>
            <HStack className="mt-28 items-center justify-center">
              <ButtonPrimary
                className=" px-8"
                onClick={submitForm}
              >
                Next
              </ButtonPrimary>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditOldGrant;
