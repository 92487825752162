import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { useAuthStore, initAuth } from "../store/useAuthStore";

function ReturnToSignUpPage() {
  const { noOfLogins } = useAuthStore().state;
  const authToBeSet = { ...initAuth, noOfLogins };
  useAuthStore.getState().setState(authToBeSet);
  window.location.href = process.env.PUBLIC_URL + "signUp";
}

const empApi = axios.create({
  baseURL:
    localStorage.getItem("empApiUrl") ||
    process.env.REACT_APP_NODE_API ||
    "http://localhost:4000/v1",
});
empApi.defaults.headers.post["Content-Type"] = "application/json";
empApi.interceptors.request.use((config) => {
  const accesstoken = useAuthStore.getState().state.accessToken;
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).companyId =
      localStorage.getItem("companyId") || "";
    (config.headers as AxiosRequestHeaders).Authorization = accesstoken;
  }

  return config;
});
empApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      ReturnToSignUpPage();
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (!error.response || error.response.status === 401) {
      ReturnToSignUpPage();
    }
    return Promise.reject(error);
  }
);

export default empApi;
