import {
  IonContent,
  IonImg,
  IonPage,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  Box,
  ButtonPrimary,
  HStack,
  Input,
  Label,
  VStack,
  Error,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useAuthenticationForm } from "../store/useAuthenticationForm";
import { SignUp } from "../types/onBoarding";
import { useCheckWaitList, useGetOtp } from "../queries/dashboard";
import { useAuthStore } from "../store/useAuthStore";

const AuthPage: React.FC = () => {
  const setAuth = useAuthStore().setState;
  const router = useIonRouter();
  const { touched, errors, setFieldTouched, form, setFieldValue } =
    useAuthenticationForm();
  const [present] = useIonToast();
  function touchAllFields() {
    setFieldTouched("id");
  }
  const { mutate: checkWaitList } = useCheckWaitList();
  const { mutate: getOtp } = useGetOtp();
  function checkWaitListAndReferalCode() {
    touchAllFields();
    if (!Object.keys(errors).length) {
      const signUpDto: SignUp = {
        phoneNumber: parseInt(form.id, 10)
          ? parseInt(form.id, 10).toString()
          : "",
        referralCode: localStorage.getItem("inviteCode") || "",
        emailId: parseInt(form.id, 10) ? "" : form.id,
      };
      checkWaitList(signUpDto, {
        onSuccess: (data) => {
          if (data.data.isInWaitList || data.data.isReferred) {
            getOtp(signUpDto, {
              onSuccess: (data) => {
                setAuth((prev) => ({ ...prev, userId: data.data.userId }));
                localStorage.removeItem("inviteCode");
                router.push("/otp");
              },
              onError: () => {
                present({
                  message: "Something went wrong",
                  duration: 1500,
                  position: "bottom",
                  color: "danger",
                  mode: "ios",
                  animated: true,
                });
              },
            });
          } else {
            router.push("/inviteCode");
            localStorage.removeItem("inviteCode");
          }
        },
        onError: () => {
          present({
            message: "Something went wrong",
            duration: 1500,
            position: "bottom",
            color: "danger",
            mode: "ios",
            animated: true,
          });
        },
      });
    }
  }
  return (
    <IonPage>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <button className="p-3 mt-1 text-black">
            <ChevronLeft size={20} onClick={() => router.goBack()} />
          </button>
          <VStack className="items-center">
            <IonImg className="w-[139px] h-[50.55px] mt-12" src={HissaIcon} />
            <HStack className="justify-center w-full mt-4">
              <h6 className="text-lg font-medium">Invite-Only</h6>
            </HStack>
            <VStack className="px-4">
              <HStack className="mt-14 w-full">
                <h6 className="text-lg font-medium">
                  Welcome, let's get you signed up!
                </h6>
              </HStack>
              <VStack className="mt-9 text-dark w-full gap-2">
                <Label className="font-medium">Mobile Number/Email ID</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("id", e.target.value);
                  }}
                  onBlur={(e) => {
                    setFieldTouched("id");
                  }}
                  value={form.id}
                />
                <Error
                  visible={!!(touched.id && errors.id)}
                  text={errors?.id}
                />
              </VStack>
              <Box className="mt-2 text-sm font-medium text-black">
                By clicking, I accept the
                <span className="underline"> terms of service</span> and
                <span className="underline"> privacy policy</span>
              </Box>
            </VStack>
            <ButtonPrimary
              className="px-12 mt-32"
              onClick={checkWaitListAndReferalCode}
            >
              Next
            </ButtonPrimary>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AuthPage;
