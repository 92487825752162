import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import { Box, ButtonSecondary, HStack, VStack } from "../components/utils";
import { BannerSlides } from "./BannerSlides";
import { Question } from "akar-icons";

const ChooseActions: React.FC = () => {
  const router = useIonRouter();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            <div className="w-6"></div>
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <Question className="w-6" strokeWidth={2} size={20} />
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <BannerSlides />
          <h4 className="mt-11 w-full px-1 text-lg font-medium">
            Choose your purpose:
          </h4>
          <VStack className="gap-7 mt-7 text-xl font-normal">
            <ButtonSecondary
              className="py-5"
              onClick={() => {
                router.push("/evaluateGrantDetails");
              }}
            >
              Evaluate an offer
            </ButtonSecondary>
            <ButtonSecondary
              className="py-5"
              onClick={() => {
                router.push("/grantInformation");
              }}
            >
              Track your current ESOPs
            </ButtonSecondary>
            <ButtonSecondary
              className="py-5"
              onClick={() => {
                router.push("/oldCompanyGrantInformation");
              }}
            >
              Track your previous company ESOPs
            </ButtonSecondary>
          </VStack>
          <Box className="text-dark bg-rose-100 py-4 mt-20 text-xs font-normal text-center">
            Please provide the above information to make the app more
            personalised for you
          </Box>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChooseActions;
