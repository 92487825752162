import * as React from "react";

export function HStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function ButtonPrimary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { disabled, className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `h-12 rounded-lg text-white bg-primary focus:opacity-90 hover:bg-primary cursor-pointer whitespace-nowrap justify-center 
    ${disabled && "cursor-not-allowed bg-gray-300 hover:bg-gray-300"
    } ${className}`;
  return (
    <button
      disabled={disabled}
      className={_className}
      type={_type}
      {...rest}
    ></button>
  );
}

export function ButtonPrimary1(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `border border-solid border-secondary font-medium rounded-lg h-12 text-secondary focus:opacity-90   ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonSecondary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `w-full bg-white border border-solid border-indigo-300 rounded-xl shadow-md focus:text-primary focus:border-primary ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function Error({
  text,
  visible,
}: {
  text?: string | number;
  visible: boolean;
}) {
  return (
    <p className={`text-red my-0.5 ${visible ? " visible " : " hidden "}`}>
      {text}
    </p>
  );
}

export function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `form-input ${_className}`;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}

export function ProfilePageInput(
  props: React.InputHTMLAttributes<HTMLInputElement>
) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `focus:outline-secondary border-secondary text-center border rounded-md  ${_className}`;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}

export function Label(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = `form-label w-full `;
  return <label {...rest} className={`${defaultClass + _className} `}></label>;
}


