import { addMonths } from "date-fns";
import _ from "lodash";
import { GrantEventType } from "../../types/onBoarding";

type VestingTemplateInput = {
  noOfOptions: number;
  startDate: Date;
  cliffPeriod: number;
  schedules: Schedule[];
};

type Schedule = {
  srNo: number;
  percentage: number;
  interval: number;
  period: number;
};

export const baseVesting = {
  cliffDate: false,
  date: new Date(2022, 1, 1),
  intervalsPassed: 0,
  vestedPercentage: 0,
  isVestDate: false,
  vestedOptions: 0,
  type: GrantEventType.Vesting,
  accumulatedVestingPercentageForGrant: 0,
  accumulatedVestedOptionsForGrant: 0,
};

export function generateProjections({
  noOfOptions = 10000,
  startDate = new Date(2022, 1, 1),
  cliffPeriod,
  schedules,
}: VestingTemplateInput) {
  let accumulatedVestingPercentageBeforeCliff = 0;
  let intervalsPassed = 0;
  let vestedOptions = 0;
  let vestingPercentage = 0;
  let accumulatedVestedOptionsForGrant = 0;
  let accumulatedVestingPercentageForGrant = 0;
  let vestedOptionsSoFarForGrant = 0;
  let currentDate = startDate;
  const vestings = [];

  const sortedSchedules = _.sortBy(schedules, (s) => s.srNo);
  for (const schedule of sortedSchedules) {
    if (schedule.period && schedule.interval) {
      let noOfEventsInSchedule = 1;

      if (schedule.period === 0 || schedule.interval === 0) {
        noOfEventsInSchedule = 1;
      } else {
        noOfEventsInSchedule = schedule.period / schedule.interval;
      }

      vestings.push({
        date: currentDate,
        vestedPercentage: 0,
        intervalsPassed: 0,
        cliffDate: false,
        vestedOptions: 0,
        accumulatedVestedOptionsForGrant: 0,
        accumulatedVestingPercentageForGrant: 0,
      });

      for (let event = 1; event <= noOfEventsInSchedule; event++) {
        const vesting = { ...baseVesting };
        intervalsPassed += schedule.interval;

        if (intervalsPassed < cliffPeriod) {
          accumulatedVestingPercentageBeforeCliff =
            (schedule.percentage / noOfEventsInSchedule) *
            (intervalsPassed / schedule.interval);
        } else {
          if (intervalsPassed === cliffPeriod) {
            vesting.cliffDate = true;
          }

          vestingPercentage =
            schedule.percentage / noOfEventsInSchedule +
            accumulatedVestingPercentageBeforeCliff;
          accumulatedVestingPercentageBeforeCliff = 0;
          currentDate = addIntervalsToDate(startDate, intervalsPassed);

          vesting.date = currentDate;
          vesting.intervalsPassed = intervalsPassed;
          vesting.vestedPercentage = vestingPercentage;
          vesting.isVestDate = true;
          vestings.push(vesting);
        }
      }
    }
  }

  const sortedVestings = _.sortBy(vestings, (v) => new Date(v.date));
  for (const vesting of sortedVestings) {
    accumulatedVestingPercentageForGrant += vesting.vestedPercentage;
    accumulatedVestedOptionsForGrant = Math.floor(
      roundOptions(
        roundPercentage(accumulatedVestingPercentageForGrant) * noOfOptions
      )
    );
    vestedOptions =
      accumulatedVestedOptionsForGrant - vestedOptionsSoFarForGrant;
    vestedOptionsSoFarForGrant += vestedOptions;

    vesting.vestedOptions = vestedOptions;
    vesting.accumulatedVestingPercentageForGrant =
      accumulatedVestingPercentageForGrant;
    vesting.accumulatedVestedOptionsForGrant = accumulatedVestedOptionsForGrant;
  }

  return vestings;
}

function addIntervalsToDate(startDate: Date, intervalsPassed: number): Date {
  return addMonths(startDate, intervalsPassed);
}

function roundPercentage(x: number) {
  return roundToPlaces(x, 10);
}

function roundOptions(x: number) {
  return roundToPlaces(x, 3);
}

function roundToPlaces(x: number, places: number) {
  const scaledNumber = x * 10 ** places;
  return Math.round(scaledNumber) / 10 ** places;
}
