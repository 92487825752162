/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import { MenuItem, Select } from "@mui/material";
import AttachFile from "../assets/attachFile.svg";
import {
  ButtonPrimary,
  Center,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronDown, ChevronLeft } from "akar-icons";
import _ from "lodash";
import { useMemo, useEffect } from "react";
import { useDashboard, useAddGrant } from "../queries/dashboard";
import { useAddOrEditGrantForm } from "../store/useAddGrantForm";
import { useCompany } from "../store/useCompany";
import { format } from "date-fns";
import { EmployerState, GrantEventType } from "../types/onBoarding";
import { getEmployerIdFromName, getGrantName, getVestingScheduleWithCustomTemplates } from "../utils";

const AddNewGrant: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const { data: _data } = useDashboard();
  const { mutate: addGrant } = useAddGrant();
  const { state: company, setState: setCompany } = useCompany();
  const employers = useMemo(() => _data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [_data]);
  const employer = employers.find(e => e.name === company);
  const customVestingSchedules = employer?.customTemplates || [];
  const customVestingScheduleNames = customVestingSchedules.map(vestingSchedule => vestingSchedule.name);
  const vestingScheduleNames = [...customVestingScheduleNames,
    "1 Year Cliff, Monthly Vesting, 4 Years",
    "1 Year Cliff, Quarterly Vesting, 4 Years",
    "1 Year Cliff, Yearly Vesting, 4 Years",
    "Custom(Set as default, fill it out later)",
  ];
  useEffect(() => {
    if (!company) {
      if (employers.length > 0) {
        setCompany(employers[employers.length - 1].name);
      }
    }
  }, []);
  useEffect(() => {
    if (company) {
      setFieldValue("employerId", employers?.find((e) => e.name === company)?.id)
      setFieldValue("employerState", employers?.find((e) => e.name === company)?.state);
      setFieldValue("currency", employers?.find((e) => e.name === company)?.currency);
    }
  }, [])
  const router = useIonRouter();
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useAddOrEditGrantForm();
  const fields = [
    "noOfOptions",
    "strikePrice",
    "dateOfGrant",
    "vestingScheduleName",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }


  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }
  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const data = _.cloneDeep(form);
    data.name = getGrantName(employer?.grants || []);
    data.vestingSchedule = getVestingScheduleWithCustomTemplates(
      form.vestingScheduleName,
      customVestingSchedules
    );
    data.grantState = "Accepted";
    if (data.employerId === 0) {
      (router.push("/addNewCompany"));
    }
    else {
      addGrant(data, {
        onSuccess: () => {
          router.push("/tabs/grants");
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
  }
  const selectedEmployer = employers?.find((e) => e.id === form.employerId);
  const events = selectedEmployer?.grants?.map(grant => grant?.events.flat() || []).flat() || [];
  const vestingEvents = events.filter(e => e.type === GrantEventType.Vesting);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => {
                clearForm(form)
                router.goBack()
              }} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <h3 className="w-full px-1 mt-2 text-xl font-bold">Add Grant</h3>
          <VStack className="w-full gap-3 px-3 mt-4">
            <h1 className="text-dark font-semibold">Select Company</h1>
            <Select
              className=" h-14 items-center bg-white border rounded-lg shadow-md"
              value={form.employerId === 0 ? "addCompany" : employers?.find((e) => e.id === form.employerId)?.name}
              IconComponent={() => (<ChevronDown
                className="mx-4 text-black"
                strokeWidth={3}
                width={26}
                height={14}
              />)}
              onChange={(e) => {
                if (e.target.value === "addCompany") {
                  setFieldValue(
                    "employerId",
                    0
                  );
                }
                else {
                  setCompany(e.target.value);
                  setFieldValue(
                    "employerId",
                    getEmployerIdFromName(e.target.value, employers)
                  );
                }
              }}
            >
              {employers.map((employer) => (
                <MenuItem
                  key={employer?.id}
                  value={employer.name}
                >
                  <HStack className="items-center w-full gap-4">
                    <Center className="w-[30px] h-[30px] bg-gray-100 rounded-full">
                      {employer.name?.charAt(0)}
                    </Center>
                    {employer.name}
                  </HStack>
                </MenuItem>
              ))}
              <MenuItem
                key={0}
                value="addCompany"
              >
                <HStack className="items-center w-full gap-4">
                  + Add New
                </HStack>
              </MenuItem>
            </Select>
            <h6>Please enter the following information to add a grant to the above company</h6>
            {vestingEvents.length > 0 ? <>
              <Label className="font-medium">Granted Options</Label>
              <Input
                onChange={(e) => {
                  setFieldValue(
                    "noOfOptions",
                    parseInt(e.target.value, 10)
                  );
                }}
                onBlur={(e) => {
                  setFieldTouched("noOfOptions");
                }}
                value={form.noOfOptions}
                type="number"
              />
              <Error
                visible={
                  !!(touched?.noOfOptions && errors?.noOfOptions)
                }
                text={errors?.noOfOptions}
              />
              <Label className="font-medium">Vested Options</Label>
              <Input
                onChange={(e) => {
                  setFieldValue(
                    "vestedOptions",
                    parseInt(e.target.value, 10)
                  );
                }}
                onBlur={(e) => {
                  setFieldTouched("vestedOptions");
                }}
                value={form.vestedOptions}
                type="number"
              />
              <Error
                visible={
                  !!(touched?.vestedOptions && errors?.vestedOptions)
                }
                text={errors?.vestedOptions}
              />
              {form.vestedOptions > 0 && (
                <>
                  <Label className="font-medium">Exercised Options</Label>
                  <Input
                    onChange={(e) => {
                      setFieldValue(
                        "exercisedOptions",
                        parseInt(e.target.value, 10)
                      );
                    }}
                    onBlur={(e) => {
                      setFieldTouched("exercisedOptions");
                    }}
                    value={form.exercisedOptions}
                    type="number"
                  />
                  <Error
                    visible={
                      !!(touched?.exercisedOptions && errors?.exercisedOptions)
                    }
                    text={errors?.exercisedOptions}
                  />
                </>
              )}
              <Label className=" font-medium">Strike Price</Label>
              <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                <IonSelect
                  aria-label="fruit"
                  className="w-1/5 h-10 font-sans min-w-[60px]"
                  mode="ios"
                  interface="popover"
                  autoSave="yes"
                  value={form.currency}
                  onIonChange={(e) =>
                    setFieldValue("currency", e.target.value)
                  }
                >
                  {currencies.map((name) => (
                    <IonSelectOption key={name} value={name} className="text-xs">
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <input
                  className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                  onChange={(e) => {
                    setFieldValue(
                      "strikePrice",
                      parseFloat(e.target.value)
                    );
                  }}
                  onBlur={(e) => {
                    setFieldTouched("strikePrice");
                  }}
                  value={form.strikePrice}
                  type="number"
                />
              </HStack>
              <Error
                visible={
                  !!(touched?.strikePrice && errors?.strikePrice)
                }
                text={errors?.strikePrice}
              />
              {form.vestedOptions > 0 && <>
                <Label className=" w-full font-medium">Last Date to Exercise</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("lastDateToExercise", new Date(e.target.value));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("lastDateToExercise");
                  }}
                  value={format(new Date(form?.lastDateToExercise || new Date()), "yyyy-MM-dd")}
                  type="date"
                />
              </>}
              <VStack className="items-center gap-1">
                <HStack className="gap-2 mt-6">
                  <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                  <p className="text-secondary text-xs font-medium">
                    Attach your grant letter
                  </p>
                </HStack>
                <p className="text-xs">(optional)</p>
              </VStack>
            </> : (
              <>
                <Label className=" font-medium">No. of Options</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("noOfOptions", parseInt(e.target.value, 10));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("noOfOptions");
                  }}
                  value={form.noOfOptions}
                  type="number"
                />
                <Error
                  visible={!!(touched.noOfOptions && errors.noOfOptions)}
                  text={errors?.noOfOptions}
                />
                <Label className=" font-medium">Strike Price</Label>
                <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                  <IonSelect
                    aria-label="fruit"
                    className="w-1/5 h-10 font-sans min-w-[60px]"
                    mode="ios"
                    interface="popover"
                    autoSave="yes"
                    value={form.currency}
                    onIonChange={(e) =>
                      setFieldValue("currency", e.target.value)
                    }
                  >
                    {currencies.map((name) => (
                      <IonSelectOption key={name} value={name} className="text-xs">
                        {name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <input
                    className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                    onChange={(e) => {
                      setFieldValue("strikePrice", parseFloat(e.target.value));
                    }}
                    onBlur={(e) => {
                      setFieldTouched("strikePrice");
                    }}
                    value={form.strikePrice}
                    type="number"
                  />
                </HStack>
                <Label className=" w-full font-medium">Vesting Schedule</Label>
                <IonSelect
                  aria-label="fruit"
                  className="form-select"
                  placeholder="Select Vesting Schedule"
                  mode="ios"
                  interface="popover"
                  autoSave="yes"
                  value={form?.vestingScheduleName}
                  onIonChange={(e) => {
                    if (e.target.value === "Custom(Set as default, fill it out later)") {
                      router.push("/customVestingSchedule");
                    }
                    else {
                      setFieldValue("vestingScheduleName", e.target.value)
                    }
                  }
                  }
                >
                  {vestingScheduleNames.map((name) => (
                    <IonSelectOption key={name} value={name} className="text-xs">
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <Label className=" w-full font-medium">Date of Grant</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("grantDate", new Date(e.target.value));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("grantDate");
                  }}
                  value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
                  type="date"
                />
                <HStack className="items-center justify-center gap-2 mt-6">
                  <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                  <p className="text-secondary text-xs font-medium">
                    Attach your grant letter
                  </p>
                </HStack>
                <p className="mx-auto -mt-3 text-xs">(optional)</p>
              </>
            )}

            <HStack className="mt-28 items-center justify-center">
              <ButtonPrimary className=" px-8" onClick={submitForm}>
                Next
              </ButtonPrimary>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddNewGrant;
