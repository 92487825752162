import { CustomTemplate } from "../types/onBoarding";
import { zustandForm } from "./zustandForm";
import { z } from "zod";

const initialValues: CustomTemplate = {
  name: "",
  cliff: 0,
  timeSchedules: [],
  eventSchedules: [],
};

const validationSchema = z.object({});

export const useCustomVestingTemplateForm = zustandForm({
  initialValues,
  validationSchema,
});
