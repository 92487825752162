/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  ButtonPrimary,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useCustomVestingTemplateForm } from "../store/useAddCustomVestingTemplateForm";
import { getFieldProps } from "../store/zustandForm";
import { EmployerState, Schedule, Template } from "../types/onBoarding";
import _ from "lodash";
import { format } from "date-fns";
import { useAddCustomTemplate, useDashboard, useUpdateGrant } from "../queries/dashboard";
import { useCompany } from "../store/useCompany";
import { useEffect, useMemo } from "react";
import { GrantOnboarding } from "../store/useAddGrantForm";

const initialScheduleValues: Schedule = {
  srNo: 0,
  percentage: 0,
  interval: 0,
  period: 0,
  eventName: "",
  eventDate: undefined,
};

const CustomVestingSchedule: React.FC = () => {
  const router = useIonRouter();
  const { data: _data } = useDashboard();
  const { state: company, setState: setCompany } = useCompany();
  const { mutate: addCustomTemplate } = useAddCustomTemplate();
  const employers = useMemo(() => _data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [_data]);
  const employer = employers.find((e) => e.name === company);
  const grants = employer?.grants;
  const grantWithZeroPercentage = grants?.find(grant => {
    const schedules = grant.template?.schedules || [];
    const totalPercentage = _.sumBy(schedules, s => s.percentage);
    return totalPercentage === 0;
  });
  const { mutate: updateGrant } = useUpdateGrant();
  const { form, clearForm, setFieldTouched, setFieldValue } =
    useCustomVestingTemplateForm();
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
  }, []);
  const schedules = [...form.timeSchedules, ...form.eventSchedules];
  function handleAddTimeBased() {
    const timeSchedules = [...form.timeSchedules];
    const newSchedule = {
      ...initialScheduleValues,
      percentage: 0,
      triggerType: "Time",
      srNo: schedules.length + 1,
    };
    if (schedules.length > 0) {
      newSchedule.percentage = 100 - _.sumBy(schedules, (s) => s.percentage);
    } else {
      newSchedule.percentage = 100;
    }
    timeSchedules.splice(timeSchedules.length + 1, 0, newSchedule);
    setFieldValue("timeSchedules", timeSchedules);
  }

  function handleAddEventBased() {
    const eventSchedules = [...form.eventSchedules];
    const newSchedule = {
      ...initialScheduleValues,
      percentage: 0,
      triggerType: "Event",
      srNo: schedules.length + 1,
    };
    if (schedules.length > 0) {
      newSchedule.percentage = 100 - _.sumBy(schedules, (s) => s.percentage);
    } else {
      newSchedule.percentage = 100;
    }
    eventSchedules.splice(eventSchedules.length + 1, 0, newSchedule);
    setFieldValue("eventSchedules", eventSchedules);
  }

  function getTotalPeriod(schedules: Schedule[]) {
    return _.sumBy(schedules, (schedule) => schedule.period);
  }

  function getTotalPercentage(schedules: Schedule[]) {
    return _.sumBy(schedules, (schedule) => schedule.percentage);
  }
  const timeBasedSchedules = schedules.filter(
    (schedule) => schedule.triggerType === "Time"
  );
  const eventBasedSchedules = schedules.filter(
    (schedule) => schedule.triggerType === "Event"
  );
  function submitForm() {
    // touchAllFields();
    // const errors = checkErrors();
    // if (errors) {
    //   // show some toast
    //   return;
    // }
    const data = { ...form };
    const schedulesWithoutTriggerType = schedules.map(({ triggerType, ...rest }) => ({ ...rest, percentage: rest.percentage / 100 }));
    const finalData: Template = {
      name: data.name,
      cliff: data.cliff,
      employerId: employer?.id,
      schedules: schedulesWithoutTriggerType,
    };
    addCustomTemplate(finalData, {
      onSuccess: (data) => {
        if (grantWithZeroPercentage && employer) {
          const grant: GrantOnboarding = {
            currency: employer.currency || "₹",
            employerId: employer.id || 0,
            employerName: employer.name || "",
            exercisedOptions: grantWithZeroPercentage.exercisedOptions || 0,
            grantDate: grantWithZeroPercentage.grantDate || new Date(),
            grantState: grantWithZeroPercentage.grantState || "Accepted",
            id: grantWithZeroPercentage.id || 0,
            latestSharePrice: employer.latestSharePrice || 0,
            name: grantWithZeroPercentage.name || "",
            employerState: employer.state,
            noOfOptions: grantWithZeroPercentage.noOfOptions || 0,
            vestedOptions: grantWithZeroPercentage.vestedOptions || 0,
            lastDateToExercise: grantWithZeroPercentage.lastDateToExercise || new Date(),
            strikePrice: grantWithZeroPercentage.strikePrice || 0,
            vestingScheduleName: data.name,
            vestingSchedule: data
          }
          updateGrant(grant);
        }
        router.goBack();
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-7" size={20} onClick={() => router.goBack()} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <VStack className="mx-2">
            <h3 className="w-full px-1 mt-2 text-xl font-semibold">
              Add Custom Vesting Schedule
            </h3>
            <h4 className="text-grey w-full px-1 mt-1 text-xs font-medium">
              The total of the vesting schedule should come to hundred, if you
              have a combination of different vesting types, please change the
              percentage and add another.
            </h4>
            <VStack className="w-full gap-3 px-3 mt-3">
              <HStack className="gap-12">
                <VStack className="text-dark gap-2 text-sm">
                  <Label className=" font-medium">Vesting Cliff (Years)</Label>
                  <Input
                    {...getFieldProps("cliff", useCustomVestingTemplateForm())}
                    type="number"
                  />
                </VStack>
                <VStack className="text-dark gap-2 text-sm">
                  <Label className=" font-medium">Time Period (Months)</Label>
                  <Input value={getTotalPeriod(schedules)} disabled={true} />
                </VStack>
              </HStack>
              <HStack className="whitespace-nowrap items-center w-full gap-2 mt-5">
                <Label className="text-dark w-full font-medium">
                  Template Name:
                </Label>
                <Input
                  {...getFieldProps("name", useCustomVestingTemplateForm())}
                  type="text"
                />
              </HStack>
              <hr className="w-full border border-dashed"></hr>
              <VStack className=" gap-4">
                <HStack className="mt-9 justify-center">
                  <button
                    className="text-primary h-9 border-primary focus:opacity-90 whitespace-nowrap p-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
                    onClick={handleAddTimeBased}
                  >
                    +Add Time Based Vesting
                  </button>
                </HStack>
                <>
                  {timeBasedSchedules.map((schedule, index) => {
                    return (
                      <VStack key={index}>
                        <HStack className="gap-9">
                          <VStack className="text-dark gap-2 text-sm">
                            <Label className="whitespace-nowrap font-medium">
                              Vesting Interval (Months)
                            </Label>
                            <Input
                              onChange={(e) => {
                                setFieldValue(
                                  `timeSchedules[${index}].interval`,
                                  parseFloat(e.target.value)
                                );
                              }}
                              onBlur={(e) => {
                                setFieldTouched(
                                  `timeSchedules[${index}].interval`
                                );
                              }}
                              value={schedule.interval}
                              type="number"
                            />
                          </VStack>
                          <VStack className="text-dark gap-2 text-sm">
                            <Label className=" font-medium">
                              Duration (Months)
                            </Label>
                            <Input
                              onChange={(e) => {
                                setFieldValue(
                                  `timeSchedules[${index}].period`,
                                  parseFloat(e.target.value)
                                );
                              }}
                              onBlur={(e) => {
                                setFieldTouched(
                                  `timeSchedules[${index}].period`
                                );
                              }}
                              value={schedule.period}
                              type="number"
                            />
                          </VStack>
                        </HStack>
                        <h6 className="text-grey mt-4 text-sm">
                          This is{" "}
                          <input
                            className="focus:outline-secondary border-secondary w-12 px-3 py-1 font-medium text-center text-black border rounded-lg"
                            value={schedule.percentage}
                            onChange={(e) => {
                              setFieldValue(
                                `timeSchedules[${index}].percentage`,
                                parseFloat(e.target.value)
                              );
                            }}
                            onBlur={(e) => {
                              setFieldTouched(
                                `timeSchedules[${index}].percentage`
                              );
                            }}
                          />{" "}
                          % of your vesting schedule
                        </h6>
                      </VStack>
                    );
                  })}
                </>
                <hr className="w-full border border-dashed"></hr>
                <HStack className="justify-center">
                  <button
                    className="text-primary h-9 border-primary focus:opacity-90 whitespace-nowrap p-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
                    onClick={handleAddEventBased}
                  >
                    +Add Event Based Vesting
                  </button>
                </HStack>
                <>
                  {eventBasedSchedules.map((schedule, index) => {
                    return (
                      <VStack key={index} className="gap-3">
                        <Label className=" w-full font-medium">
                          Event Name
                        </Label>
                        <Input
                          onChange={(e) => {
                            setFieldValue(
                              `eventSchedules[${index}].eventName`,
                              e.target.value
                            );
                          }}
                          onBlur={(e) => {
                            setFieldTouched(
                              `eventSchedules[${index}].eventName`
                            );
                          }}
                          value={schedule.eventName}
                        />
                        <Label className=" w-full font-medium">
                          Target Date
                        </Label>
                        <Input
                          onChange={(e) => {
                            setFieldValue(
                              `eventSchedules[${index}].eventDate`,
                              new Date(e.target.value)
                            );
                          }}
                          onBlur={(e) => {
                            setFieldTouched(
                              `eventSchedules[${index}].eventDate`
                            );
                          }}
                          value={format(new Date(schedule?.eventDate || new Date()) || new Date(), "yyyy-MM-dd")}
                          type="date"
                        />
                        <h6 className="text-grey mt-4 text-sm">
                          This is{" "}
                          <input
                            className="focus:outline-secondary border-secondary w-12 px-3 py-1 font-medium text-center text-black border rounded-lg"
                            value={schedule.percentage}
                            onChange={(e) => {
                              setFieldValue(
                                `eventSchedules[${index}].percentage`,
                                parseFloat(e.target.value)
                              );
                            }}
                            onBlur={(e) => {
                              setFieldTouched(
                                `eventSchedules[${index}].percentage`
                              );
                            }}
                          />{" "}
                          % of your vesting schedule
                        </h6>
                      </VStack>
                    );
                  })}
                </>
              </VStack>
              <HStack className="justify-center">
                <ButtonPrimary
                  className="px-5 mt-16"
                  disabled={getTotalPercentage(schedules) !== 100}
                  onClick={submitForm}
                >
                  Create
                </ButtonPrimary>
              </HStack>
            </VStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CustomVestingSchedule;
