import { EmployerState } from "../types/onBoarding";
import { zustandForm } from "./zustandForm";
import { z } from "zod";

const scheduleSchema = z.object({
  percentage: z.number().default(100),
  interval: z.number().default(12),
  period: z.number().default(48),
});

const templateSchema = z.object({
  srNo: z.number(),
  name: z.string(),
  cliff: z.number(),
  schedules: scheduleSchema,
});

const grantSchema = z.object({
  noOfOptions: z.number().min(1),
  name: z.string(),
  vestedOptions: z.number(),
  exercisedOptions: z.number(),
  lastDateToExercise: z.date(),
  strikePrice: z.number(),
  vestingScheduleName: z.string(),
  grantDate: z.date(),
  vestingSchedule: templateSchema,
  grantState: z.string(),
  employerId: z.number(),
  currency: z.string().default("₹"),
  employerName: z.string(),
  latestSharePrice: z.number(),
});

const initialScheduleValues = {
  srNo: 1,
  percentage: 0,
  interval: 0,
  period: 0,
};

const initialGrantDetails = {
  id: 0,
  name: "",
  noOfOptions: 0,
  vestedOptions: 0,
  exercisedOptions: 0,
  lastDateToExercise: new Date(),
  strikePrice: 0,
  vestingScheduleName: "",
  vestingSchedule: {
    name: "",
    cliff: 12,
    schedules: [initialScheduleValues],
  },
  grantDate: new Date(),
  grantState: "Accepted",
  currency: "₹",
  employerId: 0,
  employerName: "",
  employerState: EmployerState.Active,
  latestSharePrice: 0,
};

export type GrantOnboarding = typeof initialGrantDetails;

export const useAddOrEditGrantForm = zustandForm({
  initialValues: initialGrantDetails,
  validationSchema: grantSchema,
});
