import { zustandPersistedState } from "./zustandState";

export type Auth = {
  isAuthenticated: boolean;
  showIntro: boolean;
  noOfLogins: number;
  accessToken?: string;
  userId?: string;
  email?: string;
  phone?: string;
};
export const initAuth: Auth = {
  isAuthenticated: false,
  showIntro: true,
  noOfLogins: 0,
};

export const useAuthStore = zustandPersistedState(initAuth, "auth");
