import {
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonIcon,
  IonTabs,
  IonRouterOutlet,
  iosTransitionAnimation,
  IonSplitPane,
} from "@ionic/react";
import HomeIcon from "../assets/home.svg";
import GrantsIcon from "../assets/grants.svg";
import EvaluateIcon from "../assets/evaluate.svg";
import ProfileIcon from "../assets/profile.svg";

import { Redirect, Route } from "react-router-dom";
import GrantsTab from "./GrantsTab";
import HomeTab from "./HomeTab";
import ProfileTab from "./ProfileTab";
import EvaluateTab from "./EvaluateTab";
import WealthForecast from "./WealthForecast";
import EvaluateOfferUploaded from "./EvaluateOfferUploaded";
import Menu from "./Menu";
import WealthForecastForGrant from "./WealthForecastForGrant";
const Tabs: React.FC = () => {
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonTabs>
        <IonRouterOutlet animation={iosTransitionAnimation} id="main">
          <Route exact path="/tabs/home">
            <HomeTab />
          </Route>
          <Route exact path="/tabs/grants">
            <GrantsTab />
          </Route>
          <Route exact path="/tabs/profile" component={ProfileTab} />
          <Route exact path="/tabs/evaluate" component={EvaluateTab} />
          <Route
            exact
            path="/tabs/evaluate/moneyForecast"
            component={WealthForecast}
          />
          <Route
            exact
            path="/tabs/evaluate/moneyForecast/:id"
            component={WealthForecastForGrant}
          />
          <Route
            exact
            path="/tabs/evaluate/offerUploaded"
            component={EvaluateOfferUploaded}
          />
          <Route exact path="/tabs">
            <Redirect to="/tabs/home" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/tabs/home">
            <IonIcon icon={HomeIcon} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="grants" href="/tabs/grants">
            <IonIcon icon={GrantsIcon} />
            <IonLabel>Grants</IonLabel>
          </IonTabButton>
          <IonTabButton tab="forecast" href="/tabs/evaluate">
            <IonIcon icon={EvaluateIcon} />
            <IonLabel>Evaluate</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/tabs/profile">
            <IonIcon icon={ProfileIcon} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs >
    </IonSplitPane>
  );
};

export default Tabs;
