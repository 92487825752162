/* eslint-disable jsx-a11y/img-redundant-alt */
import { Icon } from "@iconify/react";
import {
  IonMenu,
  IonContent,
  IonButtons,
  IonMenuToggle,
  useIonRouter,
  IonImg,
} from "@ionic/react";
import React from "react";
import { Box, Center, HStack, VStack } from "../components/utils";
import LockIcon from "../assets/lock.svg";
import { useDashboard } from "../queries/dashboard";
import { useHistory } from "react-router";
import { setUserId, track } from "@amplitude/analytics-browser";
import { decodeAuthToken } from "../utils";
import { useDebouncedCallback } from "use-debounce";
import { initAuth, useAuthStore } from "../store/useAuthStore";

function trackScreenChange(eventName: string) {
  const accesstoken = useAuthStore.getState().state.accessToken;
  if (accesstoken) {
    let { emailId } = decodeAuthToken(accesstoken);
    emailId = emailId.padStart(10, "_");
    setUserId(emailId);
  }
  track(eventName);
}

const Menu: React.FC = () => {
  const { setState: setAuth } = useAuthStore();
  const router = useIonRouter();
  const { data } = useDashboard();
  const debounced = useDebouncedCallback(trackScreenChange, 1500);
  const nav = useHistory();
  nav.listen((nav) => {
    debounced("Open Screen: " + nav.pathname);
  });
  function handleLogout() {
    setAuth(initAuth);
    window.location.href = process.env.PUBLIC_URL + "signUp"
  }
  return (
    <IonMenu contentId="main">
      <IonContent>
        <IonButtons>
          <IonMenuToggle className=" flex flex-col items-start w-full h-screen gap-8 p-4 text-sm font-medium">
            <HStack className="items-center w-full mt-4 cursor-pointer">
              <Box className="bg-white border rounded-full shadow-md">
                <Center className="w-[55px] h-[55px] bg-gray-100 rounded-full">
                  {data?.data?.fullName?.charAt(0)}
                </Center>
              </Box>
              <div className="p-4">
                <h6>{data?.data.fullName}</h6>
                <h6
                  className="text-grey text-xs font-light"
                  onClick={() => router.push("/tabs/profile", "none")}
                >
                  View Profile
                </h6>
              </div>
            </HStack>
            <HStack className=" items-center justify-start gap-4 mt-4" onClick={() => { router.push("/faqs") }}>
              <Icon icon="ic:round-question-mark" />
              <h6>Frequently Asked Questions </h6>
            </HStack>
            <HStack className="items-center justify-start gap-4">
              <Icon icon="bx:support" />
              <h6>Help & Support </h6>
            </HStack>
            <HStack className="items-center justify-start gap-4">
              <Icon icon="icon-park-outline:send-email" />
              <h6>Give Feedback </h6>
            </HStack>
            <HStack className="items-center justify-start gap-4">
              <Icon icon="mdi:about-circle-outline" />
              <h6>
                About <span className="text-stone-600">Rule</span>
                <span className="text-primary">Zero</span>
              </h6>
            </HStack>
            <VStack className="items-center my-auto mb-10">
              <HStack className="items-center justify-start w-full">
                <IonImg className=" w-9 h-9" src={LockIcon} />
                <h6 className="text-secondary text-sm">100% Safe and Secure</h6>
              </HStack>
              <h6 className="text-[9px]">
                We adhere to the strictest security standards and are SOC 1 and
                SOC 2 compliant.
              </h6>
            </VStack>
            <HStack className="justify-center w-full">
              <button
                className="px-9 text-secondary border-secondary focus:opacity-90 py-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </button>
            </HStack>
          </IonMenuToggle>
        </IonButtons>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
