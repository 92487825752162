import { create } from "zustand";
import produce from "immer";
import _ from "lodash";
import Zod from "zod";

interface FormStore<T> {
  form: T;
  setForm: (form: T | ((form?: T) => T)) => void;
  setFieldValue: (path: string, value: any) => void;
  setFieldTouched: (path: string) => void;
  clearForm: (form: T | ((form?: T) => T)) => void;
  errors: T;
  touched: T;
}

interface ZustandForm<T> {
  initialValues: T;
  validationSchema?: any;
}

function getTouchedObject<T>(data: T) {
  const _data = data as any;
  const res = {} as any;
  for (const key of Object.keys(_data)) {
    res[key] = false;
  }
  return res as T;
}
export function zustandForm<T>(input: ZustandForm<T>) {
  const { initialValues, validationSchema } = input;
  const store = create<FormStore<T>>(function (set) {
    const store = {
      form: initialValues,
      touched: getTouchedObject(initialValues),
      errors: getValidationErrors(validationSchema, initialValues),
      setForm: (input: any) => {
        if (typeof input === "function") {
          return set((store) => {
            const errors = getValidationErrors(
              validationSchema,
              input(store.form)
            );
            return { ...store, form: input(store.form), errors };
          });
        } else {
          return set((store) => {
            const errors = getValidationErrors(validationSchema, input);
            return { ...store, form: input, errors };
          });
        }
      },
      clearForm: () => {
        return set((store) => {
          return {
            ...store,
            form: initialValues,
            touched: getTouchedObject(initialValues),
            errors: getValidationErrors(validationSchema, initialValues),
          };
        });
      },
      setFieldValue: (path: string, value: any) => {
        set((store: any) => {
          const baseState = store.form;
          const nextState = produce(baseState, (draftState: any) => {
            _.set(draftState, path, value);
          });
          const errors = getValidationErrors(validationSchema, nextState);
          return { ...store, form: nextState, errors };
        });
      },
      setFieldTouched: (path: string) => {
        return set((store: any) => {
          const baseState = store.touched;
          const nextState = produce(baseState, (draftState: any) => {
            _.set(draftState, path, true);
          });
          return { ...store, touched: nextState };
        });
      },
    };
    return store;
  });
  return store;
}

function getValidationErrors<T>(schema: Zod.ZodSchema, data: T) {
  let errors: any = {};
  const zodResult = schema.safeParse(data, {});
  if (!zodResult.success) {
    for (const err of zodResult.error.errors) {
      errors[err.path.join(".")] = err.message;
    }
  }
  return errors as T;
}

export function getFieldProps(fieldName: string, store: FormStore<any>) {
  return {
    name: fieldName,
    value: store?.form?.[fieldName],
    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
      store.setFieldValue(e.target.name, e.target.value),
    onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) =>
      store.setFieldTouched(e.target.name),
  };
}
