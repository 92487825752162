export const sampleCurrencyRates = {
  message: "Currency converted successfully!",
  data: [
    {
      id: "169",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AED",
      rate: 0.045625,
    },
    {
      id: "170",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AFN",
      rate: 1.090376,
    },
    {
      id: "171",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ALL",
      rate: 1.4021,
    },
    {
      id: "172",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AMD",
      rate: 4.880047,
    },
    {
      id: "173",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ANG",
      rate: 0.02221,
    },
    {
      id: "174",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AOA",
      rate: 6.133236,
    },
    {
      id: "175",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ARS",
      rate: 1.984238,
    },
    {
      id: "176",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AUD",
      rate: 0.018544,
    },
    {
      id: "177",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AWG",
      rate: 0.02239,
    },
    {
      id: "178",
      date: "2022-11-13T00:00:00.000Z",
      currency: "AZN",
      rate: 0.021166,
    },
    {
      id: "179",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BAM",
      rate: 0.023422,
    },
    {
      id: "180",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BBD",
      rate: 0.024882,
    },
    {
      id: "181",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BDT",
      rate: 1.25944,
    },
    {
      id: "182",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BGN",
      rate: 0.023438,
    },
    {
      id: "183",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BHD",
      rate: 0.004673,
    },
    {
      id: "184",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BIF",
      rate: 25.492287,
    },
    {
      id: "185",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BMD",
      rate: 0.012422,
    },
    {
      id: "186",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BND",
      rate: 0.016972,
    },
    {
      id: "187",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BOB",
      rate: 0.085154,
    },
    {
      id: "188",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BRL",
      rate: 0.065873,
    },
    {
      id: "189",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BSD",
      rate: 0.012323,
    },
    {
      id: "190",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BTC",
      rate: 7.62004e-7,
    },
    {
      id: "191",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BTN",
      rate: 0.995962,
    },
    {
      id: "192",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BWP",
      rate: 0.159628,
    },
    {
      id: "193",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BYN",
      rate: 0.031117,
    },
    {
      id: "194",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BYR",
      rate: 243.464644,
    },
    {
      id: "195",
      date: "2022-11-13T00:00:00.000Z",
      currency: "BZD",
      rate: 0.02484,
    },
    {
      id: "196",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CAD",
      rate: 0.016478,
    },
    {
      id: "197",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CDF",
      rate: 25.389886,
    },
    {
      id: "198",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CHF",
      rate: 0.011727,
    },
    {
      id: "199",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CLF",
      rate: 0.000401,
    },
    {
      id: "200",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CLP",
      rate: 11.067208,
    },
    {
      id: "201",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CNY",
      rate: 0.088296,
    },
    {
      id: "202",
      date: "2022-11-13T00:00:00.000Z",
      currency: "COP",
      rate: 59.188084,
    },
    {
      id: "203",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CRC",
      rate: 7.509524,
    },
    {
      id: "204",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CUC",
      rate: 0.012422,
    },
    {
      id: "205",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CUP",
      rate: 0.329174,
    },
    {
      id: "206",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CVE",
      rate: 1.320487,
    },
    {
      id: "207",
      date: "2022-11-13T00:00:00.000Z",
      currency: "CZK",
      rate: 0.292058,
    },
    {
      id: "208",
      date: "2022-11-13T00:00:00.000Z",
      currency: "DJF",
      rate: 2.193806,
    },
    {
      id: "209",
      date: "2022-11-13T00:00:00.000Z",
      currency: "DKK",
      rate: 0.089464,
    },
    {
      id: "210",
      date: "2022-11-13T00:00:00.000Z",
      currency: "DOP",
      rate: 0.670393,
    },
    {
      id: "211",
      date: "2022-11-13T00:00:00.000Z",
      currency: "DZD",
      rate: 1.72612,
    },
    {
      id: "212",
      date: "2022-11-13T00:00:00.000Z",
      currency: "EGP",
      rate: 0.303712,
    },
    {
      id: "213",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ERN",
      rate: 0.186325,
    },
    {
      id: "214",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ETB",
      rate: 0.658024,
    },
    {
      id: "215",
      date: "2022-11-13T00:00:00.000Z",
      currency: "EUR",
      rate: 0.012027,
    },
    {
      id: "216",
      date: "2022-11-13T00:00:00.000Z",
      currency: "FJD",
      rate: 0.027697,
    },
    {
      id: "217",
      date: "2022-11-13T00:00:00.000Z",
      currency: "FKP",
      rate: 0.010505,
    },
    {
      id: "218",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GBP",
      rate: 0.010528,
    },
    {
      id: "219",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GEL",
      rate: 0.033663,
    },
    {
      id: "220",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GGP",
      rate: 0.010505,
    },
    {
      id: "221",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GHS",
      rate: 0.178688,
    },
    {
      id: "222",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GIP",
      rate: 0.010505,
    },
    {
      id: "223",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GMD",
      rate: 0.761138,
    },
    {
      id: "224",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GNF",
      rate: 106.164673,
    },
    {
      id: "225",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GTQ",
      rate: 0.096369,
    },
    {
      id: "226",
      date: "2022-11-13T00:00:00.000Z",
      currency: "GYD",
      rate: 2.577743,
    },
    {
      id: "227",
      date: "2022-11-13T00:00:00.000Z",
      currency: "HKD",
      rate: 0.097363,
    },
    {
      id: "228",
      date: "2022-11-13T00:00:00.000Z",
      currency: "HNL",
      rate: 0.304551,
    },
    {
      id: "229",
      date: "2022-11-13T00:00:00.000Z",
      currency: "HRK",
      rate: 0.090563,
    },
    {
      id: "230",
      date: "2022-11-13T00:00:00.000Z",
      currency: "HTG",
      rate: 1.669802,
    },
    {
      id: "231",
      date: "2022-11-13T00:00:00.000Z",
      currency: "HUF",
      rate: 4.871498,
    },
    {
      id: "232",
      date: "2022-11-13T00:00:00.000Z",
      currency: "IDR",
      rate: 192.212231,
    },
    {
      id: "233",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ILS",
      rate: 0.042426,
    },
    {
      id: "234",
      date: "2022-11-13T00:00:00.000Z",
      currency: "IMP",
      rate: 0.010505,
    },
    {
      id: "235",
      date: "2022-11-13T00:00:00.000Z",
      currency: "INR",
      rate: 1,
    },
    {
      id: "236",
      date: "2022-11-13T00:00:00.000Z",
      currency: "IQD",
      rate: 17.985877,
    },
    {
      id: "237",
      date: "2022-11-13T00:00:00.000Z",
      currency: "IRR",
      rate: 525.43645,
    },
    {
      id: "238",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ISK",
      rate: 1.79381,
    },
    {
      id: "239",
      date: "2022-11-13T00:00:00.000Z",
      currency: "JEP",
      rate: 0.010505,
    },
    {
      id: "240",
      date: "2022-11-13T00:00:00.000Z",
      currency: "JMD",
      rate: 1.895267,
    },
    {
      id: "241",
      date: "2022-11-13T00:00:00.000Z",
      currency: "JOD",
      rate: 0.008807,
    },
    {
      id: "242",
      date: "2022-11-13T00:00:00.000Z",
      currency: "JPY",
      rate: 1.733561,
    },
    {
      id: "243",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KES",
      rate: 1.502209,
    },
    {
      id: "244",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KGS",
      rate: 1.047155,
    },
    {
      id: "245",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KHR",
      rate: 51.043495,
    },
    {
      id: "246",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KMF",
      rate: 6.003456,
    },
    {
      id: "247",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KPW",
      rate: 11.179499,
    },
    {
      id: "248",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KRW",
      rate: 16.323681,
    },
    {
      id: "249",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KWD",
      rate: 0.003811,
    },
    {
      id: "250",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KYD",
      rate: 0.010269,
    },
    {
      id: "251",
      date: "2022-11-13T00:00:00.000Z",
      currency: "KZT",
      rate: 5.673189,
    },
    {
      id: "252",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LAK",
      rate: 213.420321,
    },
    {
      id: "253",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LBP",
      rate: 18.635193,
    },
    {
      id: "254",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LKR",
      rate: 4.528812,
    },
    {
      id: "255",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LRD",
      rate: 1.91099,
    },
    {
      id: "256",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LSL",
      rate: 0.216013,
    },
    {
      id: "257",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LTL",
      rate: 0.036678,
    },
    {
      id: "258",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LVL",
      rate: 0.007514,
    },
    {
      id: "259",
      date: "2022-11-13T00:00:00.000Z",
      currency: "LYD",
      rate: 0.061435,
    },
    {
      id: "260",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MAD",
      rate: 0.132388,
    },
    {
      id: "261",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MDL",
      rate: 0.236607,
    },
    {
      id: "262",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MGA",
      rate: 52.866896,
    },
    {
      id: "263",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MKD",
      rate: 0.737875,
    },
    {
      id: "264",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MMK",
      rate: 25.878907,
    },
    {
      id: "265",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MNT",
      rate: 42.356643,
    },
    {
      id: "266",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MOP",
      rate: 0.099493,
    },
    {
      id: "267",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MRO",
      rate: 4.434532,
    },
    {
      id: "268",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MUR",
      rate: 0.543092,
    },
    {
      id: "269",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MVR",
      rate: 0.191415,
    },
    {
      id: "270",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MWK",
      rate: 12.649058,
    },
    {
      id: "271",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MXN",
      rate: 0.242446,
    },
    {
      id: "272",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MYR",
      rate: 0.057481,
    },
    {
      id: "273",
      date: "2022-11-13T00:00:00.000Z",
      currency: "MZN",
      rate: 0.792877,
    },
    {
      id: "274",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NAD",
      rate: 0.216016,
    },
    {
      id: "275",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NGN",
      rate: 5.477454,
    },
    {
      id: "276",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NIO",
      rate: 0.443575,
    },
    {
      id: "277",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NOK",
      rate: 0.123351,
    },
    {
      id: "278",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NPR",
      rate: 1.593542,
    },
    {
      id: "279",
      date: "2022-11-13T00:00:00.000Z",
      currency: "NZD",
      rate: 0.020348,
    },
    {
      id: "280",
      date: "2022-11-13T00:00:00.000Z",
      currency: "OMR",
      rate: 0.004774,
    },
    {
      id: "281",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PAB",
      rate: 0.012323,
    },
    {
      id: "282",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PEN",
      rate: 0.047526,
    },
    {
      id: "283",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PGK",
      rate: 0.043422,
    },
    {
      id: "284",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PHP",
      rate: 0.710645,
    },
    {
      id: "285",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PKR",
      rate: 2.729608,
    },
    {
      id: "286",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PLN",
      rate: 0.056233,
    },
    {
      id: "287",
      date: "2022-11-13T00:00:00.000Z",
      currency: "PYG",
      rate: 87.918686,
    },
    {
      id: "288",
      date: "2022-11-13T00:00:00.000Z",
      currency: "QAR",
      rate: 0.045228,
    },
    {
      id: "289",
      date: "2022-11-13T00:00:00.000Z",
      currency: "RON",
      rate: 0.058852,
    },
    {
      id: "290",
      date: "2022-11-13T00:00:00.000Z",
      currency: "RSD",
      rate: 1.406499,
    },
    {
      id: "291",
      date: "2022-11-13T00:00:00.000Z",
      currency: "RUB",
      rate: 0.752131,
    },
    {
      id: "292",
      date: "2022-11-13T00:00:00.000Z",
      currency: "RWF",
      rate: 13.177205,
    },
    {
      id: "293",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SAR",
      rate: 0.046707,
    },
    {
      id: "294",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SBD",
      rate: 0.102238,
    },
    {
      id: "295",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SCR",
      rate: 0.163246,
    },
    {
      id: "296",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SDG",
      rate: 7.061831,
    },
    {
      id: "297",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SEK",
      rate: 0.129238,
    },
    {
      id: "298",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SGD",
      rate: 0.017074,
    },
    {
      id: "299",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SHP",
      rate: 0.01711,
    },
    {
      id: "300",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SLE",
      rate: 0.222906,
    },
    {
      id: "301",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SLL",
      rate: 221.354076,
    },
    {
      id: "302",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SOS",
      rate: 7.055506,
    },
    {
      id: "303",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SRD",
      rate: 0.373358,
    },
    {
      id: "304",
      date: "2022-11-13T00:00:00.000Z",
      currency: "STD",
      rate: 257.103397,
    },
    {
      id: "305",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SVC",
      rate: 0.107828,
    },
    {
      id: "306",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SYP",
      rate: 31.209811,
    },
    {
      id: "307",
      date: "2022-11-13T00:00:00.000Z",
      currency: "SZL",
      rate: 0.212644,
    },
    {
      id: "308",
      date: "2022-11-13T00:00:00.000Z",
      currency: "THB",
      rate: 0.447646,
    },
    {
      id: "309",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TJS",
      rate: 0.123849,
    },
    {
      id: "310",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TMT",
      rate: 0.0436,
    },
    {
      id: "311",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TND",
      rate: 0.039358,
    },
    {
      id: "312",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TOP",
      rate: 0.029701,
    },
    {
      id: "313",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TRY",
      rate: 0.230493,
    },
    {
      id: "314",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TTD",
      rate: 0.083673,
    },
    {
      id: "315",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TWD",
      rate: 0.386458,
    },
    {
      id: "316",
      date: "2022-11-13T00:00:00.000Z",
      currency: "TZS",
      rate: 28.762578,
    },
    {
      id: "317",
      date: "2022-11-13T00:00:00.000Z",
      currency: "UAH",
      rate: 0.455126,
    },
    {
      id: "318",
      date: "2022-11-13T00:00:00.000Z",
      currency: "UGX",
      rate: 46.396972,
    },
    {
      id: "319",
      date: "2022-11-13T00:00:00.000Z",
      currency: "USD",
      rate: 0.012422,
    },
    {
      id: "320",
      date: "2022-11-13T00:00:00.000Z",
      currency: "UYU",
      rate: 0.494472,
    },
    {
      id: "321",
      date: "2022-11-13T00:00:00.000Z",
      currency: "UZS",
      rate: 138.105808,
    },
    {
      id: "322",
      date: "2022-11-13T00:00:00.000Z",
      currency: "VEF",
      rate: 11182.542141,
    },
    {
      id: "323",
      date: "2022-11-13T00:00:00.000Z",
      currency: "VES",
      rate: 0.111899,
    },
    {
      id: "324",
      date: "2022-11-13T00:00:00.000Z",
      currency: "VND",
      rate: 308.24363,
    },
    {
      id: "325",
      date: "2022-11-13T00:00:00.000Z",
      currency: "VUV",
      rate: 1.486591,
    },
    {
      id: "326",
      date: "2022-11-13T00:00:00.000Z",
      currency: "WST",
      rate: 0.034601,
    },
    {
      id: "327",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XAF",
      rate: 7.855462,
    },
    {
      id: "328",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XAG",
      rate: 0.000575,
    },
    {
      id: "329",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XAU",
      rate: 0.000007043657,
    },
    {
      id: "330",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XCD",
      rate: 0.03357,
    },
    {
      id: "331",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XDR",
      rate: 0.009591,
    },
    {
      id: "332",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XOF",
      rate: 7.855462,
    },
    {
      id: "333",
      date: "2022-11-13T00:00:00.000Z",
      currency: "XPF",
      rate: 1.460163,
    },
    {
      id: "334",
      date: "2022-11-13T00:00:00.000Z",
      currency: "YER",
      rate: 3.108523,
    },
    {
      id: "335",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ZAR",
      rate: 0.214727,
    },
    {
      id: "336",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ZMK",
      rate: 111.809961,
    },
    {
      id: "337",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ZMW",
      rate: 0.201979,
    },
    {
      id: "338",
      date: "2022-11-13T00:00:00.000Z",
      currency: "ZWL",
      rate: 3.999771,
    },
  ],
};
