/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  Box,
  Center,
  HStack,
  ProfilePageInput,
  VStack,
} from "../components/utils";
import { Icon } from "@iconify/react";
import InviteCode from "../assets/inviteCode.svg";
import { useDashboard, useUpdateAll } from "../queries/dashboard";
import { AccordianEmployer } from "./Accordian";
import { useEffect, useState } from "react";
import { initAuth, useAuthStore } from "../store/useAuthStore";
import { useProfileEditForm } from "../store/useProfileEditForm";
import _ from "lodash";
import { Clipboard } from "@capacitor/clipboard";
import { Check } from "akar-icons";
import { EmployerState } from "../types/onBoarding";

const ProfileTab: React.FC = () => {
  const router = useIonRouter();
  const { setState: setAuth } = useAuthStore();
  function handleLogout() {
    setAuth(initAuth);
    window.location.href = process.env.PUBLIC_URL + "signUp"
  }
  const { data: dashboardData } = useDashboard();
  const { mutate: updateAll } = useUpdateAll();
  const { setFieldTouched, setFieldValue, form } = useProfileEditForm();
  const currencies = ["₹", "$", "¥", "€"];
  useEffect(() => {
    if (!isEdit) {
      setFieldValue("id", dashboardData?.data.id);
      setFieldValue("fullName", dashboardData?.data.fullName);
      setFieldValue("designation", dashboardData?.data.designation);
      setFieldValue("phoneNumber", dashboardData?.data.phoneNumber);
      setFieldValue("employers", dashboardData?.data.employers.filter(employer => employer.state !== EmployerState.Offered));
      setFieldValue("referralCode", dashboardData?.data.referralCode);
      setFieldValue("preferredCurrency", dashboardData?.data.preferredCurrency);
    }
  }, [dashboardData]);
  // const fields = [
  //   "id",
  //   "fullName",
  //   "phoneNumber",
  //   "employers.name",
  //   "employers.designation",
  //   "employers.latestSharePrice",
  //   "employers.currency",
  // ];
  // function touchAllFields() {
  //   fields.forEach((field) => setFieldTouched(field));
  // }
  // function checkErrors() {
  //   fields.forEach((field) => {
  //     if (Object.keys(errors).includes(field)) {
  //       return true;
  //     }
  //   });
  //   return false;
  // }

  async function copyToClipBoard(text: string) {
    await Clipboard.write({
      string: text,
    });
  }

  function handleCancel() {
    setFieldValue("id", dashboardData?.data.id);
    setFieldValue("fullName", dashboardData?.data.fullName);
    setFieldValue("designation", dashboardData?.data.designation);
    setFieldValue("phoneNumber", dashboardData?.data.phoneNumber);
    setFieldValue("employers", dashboardData?.data.employers.filter(employer => employer.state !== EmployerState.Offered));
    setFieldValue("referralCode", dashboardData?.data.referralCode);
    setIsEdit(!isEdit);
  }
  async function generateShareableLink() {
    const url = process.env.REACT_APP_URL || "http://localhost:3000";
    const inviteCode = form.referralCode;
    const shareAbleLink = url + "/signUp?inviteCode=" + inviteCode;
    setIsLinkCopied(true);
    copyToClipBoard(shareAbleLink);
  }
  function submitForm() {
    setIsEdit(!isEdit);
    //throws some error
    // touchAllFields();
    // const errors = checkErrors();
    // if (errors) {
    //   // show some toast
    //   return;
    // }
    const data = _.cloneDeep(form);
    updateAll(data, {
      onSuccess: () => {
        setIsEdit(!isEdit);
      },
      onError: (e) => console.error(e),
    });
  }
  const [isEdit, setIsEdit] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  useEffect(() => {
    if (isLinkCopied) {
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 2500);
    }
  }, [isLinkCopied])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {!isEdit ? (
            <HStack className="items-center justify-between h-16 max-w-md mx-auto">
              <div className="w-12"></div>
              <p className="text-primary text-lg font-semibold">Profile</p>
              <Icon
                className="w-12"
                icon="iconoir:edit-pencil"
                width="28"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              ></Icon>
            </HStack>
          ) : (
            <HStack className="items-center justify-between h-16 max-w-md mx-auto">
              <h6
                className="w-16"
                onClick={handleCancel}>Cancel</h6>
              <p className="text-primary text-lg font-semibold">Profile</p>
              <h6
                onClick={submitForm}
                className="w-16"
              >
                Save
              </h6>
            </HStack>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <Box className="mt-16 border-2 rounded-lg">
            <HStack className="-top-12 relative justify-center">
              <Center className="w-[100px] h-[100px] bg-gray-100 rounded-full">
                {form?.fullName?.charAt(0)}
              </Center>
            </HStack>
            <VStack className="gap-4 px-2">
              <HStack className="justify-between -mt-8">
                <h6 className="font-normal">Name: </h6>
                {isEdit ? (
                  <ProfilePageInput
                    className="w-fit h-7"
                    onChange={(e) => {
                      setFieldValue("fullName", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldTouched("fullName");
                    }}
                    value={form.fullName}
                  />
                ) : (
                  <h6 className="h-7 font-normal">{form.fullName}</h6>
                )}
              </HStack>
              <HStack className=" justify-between">
                <h6 className=" font-normal">Designation: </h6>
                {isEdit ? (
                  <ProfilePageInput
                    className="w-fit h-7"
                    onChange={(e) => {
                      setFieldValue("designation", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldTouched("designation");
                    }}
                    value={form.designation}
                  />
                ) : (
                  <h6 className="w-fit h-7 font-normal">{form.designation}</h6>
                )}
              </HStack>
              <HStack className=" justify-between">
                <h6 className=" font-normal">Preferred Currency</h6>
                {isEdit ? (
                  <IonSelect
                    aria-label="fruit"
                    className="w-1/5 h-10 font-sans min-w-[60px]"
                    mode="ios"
                    interface="popover"
                    autoSave="yes"
                    value={form?.preferredCurrency}
                    onIonChange={(e) => setFieldValue("preferredCurrency", e.target.value)}
                  >
                    {currencies.map((name) => (
                      <IonSelectOption key={name} value={name} className="text-xs">
                        {name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                ) : (
                  <h6 className="w-fit h-7 font-normal">{form.preferredCurrency}</h6>
                )}
              </HStack>
              {parseInt(form.phoneNumber, 10) ? (
                <HStack className="justify-between">
                  <h6 className=" font-normal">Mobile number: </h6>
                  <h6 className=" font-normal">{form.phoneNumber}</h6>
                </HStack>) : <HStack className="justify-between">
                <h6 className=" font-normal">Email Address: </h6>
                <h6 className=" font-normal">{form.phoneNumber}</h6>
              </HStack>}
              <HStack className="justify-between mb-6">
                <h6 className=" font-normal">Invite Code: </h6>
                <HStack className="items-center gap-4">
                  <h6 className=" font-normal">{form.referralCode}</h6>
                  <Icon
                    icon="material-symbols:content-copy-outline-rounded"
                    onClick={() => {
                      copyToClipBoard(form.referralCode);
                    }}
                  />
                </HStack>
              </HStack>
            </VStack>
          </Box>
          {!isLinkCopied ? (
            <HStack className="mt-7 bg-secondary items-center">
              <VStack className="gap-1">
                <h6
                  className="px-1 text-lg font-medium text-white underline"
                  onClick={generateShareableLink}
                >
                  Share Invite Code
                </h6>
                <h6 className="px-1 text-xs font-light text-white">
                  Share the unique link generated with your friends to invite then to join.
                </h6>
              </VStack>
              <IonImg className="w-32 h-[85px]" src={InviteCode} />
            </HStack>
          ) : (
            <HStack className="bg-secondary mt-7 items-center justify-center gap-4 py-4">
              <h6 className="text-lg font-normal text-white">Link Copied!</h6>
              <Box className="bg-primary p-3 rounded-full">
                <Check
                  className="animate-tick text-white"
                  strokeWidth={6}
                  size={24}
                />
              </Box>
            </HStack>
          )}
          <div className="mt-8 mb-4" />
          <AccordianEmployer isEdit={isEdit} setIsEdit={setIsEdit} />
          <Box className="mt-10 border-2 rounded-lg">
            <VStack className="gap-10 p-5 text-sm font-medium">
              <HStack className="items-center justify-start gap-4" onClick={() => { router.push("/faqs") }}>
                <Icon icon="ic:round-question-mark" />
                <h6>Frequently Asked Questions </h6>
              </HStack>
              <HStack className="items-center justify-start gap-4">
                <Icon icon="bx:support" />
                <h6>Help & Support </h6>
              </HStack>
              <HStack className="items-center justify-start gap-4">
                <Icon icon="icon-park-outline:send-email" />
                <h6>Give Feedback </h6>
              </HStack>
              <HStack className="items-center justify-start gap-4">
                <Icon icon="mdi:about-circle-outline" />
                <h6>
                  About <span className="text-stone-600">Rule</span>
                  <span className="text-primary">Zero</span>
                </h6>
              </HStack>
            </VStack>
          </Box>
          <HStack className="mb-14 justify-center mt-10">
            <button
              className="px-9 text-secondary border-secondary focus:opacity-90 py-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
              onClick={handleLogout}
            >
              Logout
            </button>
          </HStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfileTab;
