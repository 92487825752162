import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonToolbar,
  useIonRouter,
  IonSelect,
  IonSelectOption,
  IonFab,
  IonFabButton,
  IonFabList,
} from "@ionic/react";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
} from "../components/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import Svg from "../assets/noRejectedGrants.svg";
import { getTotalVested, useDashboard } from "../queries/dashboard";
import { useCompany } from "../store/useCompany";
import { Icon } from "@iconify/react";
import { format, formatDistance } from "date-fns";
import {
  compactCurrenyFormatter,
  getEmployerIdFromGrantId,
  getExerciseCost,
  getTaxPercentageNumber,
  getTaxToBePaid,
  getTotalCash,
} from "../utils";
import {
  Employer,
  EmployerState,
  Grant,
  GrantEventType,
} from "../types/onBoarding";
import { ExerciseDetails } from "../store/useExerciseForm";
import _ from "lodash";
import { CashOutDetails } from "../store/useEvaluateCashOutForm";

const GrantsTab: React.FC = () => {
  const { data } = useDashboard();
  const allEmployers = useMemo(
    () =>
      data?.data.employers.filter(
        (employer) => employer.state !== EmployerState.Offered
      ) || [],
    [data]
  );
  let employers = allEmployers;
  const { state: company, setState: setCompany } = useCompany();
  if (company && company !== "All") {
    employers = employers.filter((e) => e.name === company);
  }
  const router = useIonRouter();
  const menu = ["Granted", "Exercise", "Cash Out"];
  const [activeMenu, setActiveMenu] = useState<string>("Granted");
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const exerciseEvents = grants
    .map((grant) => grant.events)
    .flat()
    .filter((e) => e.type === GrantEventType.Exercise);
  const cashOutEvents = grants
    .map((grant) => grant.events)
    .flat()
    .filter((e) => e.type === GrantEventType.Cashout);
  const grantIdMap = new Map<number, Grant>(grants.map((g) => [g.id || -1, g]));
  const exerciseEventNames = _.uniqBy(exerciseEvents, (e) => e.name)
    .map((e) => e.name || "")
    .filter((e) => e);
  const cashOutEventNames = _.uniqBy(cashOutEvents, (e) => e.name)
    .map((e) => e.name || "")
    .filter((e) => e);
  const exerciseDetails: ExerciseDetails[] = [];
  const cashOutDetails: CashOutDetails[] = [];
  const groupedEventExercise = _.groupBy(exerciseEvents, "name");
  const groupedEventCashOut = _.groupBy(cashOutEvents, "name");
  const fabRef = useRef<HTMLIonFabElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const fab = fabRef.current;
      if (fab && !fab.contains(event.target as Node)) {
        if (isMenuOpen) {
          setIsMenuOpen(!isMenuOpen);
        }
        fab.close();
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [fabRef, isMenuOpen]);
  for (const name of exerciseEventNames) {
    const groupedEvents = _.get(groupedEventExercise, name);
    exerciseDetails.push({
      dateOfExercise: groupedEvents[0]?.date,
      employerId:
        getEmployerIdFromGrantId(groupedEvents[0].grantId, grantIdMap) || 0,
      exerciseCost: getExerciseCost(groupedEvents, grantIdMap),
      exercisedOptions: _.sumBy(groupedEvents, (event) => event.noOfOptions),
      exerciseEvents: groupedEvents,
      fairMarketValue: groupedEvents[0]?.fairMarketValue || 0,
      taxSlab: groupedEvents[0]?.taxSlab || "",
      taxToBePaid: getTaxToBePaid(groupedEvents, grantIdMap),
      name: groupedEvents[0]?.name || "",
    });
  }
  for (const name of cashOutEventNames) {
    const groupedEvents = _.get(groupedEventCashOut, name);
    cashOutDetails.push({
      dateOfExercise: groupedEvents[0]?.date,
      employerId:
        getEmployerIdFromGrantId(groupedEvents[0].grantId, grantIdMap) || 0,
      totalCash: getTotalCash(groupedEvents, grantIdMap),
      exercisedOptions: _.sumBy(groupedEvents, (event) => event.noOfOptions),
      exerciseEvents: groupedEvents,
      fairMarketValue: groupedEvents[0]?.fairMarketValue || 0,
      taxSlab: groupedEvents[0]?.taxSlab || "",
      taxToBePaid:
        (getTotalCash(groupedEvents, grantIdMap) *
          (getTaxPercentageNumber(groupedEvents[0]?.taxSlab || "") || 0)) /
        100,
      name: groupedEvents[0]?.name || "",
    });
  }
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
  });
  if (company && company !== "All") {
    employers = employers.filter((e) => e.name === company);
  }
  const acceptedGrants = grants.filter(
    (grant) =>
      grant?.grantState === "Accepted" &&
      grant.events.filter((e) => e.type === GrantEventType.Vesting).length === 0
  );
  const companyNames =
    allEmployers.length > 1
      ? ["All", ...allEmployers.map((e) => e.name)]
      : allEmployers.map((e) => e.name);
  const oldGrants = grants.filter(
    (grant) =>
      grant.events.filter((e) => e.type === GrantEventType.Vesting).length > 0
  );
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack
            className={`items-center justify-between h-16 max-w-md mx-auto ${isMenuOpen ? "  opacity-10 bg-white" : ""
              }`}
          >
            <IonButtons slot="start">
              <IonMenuButton
                autoHide={false}
                className="w-12 text-3xl font-thin text-black"
              >
                <Icon icon="akar-icons:three-line-horizontal" />
              </IonMenuButton>
            </IonButtons>
            <p className="text-primary text-lg font-semibold">Grants</p>
            <div className="w-12"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div
          className={`max-w-md mx-auto mb-5 ${isMenuOpen ? "  opacity-10 bg-white" : ""
            }`}
        >
          {employers.length === 0 ? (
            <>
              <VStack>
                <IonImg className=" w-[414px] h-[368px] mt-20" src={Svg} />
                <HStack className="justify-center w-full mt-1">
                  <ButtonPrimary className="px-6 text-sm font-medium">
                    Add a New Grant
                  </ButtonPrimary>
                </HStack>
                <HStack className="px-4">
                  <h6 className="text-grey mt-3 text-xs">
                    To be able to add an exercise and perform a cash out, it is
                    necessary to add atleast one grant
                  </h6>
                </HStack>
              </VStack>
            </>
          ) : (
            <>
              <HStack>
                {menu.map((item) => (
                  <Box
                    key={item}
                    className={`w-1/3 py-2 text-center ${item === activeMenu
                        ? "border-b-2 font-semibold border-primary text-primary"
                        : "text-grey"
                      }`}
                    onClick={() => setActiveMenu(item)}
                  >
                    {item}
                  </Box>
                ))}
              </HStack>
              {activeMenu === "Granted" && (
                <>
                  <Box className="text-grey text-start w-full px-2 mt-2 text-xs font-normal">
                    This screen shows your grant options in detail.
                  </Box>
                  <HStack className="justify-end w-full">
                    <IonSelect
                      aria-label="fruit"
                      className="h-9 w-40 mt-4 mr-4 text-center border rounded-md"
                      placeholder=""
                      mode="ios"
                      interface="popover"
                      autoSave="yes"
                      value={company}
                      onIonChange={(e) => setCompany(e.target.value)}
                    >
                      {companyNames.map((name) => (
                        <IonSelectOption
                          key={name}
                          value={name}
                          className="text-xs"
                        >
                          {name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </HStack>
                  {company === "All" && grants.length > 0 ? (
                    <>
                      {acceptedGrants.map((grant) => (
                        <AcceptedGrant
                          grant={grant}
                          employers={employers}
                          key={grant.id}
                        />
                      ))}
                      {oldGrants.map((grant) => (
                        <OldGrant
                          grant={grant}
                          employers={employers}
                          key={grant.id}
                        />
                      ))}
                    </>
                  ) : acceptedGrants.length > 0 ? (
                    acceptedGrants.map((grant) => (
                      <AcceptedGrant
                        grant={grant}
                        employers={employers}
                        key={grant.id}
                      />
                    ))
                  ) : oldGrants.length > 0 ? (
                    oldGrants.map((grant) => (
                      <OldGrant
                        grant={grant}
                        employers={employers}
                        key={grant.id}
                      />
                    ))
                  ) : (
                    <VStack>
                      <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                        No Grants found
                      </Box>
                      <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                    </VStack>
                  )}
                </>
              )}
              {activeMenu === "Exercise" && (
                <>
                  <Box className="text-grey text-start w-full px-2 mt-2 text-xs font-normal">
                    This screen shows your exercised options in detail.
                  </Box>
                  <HStack className="justify-end w-full">
                    <IonSelect
                      aria-label="fruit"
                      className="h-9 w-40 mt-4 mr-4 text-center border rounded-md"
                      placeholder=""
                      mode="ios"
                      interface="popover"
                      autoSave="yes"
                      value={company}
                      onIonChange={(e) => setCompany(e.target.value)}
                    >
                      {companyNames.map((name) => (
                        <IonSelectOption
                          key={name}
                          value={name}
                          className="text-xs"
                        >
                          {name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </HStack>
                  {exerciseDetails.length > 0 ? (
                    exerciseDetails.map((exercise) => (
                      <ExerciseCard
                        employers={employers}
                        exercise={exercise}
                        key={exercise.name}
                      />
                    ))
                  ) : (
                    <VStack>
                      <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                        No Exercise found
                      </Box>
                      <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                    </VStack>
                  )}
                </>
              )}
              {activeMenu === "Cash Out" && (
                <>
                  <Box className="text-grey text-start w-full px-2 mt-2 text-xs font-normal">
                    This screen shows your cashed out options in detail.
                  </Box>
                  <HStack className="justify-end w-full">
                    <IonSelect
                      aria-label="fruit"
                      className="h-9 w-40 mt-4 mr-4 text-center border rounded-md"
                      placeholder=""
                      mode="ios"
                      interface="popover"
                      autoSave="yes"
                      value={company}
                      onIonChange={(e) => setCompany(e.target.value)}
                    >
                      {companyNames.map((name) => (
                        <IonSelectOption
                          key={name}
                          value={name}
                          className="text-xs"
                        >
                          {name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </HStack>
                  {cashOutDetails.length > 0 ? (
                    cashOutDetails.map((exercise) => (
                      <CashOutCard
                        employers={employers}
                        exercise={exercise}
                        key={exercise.name}
                      />
                    ))
                  ) : (
                    <VStack>
                      <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                        No Cash Out found
                      </Box>
                      <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                    </VStack>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </IonContent>
      <IonFab horizontal="end" vertical="bottom" slot="fixed" ref={fabRef}>
        <IonFabButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Icon icon="material-symbols:add" className="w-[56px] h-[36px]" />
        </IonFabButton>
        <IonFabList side="top">
          <VStack className="whitespace-nowrap right-36 relative items-end bg-white border-2 divide-y-2 rounded-lg shadow-md">
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  router.push("/addNewGrant");
                }}
              >
                Add a grant
              </button>
            </HStack>
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  router.push("/tabs/evaluate/moneyForecast");
                }}
              >
                Wealth Forecast
              </button>
            </HStack>
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  router.push("/addExercise");
                }}
              >
                Add Exercise
              </button>
            </HStack>
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  router.push("/addCashOut");
                }}
              >
                Add Cash Out
              </button>
            </HStack>
          </VStack>
        </IonFabList>
      </IonFab>
    </IonPage>
  );
};

export default GrantsTab;

function ExerciseCard({
  exercise,
  employers,
}: {
  exercise: ExerciseDetails;
  employers: Employer[];
}) {
  const router = useIonRouter();
  const employer = employers.find((e) => e.id === exercise.employerId);
  return (
    <Box
      className="max-w-md mx-4 mt-8 border rounded-lg"
      key={exercise.name}
      onClick={() => {
        router.push(`/seeExerciseDetails/${exercise.name}`);
      }}
    >
      <Center className="bg-[#0AC3B9] text-white rounded-t-lg">
        {exercise.name}
      </Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" text-dark px-2 text-base font-normal">
            {employer?.name}
          </h6>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated:{" "}
            {format(
              new Date(exercise?.dateOfExercise || new Date()),
              "dd MMMM yyyy"
            )}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">No. of Options</p>
          <p className="text-dark text-xl font-medium">
            {exercise.exercisedOptions}
          </p>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Fair Market Value</p>
          <div className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{exercise.fairMarketValue}</span>
          </div>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-10" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeExerciseDetails/${exercise.name}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>
  );
}

function AcceptedGrant({
  grant,
  employers,
}: {
  grant: Grant;
  employers: Employer[];
}) {
  const router = useIonRouter();
  const employer = employers.find((e) => e.id === grant.employerId);
  return (
    <Box
      className="max-w-md mx-4 mt-8 border-2 rounded-lg"
      key={grant.id}
      onClick={() => {
        router.push(`/seeDetails/${grant.id}`);
      }}
    >
      <HStack className=" items-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" text-dark px-2 text-base font-normal">
            {employer?.name}
          </h6>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated: {format(new Date(grant.grantDate), "dd MMMM yyyy")}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Total Options:</p>
          <p className="text-dark text-xl font-medium">{grant.noOfOptions}</p>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Strike Price</p>
          <div className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{grant.strikePrice}</span>
          </div>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Current Value</p>
          <div className="text-dark font-medium">
            {employer?.latestSharePrice &&
              Number(employer?.latestSharePrice) !== 0 ? (
              <>
                <span className="font-sans">{employer.currency}</span>
                <span className="text-xl">
                  {compactCurrenyFormatter.format(
                    Number(
                      (
                        getTotalVested(grant) *
                        (employer?.latestSharePrice - grant.strikePrice)
                      ).toFixed(2)
                    )
                  )}
                </span>
              </>
            ) : (
              <>
                <h6 className="text-primary whitespace-nowrap text-xl font-medium">
                  <span className="font-sans">₹</span>XXXXX
                </h6>
                <h6
                  className="text-primary mt-1 text-xs font-normal text-center underline"
                  onClick={() => {
                    router.push(`/editCompany/${employer?.id}`);
                  }}
                >
                  (Fill)
                </h6>
              </>
            )}
          </div>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-7" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeDetails/${grant.id}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>
  );
}

function OldGrant({
  grant,
  employers,
}: {
  grant: Grant;
  employers: Employer[];
}) {
  const router = useIonRouter();
  const employer = employers.find((e) => e.id === grant.employerId);
  return (
    <Box
      className="max-w-md mx-4 mt-8 border-2 rounded-lg"
      key={grant.id}
      onClick={() => {
        router.push(`/seeOldDetails/${grant.id}`);
      }}
    >
      {grant.lastDateToExercise > new Date() && (
        <HStack className="-top-4 relative justify-end">
          <p className="px-2 py-1 text-xs font-normal text-center bg-white border-2 border-yellow-400 rounded-md">
            {formatDistance(
              new Date(grant?.lastDateToExercise),
              new Date()
            ).replace("about", "")}
            left to exercise
          </p>
        </HStack>
      )}
      <HStack className=" items-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" px-2 font-normal">{employer?.name}</h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">Granted Options</div>
          <div className="text-dark text-xl font-medium">
            {grant.noOfOptions}
          </div>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">Strike Price</div>
          <div className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{grant.strikePrice}</span>
          </div>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">Vested Options</div>
          <div className="text-dark text-xl font-medium">
            {grant.events
              .filter((e) => e.type === GrantEventType.Vesting)
              .map((e) => e.noOfOptions)}
          </div>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">Exercised Options</div>
          <div className="text-dark text-xl font-medium">
            {grant.events
              .filter((e) => e.type === GrantEventType.Exercise)
              .map((e) => e.noOfOptions)}
          </div>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeOldDetails/${grant.id}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>
  );
}

function CashOutCard({
  exercise,
  employers,
}: {
  exercise: CashOutDetails;
  employers: Employer[];
}) {
  const router = useIonRouter();
  const employer = employers.find((e) => e.id === exercise.employerId);
  return (
    <Box
      className="max-w-md mx-4 mt-8 border rounded-lg"
      key={exercise.name}
      onClick={() => {
        router.push(`/seeCashOutDetails/${exercise.name}`);
      }}
    >
      <Center className="bg-[#0FA958] text-white rounded-t-lg">
        {exercise.name}
      </Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" text-dark px-2 text-base font-normal">
            {employer?.name}
          </h6>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated:{" "}
            {format(
              new Date(exercise?.dateOfExercise || new Date()),
              "dd MMMM yyyy"
            )}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">No. of Options</div>
          <div className="text-dark text-xl font-medium">
            {exercise.exercisedOptions}
          </div>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <div className="text-grey text-sm">Fair Market Value</div>
          <div className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{exercise.fairMarketValue}</span>
          </div>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-10" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeCashOutDetails/${exercise.name}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>
  );
}
