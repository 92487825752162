import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useVestings } from "../queries/dashboard";
import { Center, HStack, VStack } from "../components/utils";
ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Vested", "Granted"],
  datasets: [
    {
      label: "Options: ",
      data: [2500, 7500],
      backgroundColor: ["#FF8863", "#869EDD"],
      borderWidth: 0,
      borderRadius: [10, 10],
      spacing: 0,
    },
  ],
};

const options = {
  rotation: -90,
  circumference: 180,
  cutout: "85%",
  plugins: {
    legend: {
      display: false,
    },
  },
};
export function DoughnutChart() {
  const { totalGranted, totalVested, totalSurrendered, totalForfeited, totalExercised, totalCashedOut } =
    useVestings();
  const data = {
    labels: ["Vested", "To Be Vested", "Exercised", "Cashed Out", "Forfeited"],
    datasets: [
      {
        label: "Options: ",
        data: [
          totalVested - totalExercised - totalCashedOut - totalForfeited,
          totalGranted - totalVested - totalSurrendered,
          totalExercised,
          totalCashedOut,
          totalForfeited + totalSurrendered,
        ],
        backgroundColor: ["#E85936", "#3E2A35", "#47830C", "#409EC8", "#C10000"],
        borderWidth: 0,
        borderRadius: [10, 10, 10, 10, 10],
        spacing: 0,
      },
    ],
  };
  return (
    <VStack className="items-center justify-between mt-6 border rounded-lg shadow-md">
      <h6 className="text-dark items-start w-full mt-2 ml-4 text-lg font-medium">
        Stock Options Information
      </h6>
      <Doughnut className="mx-8" data={data} options={options} />
      <VStack className="-top-40 relative items-center justify-center gap-1">
        <span className="text-sm font-medium">
          Granted:
        </span>
        <span className="text-sm font-medium">
          {totalGranted} options
        </span>
      </VStack>
      {totalGranted - totalVested - totalSurrendered > 0 && (
        <HStack className="-top-[82px] relative items-center justify-center gap-1">
          <Center className="w-[10px] h-[10px] bg-[#3E2A35] rounded-full"></Center>
          <span className=" text-[#3E2A35] text-sm font-medium">
            To Be Vested:{" "}
          </span>
          <span className="text-sm font-medium">
            {" "}
            {totalGranted - totalVested - totalSurrendered} options
          </span>
        </HStack>
      )}
      <HStack className="-top-20 relative items-center justify-center gap-1">
        <Center className="w-[10px] h-[10px] bg-primary rounded-full"></Center>
        <span className=" text-primary text-sm font-medium">Vested: </span>
        <span className="text-sm font-medium"> {totalVested - totalExercised - totalCashedOut - totalForfeited} options</span>
      </HStack>
      {totalExercised > 0 && (
        <HStack className="-top-[78px] relative items-center justify-center gap-1">
          <Center className="w-[10px] h-[10px] bg-[#47830C] rounded-full"></Center>
          <span className=" text-[#47830C] text-sm font-medium">
            Exercised:{" "}
          </span>
          <span className="text-sm font-medium"> {totalExercised} options</span>
        </HStack>
      )}
      {totalCashedOut > 0 && (
        <HStack className="-top-[76px] relative items-center justify-center gap-1">
          <Center className="w-[10px] h-[10px] bg-[#409EC8] rounded-full"></Center>
          <span className=" text-[#409EC8] text-sm font-medium">
            Cashed Out:{" "}
          </span>
          <span className="text-sm font-medium"> {totalCashedOut} options</span>
        </HStack>
      )}
      {(totalForfeited + totalSurrendered) > 0 && (
        <HStack className="-top-[74px] relative items-center justify-center gap-1 mb-10">
          <Center className="w-[10px] h-[10px] bg-[#C10000] rounded-full"></Center>
          <span className=" text-[#C10000] text-sm font-medium">
            Forfeited:{" "}
          </span>
          <span className="text-sm font-medium"> {totalForfeited + totalSurrendered} options</span>
        </HStack>
      )}
    </VStack>
  );
}

export function DoughnutChartForNewCompany() {
  const { totalGranted, totalVested } = useVestings();
  const data = {
    labels: ["Vested", "To Be Vested"],
    datasets: [
      {
        label: "Options: ",
        data: [totalVested, totalGranted - totalVested],
        backgroundColor: ["#FF8863", "#869EDD"],
        borderWidth: 0,
        borderRadius: [10, 10],
        spacing: 0,
      },
    ],
  };
  return (
    <div className="relative flex flex-col items-center justify-between mt-2 border rounded-lg shadow-md">
      <h6 className="top-8 left-8 absolute text-lg font-medium">
        ESOP Details
      </h6>
      <Doughnut className=" mx-8" data={data} options={options} />
      <div className=" absolute top-[60%] font-medium text-sm ">
        <h6>
          <span className="text-[#869EDD]">Granted:</span> {totalGranted}{" "}
          Options
        </h6>
        <h6>
          <span className="text-[#FF8863]">Vested:</span> {totalVested} Options
        </h6>
      </div>
    </div>
  );
}
