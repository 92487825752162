import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import HissaIcon from "../assets/hissa.svg";
import { Center, VStack } from "../components/utils";

const ExploreJourney: React.FC = () => {
  const router = useIonRouter();
  useEffect(() => {
    const offerId = localStorage.getItem("offerId");
    if (router.routeInfo.pathname === "/exploreJourney") {
      if (router.routeInfo.lastPathname === "/evaluateGrantDetails" && offerId) {
        localStorage.removeItem("offerId");
        setTimeout(() => {
          router.push(`/tabs/evaluate/moneyForecast/${offerId}`);
        }, 1500);
      }
      else if (router.routeInfo.lastPathname !== "/evaluateGrantDetails") {
        setTimeout(() => {
          router.push("/tabs/home");
        }, 1500);
      }
    }
  }, [router]);
  return (
    <IonPage>
      <IonContent>
        <Center className="min-w-min max-w-md mx-auto bg-white">
          <VStack className="mt-96 h-min items-center w-full">
            <IonImg className=" w-[139px] h-[50.55px]" src={HissaIcon} />
            <h6 className="mt-6 text-xl font-medium text-black">Welcome!</h6>
          </VStack>
        </Center>
      </IonContent>
    </IonPage>
  );
};

export default ExploreJourney;
