import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import UploadFile from "../assets/upload-files.svg";
import {
  VStack,
  Label,
  Input,
  ButtonPrimary,
  HStack,
  Error,
} from "../components/utils";
import { getFieldProps, zustandForm } from "../store/zustandForm";
import { z } from "zod";
import { useUpdateUser } from "../queries/dashboard";
import { UpdateUserDto } from "../types/onBoarding";
const userUpdateSchema = z.object({
  name: z.string().min(3),
  designation: z.string(),
});
export const usePersonalInfoForm = zustandForm({
  initialValues: { name: "", designation: "" },
  validationSchema: userUpdateSchema,
});

const ProfileInfo: React.FC = () => {
  const router = useIonRouter();
  const { mutate: updateUser } = useUpdateUser();
  const { form, clearForm, touched, errors, setFieldTouched } =
    usePersonalInfoForm();
  const fields = ["name", "designation"];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }

  function submitUserInfo() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const user: UpdateUserDto = {
      designation: form.designation,
      name: form.name,
    };
    updateUser(user, {
      onSuccess: () => {
        router.push("/chooseActions");
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-center h-16 max-w-md mx-auto">
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md px-2 mx-auto">
          <VStack className=" items-center mt-4">
            <h6 className="w-full px-1 text-2xl font-semibold">Welcome!</h6>
            <h4 className="w-full px-1 mt-2.5 font-medium">
              Let's get to know you a little.
            </h4>
            <IonImg
              className=" w-[90px] h-[90px] mx-auto mt-14"
              src={UploadFile}
            />
            <div className="text-secondary mt-4">Upload picture</div>
            <VStack className="mt-14 w-full gap-2 px-3">
              <Label className="text-grey font-medium">Name</Label>
              <Input
                type="text"
                {...getFieldProps("name", usePersonalInfoForm())}
              />
              <Error
                visible={!!(touched.name && errors.name)}
                text={errors?.name}
              />
              <Label className="text-grey mt-4 font-medium">Designation</Label>
              <Input
                {...getFieldProps("designation", usePersonalInfoForm())}
                type="text"
              />
              <Error
                visible={!!(touched.designation && errors.designation)}
                text={errors?.designation}
              />
            </VStack>
            <ButtonPrimary className="px-12 mt-12" onClick={submitUserInfo}>
              Get Started
            </ButtonPrimary>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfileInfo;
