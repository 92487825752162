import {
  IonContent,
  IonImg,
  IonPage,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  ButtonPrimary,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useState } from "react";
import { useAuthStore } from "../store/useAuthStore";
import { useResendOtp, useVerify } from "../queries/dashboard";
import { SignUp, VerifyDto } from "../types/onBoarding";
import { useAuthenticationForm } from "../store/useAuthenticationForm";

const Otp: React.FC = () => {
  const router = useIonRouter();
  const { setState: setAuth } = useAuthStore();
  const { clearForm, form } = useAuthenticationForm();

  const [present] = useIonToast();
  const [otp, setOtp] = useState("");
  const { mutate: verify } = useVerify();
  const { mutate: resendOtp } = useResendOtp();
  function handleResend() {
    const signUpDto: SignUp = {
      phoneNumber: parseInt(form.id, 10)
        ? parseInt(form.id, 10).toString()
        : "",
      referralCode: localStorage.getItem("inviteCode") || "",
      emailId: parseInt(form.id, 10) ? "" : form.id,
    };
    resendOtp(signUpDto, {
      onSuccess: (data) => {
        setAuth((prev) => ({ ...prev, userId: data.data.userId }));
        present({
          message: "OTP Resent successfully",
          duration: 1500,
          position: "bottom",
          color: "success",
          mode: "ios",
          animated: true,
        });
      },
      onError: () => {
        present({
          message: "Something went wrong",
          duration: 1500,
          position: "bottom",
          color: "danger",
          mode: "ios",
          animated: true,
        });
      },
    });
  }
  function handleVerify() {
    const userId = useAuthStore.getState().state.userId;
    if (!userId) return;
    const verifyDto: VerifyDto = {
      otp,
      userId,
    };
    verify(verifyDto, {
      onSuccess: (data) => {
        setAuth((prev) => ({
          ...prev,
          isAuthenticated: true,
          accessToken: data.data.token,
          noOfLogins: prev.noOfLogins + 1
        }));
        clearForm(form);
        router.push("/verified");
      },
      onError: () => {
        present({
          message: "Something went wrong",
          duration: 1500,
          position: "bottom",
          color: "danger",
          mode: "ios",
          animated: true,
        });
      },
    });
  }
  return (
    <IonPage>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <button className="p-3 mt-1 text-black">
            <ChevronLeft size={20} onClick={() => router.goBack()} />
          </button>
          <VStack className="items-center">
            <IonImg className="w-[139px] h-[50.55px] mt-12" src={HissaIcon} />
            <HStack className="justify-center w-full mt-4">
              <h6 className="text-lg font-medium">Invite-Only</h6>
            </HStack>
            <VStack className="w-full px-4">
              <HStack className="mt-14 w-full">
                <h6 className="whitespace-nowrap text-lg font-medium">
                  We've sent the OTP set to email/no.
                </h6>
              </HStack>
              <VStack className="mt-9 text-dark w-full gap-2">
                <Label className="font-medium">OTP</Label>
                <Input
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </VStack>
              <HStack className="whitespace-nowrap justify-end w-full gap-2 mt-4 mr-4 font-medium">
                <h6 className="text-grey">
                  Didn't receive the OTP?
                  <span className="text-secondary" onClick={handleResend}> Resend</span>
                </h6>
              </HStack>
            </VStack>
            <ButtonPrimary className="mt-36 px-12" onClick={handleVerify}>
              Verify
            </ButtonPrimary>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Otp;
