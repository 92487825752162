/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonPopover,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Center, HStack, VStack } from "../components/utils";
import { useState } from "react";
import { useDashboard, useSaveEvaluateCashOutDetails } from "../queries/dashboard";
import { ChevronLeft, MoreVerticalFill } from "akar-icons";
import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import _ from "lodash";
import { getTaxPercentage } from "../utils";
import { useCashOutForm } from "../store/useEvaluateCashOutForm";
import { EmployerState } from "../types/onBoarding";

const ConfirmEvaluateCashOutDetails: React.FC = () => {
  const router = useIonRouter();
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const { form, clearForm } = useCashOutForm();
  const { data } = useDashboard();
  const employers = data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [];
  const employer = employers.find(employer => employer.id === form.employerId);
  const { mutate: saveEvaluateCashOut } = useSaveEvaluateCashOutDetails();

  function handleSave() {
    const exerciseData = _.cloneDeep(form);
    saveEvaluateCashOut(exerciseData, {
      onSuccess: () => {
        router.push(`/tabs/evaluate`);
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">{form?.name}</p>
            <button className="w-6" id="click-trigger" onClick={() => setIsPopOverOpen(true)}>
              <MoreVerticalFill strokeWidth={2} size={24} />
            </button>
            <IonPopover isOpen={isPopOverOpen} mode="md"
              onDidDismiss={e => setIsPopOverOpen(false)}
              trigger="click-trigger" triggerAction="click"
            >
              <IonContent class="ion-padding">
                <VStack className="items-center justify-center w-full gap-6 py-3 divide-y-0">
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="mdi:delete-outline"
                      className="focus:text-primary"
                      width="12"
                      height="14" />
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={() => {
                        clearForm(form);
                        router.push("/tabs/evaluate")
                        setIsPopOverOpen(false);
                      }}
                    >
                      Delete
                    </button>
                  </HStack>
                </VStack>
              </IonContent>
            </IonPopover>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <HStack className=" items-center justify-center gap-1 px-2 py-4">
            <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
              {employer?.name.charAt(0)}
            </Center>
            <VStack>
              <h6 className=" text-dark px-2 text-base font-normal">
                {employer?.name}
              </h6>
              <h6 className=" text-grey px-2 text-xs font-normal">
                Dated: {format(new Date(form?.dateOfExercise || new Date()), "dd MMMM yyyy")}
              </h6>
            </VStack>
          </HStack>
          <VStack className=" items-center px-4 mt-2">
            <HStack className=" rounded-xl w-full py-3 text-center border divide-x-2">
              <VStack className="w-1/2 gap-1">
                <h6 className="text-dark text-3xl font-medium">
                  {form?.exercisedOptions}
                </h6>
                <h6 className="text-grey text-xs font-normal">Total no. of options</h6>
              </VStack>
              <VStack className="w-1/2 gap-1">
                <h6 className="text-dark text-3xl font-medium">
                  <span className="font-sans text-xl">{employer?.currency}</span>
                  {form?.fairMarketValue}
                </h6>
                <h6 className="text-grey text-xs font-normal">Price Per Option </h6>
              </VStack>
            </HStack>
          </VStack>
          <VStack className="px-4">
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Total Cash:</span>
              <span className="text-dark">{employer?.currency} {(form?.totalCash || 0).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">No. of Options * (Price Per Options - Strike Price)</span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="mt-7 justify-between">
              <span className="text-dark font-medium">Tax Slab: </span>
              <span className="text-dark">{getTaxPercentage(form?.taxSlab || "")}</span>
            </HStack>
            <span className="text-grey text-xs">Inclusive of salary bracket + the amount cashing out </span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Tax to be Paid: </span>
              <span className="text-dark">{employer?.currency} {(form?.taxToBePaid || 0).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">This is only an estimate of tax based on the salary slab. Final tax may be different and will be intimated by your company.</span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Cash to be Received:  </span>
              <span className="text-dark">{employer?.currency} {((form?.totalCash || 0) - (form?.taxToBePaid || 0)).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">Total Cash - Tax</span>
          </VStack>
          <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-16" />
          <HStack className="justify-around w-full mt-5 text-sm text-white">
            <motion.button
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
              className="text-primary h-9 border-primary focus:opacity-90 whitespace-nowrap p-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
              onClick={handleSave}
            >
              Save for later
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
              className="bg-green h-9 w-20 rounded-lg"
            // onClick={() => handleAccept(grant)}
            >
              Accept
            </motion.button>
          </HStack>
          <span className="text-grey text-start px-4 mt-4 text-[11px]">Accepting this exercise would impact the selected company options</span>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default ConfirmEvaluateCashOutDetails;
