import { IonImg } from "@ionic/react";
import { FunctionComponent } from "react";
import ChaiPointIcon from "../assets/cp.svg";
import { HStack } from "../components/utils";

const Banner2: FunctionComponent = () => {
  return (
    <HStack className="items-center max-w-md gap-1 px-2 py-4 mx-auto bg-secondary rounded-b-xl">
      <IonImg className="w-8 h-8" src={ChaiPointIcon} />
      <h6 className="px-1 py-1 font-normal text-white">
        Chai Point employees have hit the jackpot with stock options
      </h6>
    </HStack>
  );
};

export default Banner2;
