import { GrantOnboarding } from "../store/useAddGrantForm";
import { CashOutDetails } from "../store/useEvaluateCashOutForm";
import { ExerciseDetails } from "../store/useExerciseForm";
import { OnboardingDetails } from "../store/useOnboardingForm";
import { ProfileEditDetails } from "../store/useProfileEditForm";
import {
  Currency,
  CustomRes,
  Dashboard,
  Employer,
  FAQ,
  Grant,
  GrantEvent,
  SignUp,
  Template,
  Token,
  UpdateUserDto,
  VerifyDto,
  Waitlist,
} from "../types/onBoarding";
import empApi from "./empApi";
import { sampleCurrencyRates } from "./sampleCurrencyRates";

export async function getAll(): Promise<CustomRes<Dashboard>> {
  const res = await empApi.get("/onboarding/dashboard");
  const data = res.data as CustomRes<Dashboard>;
  data.data.employers.forEach((employer) =>
    employer.grants?.forEach(
      (g) => (g.perSharePrice = Number(employer.latestSharePrice))
    )
  );
  return data;
}

export async function updateAll(
  data: ProfileEditDetails
): Promise<CustomRes<Dashboard>> {
  const res = await empApi.put("/onboarding/dashboard", data);
  return res.data;
}

export async function saveInitialDetails(
  data: OnboardingDetails
): Promise<CustomRes<any>> {
  const res = await empApi
    .post("/onboarding/initialDetails", data)
    .then((res) => res.data);
  return res.data;
}

export async function addEmployer(
  employerDto: Employer
): Promise<CustomRes<Employer>> {
  const res = await empApi.post("/onboarding/employee", employerDto);
  return res.data;
}

export async function addGrant(data: GrantOnboarding): Promise<Grant> {
  const res = await empApi
    .post("/onboarding/grants", data)
    .then((res) => res.data);
  return res.data;
}

export async function addCustomTemplate(data: Template): Promise<Template> {
  const res = await empApi
    .post("/onboarding/employer/customTemplates", data)
    .then((res) => res.data);
  return res.data;
}

export async function updateGrant(
  data: GrantOnboarding
): Promise<CustomRes<Grant>> {
  const res = await empApi
    .put("/onboarding/grants", data)
    .then((res) => res.data);
  return res.data;
}

export async function updateEmployer(
  employerDto: Employer
): Promise<CustomRes<Employer>> {
  const res = await empApi
    .put("/onboarding/employee", employerDto)
    .then((res) => res.data);
  return res.data;
}

export async function deleteEmployer(id: number) {
  const res = await empApi
    .delete(`/onboarding/employee/${id}`)
    .then((res) => res.data);
  return res.data;
}

export async function deleteGrant(id: number) {
  const res = await empApi
    .delete(`/onboarding/grants/${id}`)
    .then((res) => res.data);
  return res.data;
}

export async function signUp(signUpDto: SignUp): Promise<CustomRes<Token>> {
  return empApi.post("/signup", signUpDto).then((res: any) => res.data);
}

export async function checkWaitList(
  signUpDto: SignUp
): Promise<CustomRes<Waitlist>> {
  return empApi.post("/checkWaitList", signUpDto).then((res: any) => res.data);
}

export async function signIn(signInDto: SignUp): Promise<CustomRes<Token>> {
  return empApi.post("/signin", signInDto).then((res: any) => res.data);
}

export async function getOtp(
  signUpDto: SignUp
): Promise<CustomRes<{ userId: string }>> {
  return empApi.post("/otp", signUpDto).then((res: any) => res.data);
}

export async function resendOtp(signUpDto: SignUp): Promise<CustomRes<any>> {
  return empApi.post("/resendOtp", signUpDto).then((res: any) => res.data);
}

export async function verify(verifyDto: VerifyDto): Promise<CustomRes<Token>> {
  return empApi.post("/verify", verifyDto).then((res: any) => res.data);
}

export async function updateUser(user: UpdateUserDto) {
  return empApi.put("/onboarding/user", user).then((res: any) => res.data);
}

export async function saveEvaluateExerciseDetails(
  data: ExerciseDetails
): Promise<GrantEvent[]> {
  const res = await empApi
    .post("/onboarding/evaluateExercise", data)
    .then((res) => res.data);
  return res.data;
}

export async function saveEvaluateCashOutDetails(
  data: CashOutDetails
): Promise<GrantEvent[]> {
  const res = await empApi
    .post("/onboarding/evaluateCashOut", data)
    .then((res) => res.data);
  return res.data;
}

export async function getFaqs(): Promise<CustomRes<FAQ[]>> {
  const res = await empApi.get("/onboarding/faqs");
  const data = res.data as CustomRes<FAQ[]>;
  return data;
}

export function getCurrencyRates(): Promise<CustomRes<Currency[]>> {
  return new Promise<any>((resolve, reject) => {
    const res: CustomRes<Currency[]> = sampleCurrencyRates;
    resolve(res);
  });
}
