export interface CustomRes<T> {
  message: string;
  data: T;
}

export type SignUp = {
  phoneNumber?: string;
  referralCode?: string;
  emailId?: string;
};

export type VerifyDto = {
  userId?: string;
  otp: string;
};

export type UpdateUserDto = {
  name: string;
  designation: string;
};

export type Token = {
  token: string;
};

export type Waitlist = {
  isInWaitList: boolean;
  isReferred: boolean;
};

export interface Dashboard {
  id: string;
  dateOfBirth: string;
  fullName: string;
  gender: string;
  phoneNumber: string;
  numberOfLoginTries: number;
  isDeactivated: boolean;
  profilePictureUrl: string;
  preferredCurrency: string;
  designation: string;
  referralCode: string;
  employers: Employer[];
}

export type Employer = {
  id?: number;
  cin?: string;
  pan?: string;
  latestSharePrice: number;
  customTemplates?: Template[];
  currency: string;
  name: string;
  dateOfJoining?: Date;
  state: EmployerState;
  lastDay?: Date;
  userId?: number;
  grants?: Grant[];
};

export type Grant = {
  id?: number;
  name: string;
  grantDate: Date;
  noOfOptions: number;
  vestedOptions: number;
  exercisedOptions: number;
  lastDateToExercise: Date;
  letterLink?: string;
  strikePrice: number;
  template?: Template;
  events: GrantEvent[];
  vestingProjection?: VestingProjection[];
  grantState: string;
  employerId?: number;
  templateId?: number;
  perSharePrice?: number;
};

export type GrantEvent = {
  noOfOptions: number;
  date: Date;
  type: GrantEventType;
  grantId: number;
  name?: string;
  fairMarketValue?: number;
  taxSlab?: "";
};

export enum GrantEventType {
  Exercise = "exercise",
  Cashout = "cashout",
  Vesting = "vesting",
  Lapse = "lapse",
  Surrender = "surrender",
  EvaluateExercise = "evaluateExercise",
  EvaluateCashOut = "evaluateCashOut",
}

export enum EmployerState {
  Active = "active",
  Resigned = "resigned",
  Offered = "offered",
}

export type Schedule = {
  srNo: number;
  percentage: number;
  interval: number;
  period: number;
  templateId?: number;
  eventDate?: Date;
  eventName?: string;
  triggerType?: string;
};

export type SignIn = {
  phoneNumber: string;
};

export type FAQ = {
  id: number;
  question: string;
  answer: string;
  section: string;
};

export interface Template {
  employerId?: number;
  id?: string;
  name: string;
  cliff: number;
  schedules: Schedule[];
}

export interface CustomTemplate {
  id?: string;
  name: string;
  cliff: number;
  timeSchedules: Schedule[];
  eventSchedules: Schedule[];
}
export interface VestingProjection {
  date: Date;
  vestedPercentage: number;
  intervalsPassed: number;
  cliffDate: boolean;
  vestedOptions: number;
  accumulatedVestedOptionsForGrant: number;
  accumulatedVestingPercentageForGrant: number;
  isVestDate?: boolean;
  grantId: number;
  employerId: number;
  type: GrantEventType;
}

export type Currency = {
  id: string;
  date: string;
  currency: string;
  rate: number;
};
