import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import "./mySwiper.css";
import IntroSlide1 from "./IntroSlide1";
import IntroSlide2 from "./IntroSlide2";
import IntroSlide3 from "./IntroSlide3";
import { IonContent, IonPage } from "@ionic/react";

export function IntroSlides() {
  return (
    <IonPage>
      <IonContent>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <IntroSlide1 />
          </SwiperSlide>
          <SwiperSlide>
            <IntroSlide2 />
          </SwiperSlide>
          <SwiperSlide>
            <IntroSlide3 />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
}
