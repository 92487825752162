import {
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  ButtonPrimary,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useEffect } from "react";
import { useDashboard, useUpdateGrant } from "../queries/dashboard";
import { format } from "date-fns";
import { GrantOnboarding, useAddOrEditGrantForm } from "../store/useAddGrantForm";
import { getVestingScheduleWithCustomTemplates } from "../utils";
import { EmployerState } from "../types/onBoarding";

const EditOffer: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const { mutate: updateGrant } = useUpdateGrant();
  const id = router.routeInfo.pathname.split("/")[2];
  const { data } = useDashboard();
  let employers = data?.data.employers || [];
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useAddOrEditGrantForm();
  const employer = employers.find(employer => employer.id === grant?.employerId);
  const customVestingSchedules = employer?.customTemplates || [];
  const customVestingScheduleNames = customVestingSchedules.map(vestingSchedule => vestingSchedule.name);
  const vestingScheduleNames = [...customVestingScheduleNames,
    "1 Year Cliff, Monthly Vesting, 4 Years",
    "1 Year Cliff, Quarterly Vesting, 4 Years",
    "1 Year Cliff, Yearly Vesting, 4 Years",
  ];
  useEffect(() => {
    setFieldValue("noOfOptions", grant?.noOfOptions);
    setFieldValue("strikePrice", grant?.strikePrice);
    setFieldValue("grantDate", grant?.grantDate);
    setFieldValue("vestingScheduleName", grant?.template?.name);
    setFieldValue("employerId", grant?.employerId);
    setFieldValue("grantState", grant?.grantState);
    setFieldValue("currency", employer?.currency)
    setFieldValue("name", grant?.name);
    setFieldValue("id", grant?.id);
  }, [grant, setFieldValue, employer]);
  const fields = [
    "noOfOptions",
    "strikePrice",
    "grantDate",
    "vestingScheduleName",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }

  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const grant: GrantOnboarding = {
      id: form.id || 0,
      name: form.name,
      noOfOptions: form.noOfOptions,
      strikePrice: form.strikePrice,
      exercisedOptions: form.exercisedOptions,
      vestedOptions: form.vestedOptions,
      lastDateToExercise: form.lastDateToExercise,
      vestingScheduleName: form.vestingScheduleName || "",
      vestingSchedule: getVestingScheduleWithCustomTemplates(form.vestingScheduleName, customVestingSchedules),
      grantDate: form.grantDate,
      grantState: form?.grantState,
      employerId: form?.employerId || 0,
      employerName: "",
      employerState: EmployerState.Offered,
      currency: form.currency,
      latestSharePrice: 0,
    };
    updateGrant(grant, {
      onSuccess: () => {
        router.goBack();
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Edit Offer Details</p>

            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          {grant?.grantState === "Offered" ?
            (<VStack className="w-full gap-3 px-3 mt-4">
              <Label className=" font-medium">No. of Options</Label>
              <Input
                onChange={(e) => {
                  setFieldValue("noOfOptions", parseInt(e.target.value, 10));
                }}
                onBlur={(e) => {
                  setFieldTouched("noOfOptions");
                }}
                value={form.noOfOptions}
                type="number"
              />
              <Error
                visible={!!(touched.noOfOptions && errors.noOfOptions)}
                text={errors?.noOfOptions}
              />
              <Label className=" font-medium">Strike Price</Label>
              <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                <IonSelect
                  aria-label="fruit"
                  className="w-1/5 h-10 font-sans min-w-[60px]"
                  mode="ios"
                  interface="popover"
                  autoSave="yes"
                  value={form.currency}
                  onIonChange={(e) =>
                    setFieldValue("currency", e.target.value)
                  }
                >
                  {currencies.map((name) => (
                    <IonSelectOption key={name} value={name} className="text-xs">
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <input
                  className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                  onChange={(e) => {
                    setFieldValue("strikePrice", parseFloat(e.target.value));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("strikePrice");
                  }}
                  value={form.strikePrice}
                  type="number"
                />
              </HStack>
              <Label className=" w-full font-medium">Potential Joining Date</Label>
              <Input
                onChange={(e) => {
                  setFieldValue("grantDate", new Date(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("grantDate");
                }}
                value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
                type="date"
              />
              <Label className=" w-full font-medium">Vesting Schedule</Label>
              <IonSelect
                aria-label="fruit"
                className="form-select"
                placeholder="Select Vesting Schedule"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form?.vestingScheduleName}
                onIonChange={(e) => {
                  if (e.target.value === "Custom(Set as default, fill it out later)") {
                    router.push("/customVestingSchedule");
                  }
                  else {
                    setFieldValue("vestingScheduleName", e.target.value)
                  }
                }
                }
              >
                {vestingScheduleNames.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <HStack className="mt-28 items-center justify-center text-sm">
                <ButtonPrimary className=" px-8" onClick={submitForm}>
                  Submit
                </ButtonPrimary>
              </HStack>
            </VStack>) : (<>
              <VStack className="w-full gap-3 px-3 mt-4">
                <Label className=" font-medium">Compensation Offered</Label>
                <HStack className=" border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                  <IonSelect
                    aria-label="fruit"
                    className="w-1/5 h-10 font-sans min-w-[60px]"
                    mode="ios"
                    interface="popover"
                    autoSave="yes"
                    value={form.currency}
                    onIonChange={(e) =>
                      setFieldValue("currency", e.target.value)
                    }
                  >
                    {currencies.map((name) => (
                      <IonSelectOption key={name} value={name} className="text-xs">
                        {name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <Input
                    onChange={(e) => {
                      setFieldValue("noOfOptions", parseInt(e.target.value, 10));
                    }}
                    onBlur={(e) => {
                      setFieldTouched("noOfOptions");
                    }}
                    value={form.noOfOptions}
                    type="number"
                    className="focus:outline-none active:outline-none w-4/5 border-none outline-none"
                  />
                  <Error
                    visible={!!(touched.noOfOptions && errors.noOfOptions)}
                    text={errors?.noOfOptions}
                  />
                </HStack>
                <Label className=" w-full font-medium">Potential Joining Date</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("grantDate", new Date(e.target.value));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("grantDate");
                  }}
                  value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
                  type="date"
                />

                <HStack className="mt-28 items-center justify-center text-sm">
                  <ButtonPrimary className=" px-8" onClick={submitForm}>
                    Submit
                  </ButtonPrimary>
                </HStack>
              </VStack>
            </>)}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditOffer;
