import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  VStack,
  Label,
  Input,
  HStack,
  ButtonPrimary,
  Error,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useOnboardingForm } from "../store/useOnboardingForm";
import { getFieldProps } from "../store/zustandForm";
import _ from "lodash";
import { usePersonalInfoForm } from "./ProfileInfo";
import { useDashboard, useSaveInitialDetails } from "../queries/dashboard";
import { getVestingScheduleWithCustomTemplates } from "../utils";
import { EmployerState } from "../types/onBoarding";

const CompanyInformation: React.FC = () => {
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useOnboardingForm();
  const { data } = useDashboard();
  const { mutate: saveInitialDetails } = useSaveInitialDetails();
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const fields = ["pps", "companyName"];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }

  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const data = _.cloneDeep(form);
    data.grant.name = "Grant 1";
    data.grant.vestingSchedule = getVestingScheduleWithCustomTemplates(data.grant.vestingScheduleName, []);
    data.state = EmployerState.Active;
    saveInitialDetails(data, {
      onSuccess: () => {
        router.push("/exploreJourney");
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-7" size={20} onClick={() => router.goBack()} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <h3 className="w-full px-1 mt-2 text-xl font-bold">
            Hey {data?.data.fullName}!
          </h3>
          <h4 className="w-full px-1 mt-1 text-sm font-normal">
            Please enter your company information so you can easily track
            everything
          </h4>
          <VStack className="mt-7 w-full h-full gap-3 px-3">
            <Label className="w-full font-medium">Company Name</Label>
            <Input
              {...getFieldProps("name", useOnboardingForm())}
              type="text"
            />
            <Error
              visible={!!(touched.name && errors.name)}
              text={errors?.name}
            />
            <Label className="w-full mt-5 font-medium">Price Per Share</Label>
            <p className="text-grey text-start w-full text-xs font-light">
              A share price is the price of a single share of a number of
              saleable equity shares of a company.
            </p>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) => setFieldValue("currency", e.target.value)}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue("latestSharePrice", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("latestSharePrice");
                }}
                value={form.latestSharePrice}
                type="number"
              />
            </HStack>
            <Error
              visible={!!(touched.latestSharePrice && errors.latestSharePrice)}
              text={errors?.latestSharePrice}
            />
            <HStack className="mt-72 items-center justify-center">
              <ButtonPrimary className="px-12" onClick={submitForm}>
                Submit
              </ButtonPrimary>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CompanyInformation;
