/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Line } from "react-chartjs-2";
import { format } from "date-fns/esm";
import { formatToCurrency, formatToNumber } from "../utils";
import { Box, Center, HStack, VStack } from "../components/utils";
import { Icon } from "@iconify/react";
import { getCurrencyMultiplier, useCurrencyRates, useDashboard, useVestingsForForeCastChart } from "../queries/dashboard";
import _ from "lodash";
import { addYears } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useCompany } from "../store/useCompany";
import { Currency, CustomRes, Employer, EmployerState, VestingProjection } from "../types/onBoarding";
import { config, getVestingsForDateRange } from "./ForeCastChartForGrant";
import { ChartJSOrUndefined, ForwardedRef } from "react-chartjs-2/dist/types";

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E85936"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#002CA4"
  },
}));


const verticalLinePlugin = {
  id: "vline-plugin",
  getLinePosition: function (chart: any, pointIndex: number) {
    const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
    const data = meta.data;
    return data[pointIndex]._model.x;
  },
  renderVerticalLine: function (chartInstance: any, pointIndex: number) {
    const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
    const scale = chartInstance.scales["y-axis-0"];
    const context = chartInstance.chart.ctx;

    // render vertical line
    context.beginPath();
    context.strokeStyle = "#ff0000";
    context.moveTo(lineLeftOffset, scale.top);
    context.lineTo(lineLeftOffset, scale.bottom);
    context.stroke();

    // write label
    context.fillStyle = "#ff0000";
    context.textAlign = "center";
    context.fillText(
      "MY TEXT",
      lineLeftOffset,
      (scale.bottom - scale.top) / 2 + scale.top
    );
  },

  afterDatasetsDraw: function (chart: any, easing: any) {
    if (chart.config.lineAtIndex) {
      chart.config.lineAtIndex.forEach((pointIndex: any) =>
        this.renderVerticalLine(chart, pointIndex)
      );
    }
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  verticalLinePlugin
);


type ChartPeriod = "1Y" | "2Y" | "3Y" | "4Y" | "5Y" | "All";

export function ForecastChart() {
  const ref: ForwardedRef<ChartJSOrUndefined<"line", number[], string>> = useRef(null);
  const { filteredVesting: _vestings, totalGranted, isLoading } = useVestingsForForeCastChart();
  const { state: company, setState: setCompany } = useCompany();
  const { data: dashBoardData } = useDashboard();
  const employers = useMemo(() => dashBoardData?.data.employers.filter(employer => employer.state === EmployerState.Active) || [], [dashBoardData]);
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    } else if (company !== "All" && employers.length > 0 && !employers.find(employer => employer.name === company) && employers.length > 0) {
      setCompany(employers[employers.length - 1].name);
    }
  }, []);
  const companyNames =
    employers.length > 1
      ? ["All", ...employers.map((e) => e.name)]
      : employers.map((e) => e.name);
  const employer = employers.find(employer => employer.name === company);
  const vestings = useMemo(() => _vestings, [employer, isLoading]);
  const { data: currencyData } = useCurrencyRates();
  const [vested, setVested] = useState(0);
  const [netWorth, setNetWorth] = useState("0");
  const [originalSharePrice, setOriginalSharePrice] = useState(1);
  const [sharePrice, setSharePrice] = useState(originalSharePrice);
  const [sliderValue, setSliderValue] = useState(25);
  const [selectedDate, setSelectedDate] = useState(format(new Date(), "dd-MMM-yyyy"));
  const [dateRange, setDateRange] = useState([
    new Date("2000-01-01"),
    new Date("2099-01-01"),
  ]);
  const [period, setPeriod] = useState<ChartPeriod>("All");
  const currencies = ["₹", "$", "¥", "€"];
  const [currency, setCurrency] = useState(currencies[0]);
  const addedVestings = useMemo(() => {
    const vestingsToBeAdded = getVestingsForDateRange(dateRange, vestings);
    const filteredVesting = vestings
      .filter(
        (v) =>
          new Date(v.date).getTime() >= dateRange[0].getTime() &&
          new Date(v.date).getTime() <= dateRange[1].getTime()
      ).filter(vesting => vesting.date);
    return _.sortBy([...vestingsToBeAdded, ...filteredVesting], v => new Date(v.date));
  }, [dateRange]);
  const labels = addedVestings
    .map((v) => format(new Date(v.date), "MMM-yyyy"));
  const values = (addedVestings
    .map((v) => v.accumulatedVestedOptionsForGrant));
  const [useCustomPrice, setUseCustomPrice] = useState(false);
  useEffect(() => {
    const indexToBeSet = Math.round((addedVestings.length - 1) / 2);
    if (ref.current && indexToBeSet > 0 && addedVestings.length > 0) {
      ref.current?.setActiveElements([{ datasetIndex: 0, index: indexToBeSet }]);
      const date = format(addedVestings[indexToBeSet].date, "MMM-yyyy");
      setSelectedDate(date);
      setVested(addedVestings[indexToBeSet].accumulatedVestedOptionsForGrant);
      setNetWorth(calculateNetWorth(vestings, sharePrice, date, employers, dashBoardData?.data.preferredCurrency || "₹", currencyData));
    }
  }, [useCustomPrice, dateRange]);
  useEffect(() => {
    if (period !== "All") {
      setDateRange([
        new Date(new Date().getFullYear(), 0, 1),
        addYears(
          new Date(new Date().getFullYear(), 0, 1),
          parseInt(period[0], 10)
        ),
      ]);
    }
    else setDateRange([new Date(vestings[0]?.date || new Date()), new Date(vestings[vestings.length - 1]?.date || new Date())]);
  }, [period, vestings]);
  console.log(vestings);
  useEffect(() => {
    const indexToBeSet = addedVestings.findIndex(v => v.date > new Date(selectedDate));
    if (ref.current && ref.current?.getActiveElements().length === 0 && indexToBeSet > 0 && addedVestings.length > 0) {
      ref.current?.setActiveElements([{ datasetIndex: 0, index: indexToBeSet }]);
      const date = format(addedVestings[indexToBeSet].date, "MMM-yyyy");
      setSelectedDate(date);
      setNetWorth(calculateNetWorth(vestings, sharePrice, date, employers, dashBoardData?.data.preferredCurrency || "₹", currencyData));
    }
  }, [ref.current?.getActiveElements()]);
  useEffect(() => {
    if (employer) {
      setCurrency(employer.currency);
      if (employer.latestSharePrice === 0) {
        setOriginalSharePrice(1);
      }
      else {
        setOriginalSharePrice(employer.latestSharePrice);
      }
    }
  }, [employer]);
  useEffect(() => {
    setNetWorth(calculateNetWorth(vestings, sharePrice, selectedDate, employers, dashBoardData?.data.preferredCurrency || "₹", currencyData))
  }, [sharePrice]);
  useEffect(() => {
    setSharePrice(parseInt((originalSharePrice * getMultiplier(sliderValue)).toFixed(0), 10));
  }, [originalSharePrice]);
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Vested",
        data: values,
        pointStyle: "circle",
        showLine: true,
        pointRadius: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#E75935",
        yAxisID: "y",
        xAxisID: "x",
        borderColor: "#989797",
        borderWidth: 1.5,
        hoverBorderWidth: 0,
        totalGranted,
        sharePrice,
        onTooltipChange(data: any) {
          if (data.vested !== "") {
            setVested(formatToNumber(data.vested));
          }
          if (data.date !== "") {
            setSelectedDate(data.date);
            setNetWorth(calculateNetWorth(vestings, sharePrice, data.date, employers, dashBoardData?.data.preferredCurrency || "₹", currencyData))
          }
        },
      },
    ],
  };
  return (
    <div className="p-2 px-4">
      <p className="text-grey text-xs">
        By manipulating PPS (Price Per Share) and time, you can determine an estimation of your worth.
      </p>
      <HStack className="justify-start w-full">
        <IonSelect
          aria-label="fruit"
          className="h-9 w-40 mt-4 mr-4 text-center border rounded-md"
          placeholder=""
          mode="ios"
          interface="popover"
          autoSave="yes"
          value={company}
          onIonChange={(e) => setCompany(e.target.value)}
        >
          {companyNames.map((name) => (
            <IonSelectOption key={name} value={name} className="text-xs">
              {name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </HStack>
      <VStack className="rounded-xl items-center mt-4">
        <span className="text-primary text-4xl font-medium">
          <span className="font-sans"> {company === "All" ? dashBoardData?.data.preferredCurrency : employer?.currency} </span>
          {formatToNumber(netWorth) > 0 ?
            <span>{netWorth}</span> : <span> 0 </span>}
        </span>
        <HStack className="items-center justify-center mt-1">
          {period !== "All" ? (
            <Box className="text-dark py-4 text-xs font-normal text-center">
              This will be your estimated worth as of {selectedDate} if you stay in this company for {period.replace("Y", "")}
              {" "} years at PPS of {formatToCurrency(sharePrice)}.
            </Box>
          ) : (
            <p className="py-4 text-xs text-center">
              This will be your estimated worth as of {selectedDate}
            </p>
          )}
        </HStack>
        <BorderLinearProgress
          className="w-full"
          variant="determinate"
          value={((vested) / totalGranted) * 100}
        />
        <HStack className="justify-between w-full">
          <h6 className="text-grey text-sm font-medium">Vested:
            <span className="text-secondary">
              {(vested)}
            </span>
          </h6>
          <h6 className="text-grey text-sm font-medium">To be Vested:
            <span className="text-primary">
              {totalGranted - vested}
            </span>
          </h6>
        </HStack>
      </VStack>
      <Line className="mt-4" ref={ref} options={config as any} data={data} />
      <h6 className="text-grey text-xs">Move the pointer on the graph to determine worth. </h6>
      <h6 className="text-dark mt-4">Timeline</h6>
      <HStack className="text-grey justify-between max-w-md mx-auto mt-4 text-xs">
        {["1Y", "2Y", "3Y", "4Y", "5Y", "All"].map((i) => {
          const active = period === i;
          return (
            <Center
              key={i}
              onClick={() => setPeriod(i as ChartPeriod)}
              className={`shadow-md w-9 h-9 rounded-md ${active ? "border-primary border text-primary" : ""
                }`}
            >
              {i}
            </Center>
          );
        })}
      </HStack>
      <VStack className="max-w-md mx-auto mt-8">
        {company !== "All" &&
          <label className="text-dark dark:text-white block mb-4">
            Price Per Share :<span className="ml-2 font-sans">{employer?.currency}</span>
            <span className="ml-1 text-xs font-semibold">
              {formatToCurrency(sharePrice)}
            </span>
          </label>}
        <HStack className="justify-between gap-4 align-top">
          <AnimatePresence>
            {!useCustomPrice ? (
              <>
                <motion.div
                  className="w-full h-10"
                  key="input-1"
                  initial={{ x: -50 }}
                  animate={{ x: 0 }}
                >
                  <input
                    id="default-range"
                    type="range"
                    min={0}
                    max={100}
                    value={sliderValue}
                    onChange={(e) => {
                      setSharePrice(
                        parseInt(((originalSharePrice) *
                          getMultiplier(parseFloat(e.target.value))
                        ).toFixed(0), 10)
                      );
                      setSliderValue(parseFloat(e.target.value));
                    }}
                    className="w-full h-1.5 bg-neutral-300 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-primary"
                  />
                  <HStack className="text-neutral-500 justify-between mt-2 text-xs">
                    {[0.5, 1, 10, 50, 100].map((i) => (
                      <label key={i}>{i}X</label>
                    ))}
                  </HStack>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                  onClick={() => setUseCustomPrice((prev) => !prev)}
                  className="h-min p-2 bg-white border rounded-full shadow-md cursor-pointer"
                >
                  {!useCustomPrice ? (<Icon icon="eva:keypad-outline" height={20} />) : (<Icon icon="tabler:arrows-exchange-2" height={20} />)}
                </motion.div>
              </>
            ) : (
              <>
                <HStack className=" border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                  <IonSelect
                    aria-label="fruit"
                    className="w-1/5 h-10 font-sans min-w-[60px]"
                    mode="ios"
                    interface="popover"
                    autoSave="yes"
                    value={currency}
                    onIonChange={(e) =>
                      setCurrency(e.target.value)
                    }
                  >
                    {currencies.map((name) => (
                      <IonSelectOption key={name} value={name} className="text-xs">
                        {name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <motion.input
                    key="input-2"
                    initial={{ x: 50 }}
                    animate={{ x: 0 }}
                    id="custom-price"
                    value={formatToCurrency(originalSharePrice)}
                    onChange={(e) => {
                      setOriginalSharePrice(formatToNumber(e.target.value));
                      setSliderValue(25);
                    }
                    }
                    className=" focus:outline-none active:outline-none w-4/5 bg-white border-none outline-none"
                  />

                </HStack >
                <motion.div
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                  onClick={() => setUseCustomPrice((prev) => !prev)}
                  className="h-min p-2 bg-white border rounded-full shadow-md cursor-pointer"
                >
                  {!useCustomPrice ? (<Icon icon="eva:keypad-outline" height={20} />) : (<Icon icon="tabler:arrows-exchange-2" height={20} />)}
                </motion.div>
              </>
            )}
          </AnimatePresence>

        </HStack>
        {!useCustomPrice && <p className="text-grey mt-4 text-xs">
          You can enter the Price Per Share manaully from the keypad icon
        </p>}
      </VStack>
    </div>
  );
}

function getMultiplier(val: number) {
  if (val >= 0 && val < 25) {
    return 0.5 + ((1 - 0.5) * (val - 0)) / 25;
  } else if (val >= 25 && val < 50) {
    return 1 + ((10 - 1) * (val - 25)) / 25;
  } else if (val >= 50 && val < 75) {
    return 10 + ((50 - 10) * (val - 50)) / 25;
  } else if (val >= 75 && val <= 100) {
    return 50 + ((100 - 50) * (val - 75)) / 25;
  } else return 1;
}
export function calculateNetWorth(addedVestings: VestingProjection[], sharePrice: number, selectedDate: string, employers: Employer[], preferredCurrency: string, currencyData?: CustomRes<Currency[]>) {
  let netWorth = 0;
  const grants = employers.map(employer => employer.grants || []).flat();
  const dateBeforeVestings = new Date(selectedDate);
  dateBeforeVestings.setMonth(dateBeforeVestings.getMonth() + 1);
  const vestingsBeforeSelectedDate = addedVestings.filter(v => new Date(v.date) <= dateBeforeVestings);
  vestingsBeforeSelectedDate.forEach((vesting) => {
    const grant = grants.find((grant) => grant.id === vesting.grantId);
    const employer = employers.find((employer) => employer.id === vesting.employerId);
    if (grant && employer) {
      netWorth +=
        vesting.vestedOptions *
        (sharePrice - grant.strikePrice) *
        getCurrencyMultiplier(
          preferredCurrency,
          employer.currency,
          currencyData
        );
    }
  });
  return formatToCurrency(netWorth);
}

