import { create } from "zustand";
import { configurePersist } from "zustand-persist";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root2",
});
const persist = _persist as any;

interface StateStore<T> {
  state: T;
  setState: (state: T | ((state: T) => T)) => void;
}

export function zustandState<T>(initialState: T) {
  return create<StateStore<T>>((set: any) => ({
    state: initialState,
    setState: (input) => {
      if (typeof input === "function") {
        const _input = input as (state?: T) => T;
        return set((store: any) => ({ ...store, state: _input(store.state) }));
      } else {
        return set((store: any) => ({ ...store, state: input }));
      }
    },
  }));
}

export type Setter<T> = (state: T | ((state?: T) => T)) => void;

export type StateStore1<T> = [T, Setter<T>];

export function zustandState1<T>(initialState: T) {
  return create<StateStore1<T>>((set: any) => {
    const s = [
      initialState,
      (input: any) => {
        if (typeof input === "function") {
          const _input = input as (state?: T) => T;
          return set((store: any) => [_input(store.state), store[1]]);
        } else {
          return set((store: any) => {
            return [input, store[1]];
          });
        }
      },
    ];
    return [s[0] as T, s[1] as Setter<T>];
  });
}

export function zustandPersistedState<T>(initialState: T, key: string) {
  return create<StateStore<T>>(
    persist(
      {
        key,
      },
      (set: any) => ({
        state: initialState,
        setState: (input: any) => {
          if (typeof input === "function") {
            const _input = input as (state?: T) => T;
            return set((store: any) => ({
              ...store,
              state: _input(store.state),
            }));
          } else {
            return set((store: any) => ({ ...store, state: input }));
          }
        },
      })
    )
  );
}
