import { zustandForm } from "./zustandForm";
import { z } from "zod";

const initialValues = {
  id: "",
  referralCode: "",
};
const validationSchema = z.object({
  id: z.string().min(1, "Please enter your mobile Number/email address"),
  referralCode: z.string().optional(),
});

export const useAuthenticationForm = zustandForm({
  initialValues,
  validationSchema,
});
