import { FunctionComponent } from "react";
import { Center, VStack } from "../components/utils";
import HissaIcon from "../assets/hissa.svg";
import Illustration2 from "../assets/illustration2.svg";
import { Icon } from "@iconify/react";
import { useSwiper } from "swiper/react";
import { IonImg, IonRippleEffect } from "@ionic/react";

const IntroScreen2: FunctionComponent = () => {
  const swiper = useSwiper();
  return (
    <Center className="h-screen bg-white">
      <VStack className="items-center gap-4 mx-10 text-black h-3/4">
        <IonImg className=" w-[139px] h-[50.55px]" src={HissaIcon} />
        <IonImg
          className="sm:w-3/4 md:w-1/2 aspect-square"
          src={Illustration2}
        />
        <h6 className="text-xl font-medium">See Your Wealth Forecast</h6>
        <div className="relative flex items-center self-end justify-center w-20 h-20 mt-8 overflow-hidden border-2 rounded-full ion-activatable">
          <IonRippleEffect></IonRippleEffect>
          <button onClick={() => swiper.slideNext()}>
            <Icon
              icon="material-symbols:arrow-forward-ios-rounded"
              color="#E85936"
              width={32}
            />
          </button>
        </div>
      </VStack>
    </Center>
  );
};

export default IntroScreen2;
