import { IonImg } from "@ionic/react";
import { FunctionComponent } from "react";
import HattiKappiIcon from "../assets/hk.svg";
import { HStack } from "../components/utils";

const Banner3: FunctionComponent = () => {
  return (
    <HStack className="items-center max-w-md gap-1 px-2 py-4 bg-secondary rounded-b-xl mx-auto">
      <IonImg className="w-8 h-8" src={HattiKappiIcon} />
      <h6 className="px-1 py-1 font-normal text-white">
        Employees at Hatti Kappi have made it big from their stock options
      </h6>
    </HStack>
  );
};

export default Banner3;
