import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Center,
  HStack,
  VStack,
} from "../components/utils";

import HissaIcon from "../assets/hissa.svg";
import { useEffect } from "react";

const SignUp: React.FC = () => {
  useEffect(() => {
    if (window.location.search.includes("inviteCode")) {
      const splitString = window.location.search.split("=");
      localStorage.setItem("inviteCode", splitString[1]);
    }
  }, []);

  const router = useIonRouter();
  return (
    <IonPage>
      <IonContent>
        <Center className="h-screen bg-white min-w-min max-w-md mx-auto">
          <VStack className="w-full mt-[50%] h-min items-center">
            <IonImg className=" w-[139px] h-[50.55px] mb-36" src={HissaIcon} />
            <h6 className="mb-10 text-xl font-medium">
              Easiest Way To Track Everything
            </h6>
            <HStack className="flex w-full max-w-md p-4 justify-evenly">
              <ButtonPrimary
                className="px-12"
                onClick={() => {
                  router.push("/authPage");
                }}
              >
                Sign Up
              </ButtonPrimary>
              <ButtonPrimary1
                className="px-12 "
                onClick={() => {
                  router.push("/authPage");
                }}
              >
                Login
              </ButtonPrimary1>
            </HStack>
          </VStack>
        </Center>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
