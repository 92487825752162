import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import "./mySwiper.css";
import Banner1 from "./Banner1";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";

export function BannerSlides() {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
    >
      <SwiperSlide>
        <Banner1 />
      </SwiperSlide>
      <SwiperSlide>
        <Banner2 />
      </SwiperSlide>
      <SwiperSlide>
        <Banner3 />
      </SwiperSlide>
    </Swiper>
  );
}
