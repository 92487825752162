import jwtDecode from "jwt-decode";
import _ from "lodash";
import { getTotalVested } from "./queries/dashboard";
import {
  GrantEvent,
  Grant,
  Employer,
  GrantEventType,
  Template,
} from "./types/onBoarding";

export const currenyFormatter = new Intl.NumberFormat("en-in", {});

export const compactCurrenyFormatter = new Intl.NumberFormat("en-in", {
  notation: "compact",
});

export function formatToCurrency(val: number) {
  return currenyFormatter.format(Number(val.toFixed(2)));
}

export function fomatCompact(val: number) {
  return compactCurrenyFormatter.format(val);
}

export function formatToNumber(text: string) {
  const num = parseFloat(text.replaceAll(",", ""));
  if (isNaN(num) || text === "") return 0;
  else return num;
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string;
    emailId: string;
  };
  return decodedToken;
}

export function getExerciseCost(
  grantEvents: GrantEvent[],
  grantIdMap: Map<number, Grant>
) {
  let exerciseCost = 0;
  for (const grantEvent of grantEvents) {
    const grant = grantIdMap.get(grantEvent.grantId);
    if (!grant) continue;
    exerciseCost += grant.strikePrice * grantEvent.noOfOptions;
  }
  return exerciseCost;
}

export function getTaxToBePaid(
  exerciseEvents: GrantEvent[],
  grantIdMap: Map<number, Grant>
) {
  let taxableIncome = 0;
  const fairMarketValue = exerciseEvents[0]?.fairMarketValue || 0;
  exerciseEvents.forEach((event) => {
    taxableIncome += event?.noOfOptions * fairMarketValue;
  });
  taxableIncome = taxableIncome - getExerciseCost(exerciseEvents, grantIdMap);
  return (
    (taxableIncome *
      (getTaxPercentageNumber(exerciseEvents[0]?.taxSlab || "") || 0)) /
    100
  );
}

export function getTaxPercentageNumber(taxSlab: string) {
  if (taxSlab === "0 - 2.5L") {
    return 0;
  } else if (taxSlab === "2.5L - 5L") {
    return 5;
  } else if (taxSlab === "5L - 7.5L") {
    return 10;
  } else if (taxSlab === "7.5L - 10L") {
    return 15;
  } else if (taxSlab === "10L - 12.5L") {
    return 20;
  } else if (taxSlab === "12.5L - 15L") {
    return 25;
  } else if (taxSlab === "Above 15L") {
    return 30;
  }
}

export function getTaxPercentage(taxSlab: string) {
  if (taxSlab === "0 - 2.5L") {
    return "Nil";
  } else if (taxSlab === "2.5L - 5L") {
    return "5%";
  } else if (taxSlab === "5L - 7.5L") {
    return "10%";
  } else if (taxSlab === "7.5L - 10L") {
    return "15%";
  } else if (taxSlab === "10L - 12.5L") {
    return "20%";
  } else if (taxSlab === "12.5L - 15L") {
    return "25%";
  } else if (taxSlab === "Above 15L") {
    return "30%";
  }
}

export function getTotalCash(
  grantEvents: GrantEvent[],
  grantIdMap: Map<number, Grant>
) {
  let totalCash = 0;
  for (const grantEvent of grantEvents) {
    const grant = grantIdMap.get(grantEvent.grantId);
    if (!grant) continue;
    totalCash +=
      ((grantEvent?.fairMarketValue || 0) - grant.strikePrice) *
      grantEvent.noOfOptions;
  }
  return totalCash;
}

export function getEmployerIdFromGrantId(
  grantId: number,
  grantIdMap: Map<number, Grant>
) {
  if (grantId === 0) return 0;
  const grant = grantIdMap.get(grantId);
  return grant?.employerId;
}

export function getOfferName(employers: Employer[]) {
  const grantNames = employers
    .map((e) => e.grants || [])
    .flat()
    .map((g) => g?.name);
  for (let i = 1; ; i++) {
    const offerName = `Offer ${i}`;
    if (!grantNames.includes(offerName)) {
      return offerName;
    }
  }
}

export function getExerciseName(
  employer: Employer,
  grantEventType: GrantEventType
) {
  const grants = employer?.grants || [];
  let exerciseName = "Exercise 1";
  const evaluateExerciseEvents = grants
    .map((grant) => grant.events)
    .flat()
    .filter((event) => event.type === grantEventType);
  if (evaluateExerciseEvents.length > 0) {
    const eventNames = _.uniqBy(evaluateExerciseEvents, (e) => e.name)
      .map((e) => e.name || "")
      .filter((e) => e);
    for (let i = 1; ; i++) {
      exerciseName = `Exercise ${i}`;
      if (!eventNames.includes(exerciseName)) {
        return exerciseName;
      }
    }
  }
  return exerciseName;
}
export function getEmployerIdFromName(name: string, employers: Employer[]) {
  return employers?.find((e) => e.name === name)?.id;
}

export function getOpacity(index: number) {
  if (index === 0) {
    return 1;
  } else if (index === 1) {
    return 0.8;
  } else if (index === 2) {
    return 0.6;
  } else if (index === 3) {
    return 0.4;
  } else {
    return 0.2;
  }
}

export function getGrantEventsOnFIFOLogic(
  noOfOptions: number,
  grants: Grant[],
  grantEventType: GrantEventType
) {
  const grantEvents: GrantEvent[] = [];
  const sortedGrants = _.sortBy(
    grants.filter((grant) => grant.grantState === "Accepted"),
    (grant) => grant.grantDate
  );
  let remainingToBeExercised = noOfOptions;
  sortedGrants.forEach((grant) => {
    const vestedOptions = getTotalVested(grant);
    if (remainingToBeExercised > 0 && vestedOptions > 0) {
      if (remainingToBeExercised - vestedOptions > 0) {
        grantEvents.push({
          grantId: grant.id || 0,
          noOfOptions: vestedOptions,
          type: grantEventType,
          date: new Date(),
        });
        remainingToBeExercised -= vestedOptions;
      } else {
        grantEvents.push({
          grantId: grant.id || 0,
          noOfOptions: remainingToBeExercised,
          type: grantEventType,
          date: new Date(),
        });
        remainingToBeExercised = 0;
      }
    }
  });
  return grantEvents;
}

export function getCashOutName(
  employer: Employer,
  grantEventType: GrantEventType
) {
  const grants = employer?.grants || [];
  let exerciseName = "Cash Out 1";
  const evaluateExerciseEvents = grants
    .map((grant) => grant.events)
    .flat()
    .filter((event) => event.type === grantEventType);
  if (evaluateExerciseEvents.length > 0) {
    const eventNames = _.uniqBy(evaluateExerciseEvents, (e) => e.name)
      .map((e) => e.name || "")
      .filter((e) => e);
    for (let i = 1; ; i++) {
      exerciseName = `Cash Out ${i}`;
      if (!eventNames.includes(exerciseName)) {
        return exerciseName;
      }
    }
  }
  return exerciseName;
}

export function getGrantsFromGrantEvents(
  grantEvents: GrantEvent[],
  grantIdMap: Map<number, Grant>
) {
  const grants: Grant[] = [];
  grantEvents.forEach((grantEvent) => {
    const grant = grantIdMap.get(grantEvent.grantId);
    if (grant) {
      grants.push(grant);
    }
  });
  return grants;
}

export function getGrantName(grants: Grant[]) {
  const grantNames = grants
    .filter((grant) => grant.grantState === "Accepted")
    .map((grant) => grant.name);
  let grantName = "Grant 1";
  for (let i = 1; ; i++) {
    grantName = `Grant ${i}`;
    if (!grantNames.includes(grantName)) {
      return grantName;
    }
  }
}

export function getVestingScheduleWithCustomTemplates(
  name: string,
  customTemplates: Template[]
): Template {
  const template = customTemplates.find((template) => template.name === name);
  if (template) {
    return template;
  } else if (name === "1 Year Cliff, Monthly Vesting, 4 Years") {
    return {
      cliff: 12,
      name,
      schedules: [{ srNo: 1, percentage: 1, interval: 1, period: 48 }],
    };
  } else if (name === "1 Year Cliff, Quarterly Vesting, 4 Years") {
    return {
      cliff: 12,
      name,
      schedules: [{ srNo: 1, percentage: 1, interval: 3, period: 48 }],
    };
  } else if (name === "1 Year Cliff, Yearly Vesting, 4 Years") {
    return {
      cliff: 12,
      name,
      schedules: [{ srNo: 1, percentage: 1, interval: 12, period: 48 }],
    };
  } else {
    return {
      cliff: 12,
      name,
      schedules: [{ srNo: 1, percentage: 0, interval: 0, period: 0 }],
    };
  }
}
