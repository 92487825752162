/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  VStack,
  Label,
  Input,
  HStack,
  ButtonPrimary,
  Error,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { getFieldProps } from "../store/zustandForm";
import { useDashboard, useUpdateEmployer, useUpdateGrant } from "../queries/dashboard";
import { Employer } from "../types/onBoarding";
import { useEffect } from "react";
import { useAddOrEditCompanyForm } from "../store/useAddCompanyForm";
import { GrantOnboarding, useAddOrEditGrantForm } from "../store/useAddGrantForm";
import { getVestingScheduleWithCustomTemplates } from "../utils";

const EditCompany: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const id = router.routeInfo.pathname.split("/")[2];
  const { data } = useDashboard();
  const employers = data?.data.employers || [];
  const employer = employers.find(
    (employer) => (employer.id || 0).toString() === id
  );
  const { clearForm, form, setFieldTouched, setFieldValue, errors, touched } =
    useAddOrEditCompanyForm();
  const { form: grantForm, clearForm: clearGrantForm, setFieldValue: setGrantFieldValue } =
    useAddOrEditGrantForm();
  const { mutate: updateEmployer } = useUpdateEmployer();
  const { mutate: updateGrant } = useUpdateGrant();
  const fields = ["currency", "latestSharePrice", "name"];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }
  const customVestingSchedules = employer?.customTemplates || [];
  useEffect(() => {
    const grantInfo = JSON.parse(localStorage.getItem("grantDetails") || "{}");
    if (JSON.stringify(grantInfo) !== "{}") {
      setGrantFieldValue("noOfOptions", grantInfo.noOfOptions);
      setGrantFieldValue("strikePrice", grantInfo.strikePrice);
      setGrantFieldValue("dateOfGrant", grantInfo.grantDate);
      setGrantFieldValue("vestingScheduleName", grantInfo.vestingScheduleName);
      setGrantFieldValue("vestingSchedule", getVestingScheduleWithCustomTemplates(grantInfo.vestingScheduleName, customVestingSchedules))
      setGrantFieldValue("employerId", grantInfo.employerId);
      setGrantFieldValue("grantState", grantInfo.grantState);
      setGrantFieldValue("name", grantInfo.name);
      setGrantFieldValue("id", grantInfo.id);
      localStorage.removeItem("grantDetails");
    }
  }, [])
  useEffect(() => {
    setFieldValue("currency", employer?.currency);
    if (grantForm.grantState && grantForm.grantState === "Accepted") {
      setFieldValue("latestSharePrice", employer?.latestSharePrice);
      setFieldValue("name", employer?.name);
    }
  }, [employer, setFieldValue, grantForm]);
  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }
  function handleSubmit() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    if (grantForm.id && grantForm.id !== 0) {
      const grant: GrantOnboarding = {
        id: grantForm.id || 0,
        name: grantForm.name,
        noOfOptions: grantForm.noOfOptions,
        strikePrice: grantForm.strikePrice,
        exercisedOptions: grantForm.exercisedOptions,
        vestedOptions: grantForm.vestedOptions,
        lastDateToExercise: grantForm.lastDateToExercise,
        vestingScheduleName: grantForm.vestingScheduleName || "",
        vestingSchedule: getVestingScheduleWithCustomTemplates(grantForm.vestingScheduleName, []),
        grantDate: grantForm.grantDate,
        grantState: "Accepted",
        employerId: parseInt(id, 10),
        employerName: form.name,
        employerState: form.state,
        currency: form.currency,
        latestSharePrice: form.latestSharePrice,
      };
      updateGrant(grant, {
        onSuccess: () => {
          router.push("/tabs/grants");
          clearGrantForm(grantForm);
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
    else {
      const data: Employer = {
        id: parseInt(id, 10),
        latestSharePrice: form.latestSharePrice,
        currency: form.currency,
        name: form.name,
        state: form.state,
      };
      updateEmployer(data, {
        onSuccess: () => {
          router.push("/tabs/home");
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-7" size={20} onClick={() => router.goBack()} />
            )}
            {grantForm?.grantState && grantForm?.grantState === "Accepted" ?
              <p className="text-primary text-lg font-semibold">
                Edit Company Details
              </p> : <p className="text-primary text-lg font-semibold">
                Company Details
              </p>}
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <VStack className="mt-7 w-full h-full gap-3 px-3">
            <Label className="w-full font-medium">Company Name</Label>
            <Input
              {...getFieldProps("name", useAddOrEditCompanyForm())}
              type="text"
            />
            <Error
              visible={!!(touched.name && errors.name)}
              text={errors?.name}
            />
            <Label className="w-full mt-5 font-medium">Price Per Share</Label>
            <p className="text-grey text-start w-full text-xs font-light">
              A share price is the price of a single share of a number of
              saleable equity shares of a company.
            </p>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) => setFieldValue("currency", e.target.value)}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue("latestSharePrice", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("latestSharePrice");
                }}
                value={form.latestSharePrice}
                type="number"
              />
            </HStack>
            <Error
              visible={!!(touched.latestSharePrice && errors.latestSharePrice)}
              text={errors?.latestSharePrice}
            />
            <HStack className="mt-72 items-center justify-center">
              <ButtonPrimary className="px-12" onClick={handleSubmit}>
                Submit
              </ButtonPrimary>
            </HStack>
            <HStack className="items-center justify-center mt-8">
              <button className="text-primary" onClick={handleSubmit}>
                Skip
              </button>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditCompany;
