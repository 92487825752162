import { IonImg } from "@ionic/react";
import { FunctionComponent } from "react";
import BigBasketIcon from "../assets/bb.svg";
import { HStack } from "../components/utils";

const Banner1: FunctionComponent = () => {
  return (
    <HStack className="items-center max-w-md gap-1 px-2 py-4 bg-secondary rounded-b-xl mx-auto ">
      <IonImg className="w-8 h-8" src={BigBasketIcon} />
      <h6 className="px-1 py-1 font-normal text-white">
        Big Basket announces liquidity to its employees worth millions.
      </h6>
    </HStack>
  );
};

export default Banner1;
