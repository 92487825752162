/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Center, HStack, Label, VStack } from "../components/utils";
import { useEffect, useState } from "react";
import { useDashboard } from "../queries/dashboard";
import { ChevronLeft } from "akar-icons";
import { MenuItem, Select } from "@mui/material";
import { useExerciseForm } from "../store/useExerciseForm";
import { getTaxPercentageNumber, getTaxPercentage } from "../utils";
import { EmployerState } from "../types/onBoarding";

const CalculateTax: React.FC = () => {
  const { setFieldValue, form } = useExerciseForm();
  const [taxableIncome, setTaxableIncome] = useState(0);
  const router = useIonRouter();
  const { data } = useDashboard();
  const employers = data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [];
  const employer = employers.find(e => e.id === form.employerId);

  useEffect(() => {
    let taxableIncome = 0;
    form.exerciseEvents.forEach(event => {
      taxableIncome += (event.noOfOptions * form.fairMarketValue)
    })
    taxableIncome = taxableIncome - form.exerciseCost;
    setTaxableIncome(taxableIncome);
  }, []);

  useEffect(() => {
    if (form.taxSlab !== "") {
      setFieldValue("taxToBePaid", taxableIncome * (getTaxPercentageNumber(form.taxSlab) || 0) / 100)
    }
  }, [form.taxSlab])

  const taxSlabs = ["0 - 2.5L", "2.5L - 5L", "5L - 7.5L", "7.5L - 10L", "10L - 12.5L", "12.5L - 15L", "Above 15L"];
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Calculate Tax</p>
            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <VStack className="p-2">
            <HStack className="items-center gap-4">
              <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
                {employer?.name.charAt(0)}
              </Center>
              <h6 className="text-lg font-semibold text-black underline">Exercise 1</h6>
            </HStack>
            <h6 className="text-grey text-xs font-medium">Exercise refers to purchasing the shares of the company by paying the exercise price.</h6>
          </VStack>
          <VStack className="px-4">
            <Label className="w-full mt-5 font-medium">Tax Slab</Label>
            <h6 className="text-grey text-xs">Select your salary bracket + {employer?.currency}{taxableIncome}</h6>
            <Select
              value={form.taxSlab}
              className="border-secondary hover:outline-none focus:outline-none active:outline-none w-full h-12 mt-1 border rounded-lg"
              onChange={(e) => {
                setFieldValue(
                  "taxSlab",
                  e.target.value
                );
              }
              }
            >
              {taxSlabs.map((taxSlabs, index) => (
                <MenuItem
                  key={index}
                  value={taxSlabs}
                >
                  <HStack className=" items-center justify-around w-full">
                    <h6>{taxSlabs}</h6>
                    <h6>{getTaxPercentage(taxSlabs)}</h6>
                  </HStack>
                </MenuItem>
              ))}
            </Select>
          </VStack>
          {form.taxSlab !== "" &&
            <>
              <Center className="text-dark mt-7 font-medium">Tax to be Paid</Center>
              <HStack className="text-secondary items-center justify-center mt-1">
                <span className="font-sans text-xl">
                  {employer?.currency}
                </span>
                <span className="text-2xl">
                  {form.taxToBePaid}
                </span>
              </HStack>
              <h6 className="text-grey px-4 text-xs font-medium">This is only an estimate of tax based on the salary slab. Final tax may be different and will be intimated by your company.</h6>

              <Center className="text-dark mt-16 font-medium">Cost Incurred to Exercise</Center>
              <HStack className="text-secondary items-center justify-center mt-1">
                <span className=" font-sans text-xl">
                  {employer?.currency}
                </span>
                <span className="text-2xl">
                  {(form.exerciseCost + form.taxToBePaid).toFixed(2)}
                </span>
              </HStack>
              <Center className="text-grey text-xs font-medium">Cost of Exercise + Tax</Center>

              <Center className=" mt-12">
                <button
                  className="text-primary h-9 border-primary focus:opacity-90 whitespace-nowrap p-2 text-sm font-medium bg-white border border-solid rounded-lg cursor-pointer"
                  onClick={() => router.push("/confirmEvaluateExerciseDetails")}
                >
                  See Details
                </button>
              </Center>
            </>
          }
        </div>
      </IonContent>
    </IonPage >
  );
};

export default CalculateTax;
