/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Box, ButtonPrimary, ButtonPrimary1, Center, HStack, VStack } from "../components/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import Svg from "../assets/noRejectedGrants.svg";
import { useDashboard } from "../queries/dashboard";
import { format } from "date-fns";
import { ExerciseDetails } from "../store/useExerciseForm";
import _ from "lodash";
import { Employer, Grant, GrantEventType } from "../types/onBoarding";
import { Icon } from "@iconify/react";
import { CashOutDetails } from "../store/useEvaluateCashOutForm";
import { getEmployerIdFromGrantId, getExerciseCost, getTaxPercentageNumber, getTaxToBePaid, getTotalCash } from "../utils";

const EvaluateTab: React.FC = () => {
  const router = useIonRouter();
  const filterMenu = ["All", "Cash Out", "Exercise", "Offer"];
  const [filter, setFilter] = useState("All");
  const { data } = useDashboard();
  let employers = useMemo(() => data?.data.employers || [], [data]);
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const yetToAcceptOffers = grants.filter(grant => grant.grantState === "Offered");
  const yetToAcceptOffersWithAmount = grants.filter(grant => grant.grantState === "OfferedInAmount");
  const evaluateExerciseEvents = grants.map(grant => grant.events).flat().filter(e => e.type === GrantEventType.EvaluateExercise);
  const evaluateCashOutEvents = grants.map(grant => grant.events).flat().filter(e => e.type === GrantEventType.EvaluateCashOut);
  const grantIdMap = new Map<number, Grant>(grants.map(g => ([g.id || -1, g])));
  const evaluateExerciseEventNames = _.uniqBy(evaluateExerciseEvents, e => e.name).map(e => e.name || "").filter(e => e);
  const evaluateCashOutEventNames = _.uniqBy(evaluateCashOutEvents, e => e.name).map(e => e.name || "").filter(e => e);
  const evaluateExerciseDetails: ExerciseDetails[] = [];
  const evaluateCashOutDetails: CashOutDetails[] = [];
  const groupedEventEvaluateExercise = _.groupBy(evaluateExerciseEvents, "name");
  const groupedEventEvaluateCashout = _.groupBy(evaluateCashOutEvents, "name");
  const fabRef = useRef<HTMLIonFabElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const fab = fabRef.current;
      if (fab && !fab.contains(event.target as Node)) {
        if (isMenuOpen) {
          setIsMenuOpen(!isMenuOpen);
        }
        fab.close();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [fabRef, isMenuOpen]);
  const section = "Offer Related Queries";
  for (const name of evaluateExerciseEventNames) {
    const groupedEvents = _.get(groupedEventEvaluateExercise, name);
    evaluateExerciseDetails.push({
      dateOfExercise: groupedEvents[0]?.date,
      employerId: getEmployerIdFromGrantId(groupedEvents[0].grantId, grantIdMap) || 0,
      exerciseCost: getExerciseCost(groupedEvents, grantIdMap),
      exercisedOptions: _.sumBy(groupedEvents, (event => event.noOfOptions)),
      exerciseEvents: groupedEvents,
      fairMarketValue: groupedEvents[0]?.fairMarketValue || 0,
      taxSlab: groupedEvents[0]?.taxSlab || "",
      taxToBePaid: getTaxToBePaid(groupedEvents, grantIdMap),
      name: groupedEvents[0]?.name || "",
    })
  }
  for (const name of evaluateCashOutEventNames) {
    const groupedEvents = _.get(groupedEventEvaluateCashout, name);
    evaluateCashOutDetails.push({
      dateOfExercise: groupedEvents[0]?.date,
      employerId: getEmployerIdFromGrantId(groupedEvents[0].grantId, grantIdMap) || 0,
      totalCash: getTotalCash(groupedEvents, grantIdMap),
      exercisedOptions: _.sumBy(groupedEvents, (event => event.noOfOptions)),
      exerciseEvents: groupedEvents,
      fairMarketValue: groupedEvents[0]?.fairMarketValue || 0,
      taxSlab: groupedEvents[0]?.taxSlab || "",
      taxToBePaid: getTotalCash(groupedEvents, grantIdMap) * (getTaxPercentageNumber(groupedEvents[0]?.taxSlab || "") || 0) / 100,
      name: groupedEvents[0]?.name || "",
    })
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className={`items-center justify-center h-16 max-w-md mx-auto ${isMenuOpen ? "  opacity-10 bg-white" : ""}`}>
            <p className="text-primary text-lg font-semibold">Evaluate</p>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={`max-w-md mx-auto mb-5 ${isMenuOpen ? "  opacity-10 bg-white" : ""}`}>
          {yetToAcceptOffersWithAmount.length === 0 && yetToAcceptOffers?.length === 0 && evaluateExerciseDetails.length === 0 && evaluateCashOutDetails.length === 0 ? (
            <VStack>
              <HStack className="items-center justify-center mt-64">
                <ButtonPrimary
                  className="px-6 text-sm"
                  onClick={() => router.push("/evaluateDetails")}
                >
                  Evaluate an offer
                </ButtonPrimary>
              </HStack>
              <Box className="text-grey w-full px-4 py-4 text-xs font-normal text-center">
                Evaluate any ESOP offers you have been granted here and track the growth of your funds
              </Box>
              <HStack className="items-center justify-between gap-2 px-6 mt-2">
                <ButtonPrimary1
                  className=" px-6 text-xs"
                  onClick={() => {
                    router.push("/evaluateExercise")
                  }}
                >
                  Evaluate Exercise
                </ButtonPrimary1>
                <ButtonPrimary1
                  className=" px-6 text-xs"
                  onClick={() => {
                    router.push("/evaluateCashOut");
                  }}
                >
                  Evaluate Cash Out
                </ButtonPrimary1>
              </HStack>
              <h4 className=" w-full px-4 mt-5 text-xs font-normal">
                <span className="text-grey">
                  What are some helpful questions to ask my employer if I want to understand my ESOP offer better?
                </span>
                <span className="text-primary underline" onClick={() => router.push(`/faq/${section}`)}>
                  Click here to gain a better understanding
                </span>
              </h4>
            </VStack>
          ) : (
            <Box>
              <Box className="text-grey text-start w-full px-2 mt-2 text-xs font-normal">
                You can evaluate any ESOP offers you are granted here and
                see your money growth.
              </Box>
              <HStack className="justify-end w-full">
                <IonSelect
                  aria-label="fruit"
                  className="h-9 w-40 mt-4 mr-4 text-center border rounded-md"
                  placeholder=""
                  mode="ios"
                  interface="popover"
                  autoSave="yes"
                  value={filter}
                  onIonChange={(e) => setFilter(e.target.value)}
                >
                  {filterMenu.map((name) => (
                    <IonSelectOption key={name} value={name} className="text-xs">
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </HStack>
              {filter === "Offer" && yetToAcceptOffers.length > 0 && yetToAcceptOffers?.map((offer) => (
                <Offers offer={offer} employers={employers} key={offer.id} />))
              }
              {filter === "Offer" && yetToAcceptOffersWithAmount.length > 0 && yetToAcceptOffersWithAmount?.map((offer) => (
                <OffersWithAmount offer={offer} employers={employers} key={offer.id} />))
              }
              {filter === "Offer" && yetToAcceptOffers.length === 0 && yetToAcceptOffersWithAmount.length === 0 &&
                <VStack>
                  <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                    No Evaluated Offers found
                  </Box>
                  <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                </VStack>}

              {filter === "Exercise" && evaluateExerciseDetails.length > 0 && (evaluateExerciseDetails.map(exercise =>
                <ExerciseCard employers={employers} exercise={exercise} key={exercise.name} />
              ))}
              {filter === "Exercise" && evaluateExerciseDetails.length === 0 &&
                <VStack>
                  <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                    No Evaluated Exercise found
                  </Box>
                  <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                </VStack>}
              {filter === "Cash Out" && evaluateCashOutDetails.length > 0 && evaluateCashOutDetails.map(exercise =>
                <CashOutCard employers={employers} exercise={exercise} key={exercise.name} />)}
              {filter === "Cash Out" && evaluateCashOutDetails.length === 0 &&
                <VStack>
                  <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                    No Evaluated Cash Out found
                  </Box>
                  <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                </VStack>
              }
              {filter === "All" && (
                yetToAcceptOffersWithAmount.length > 0 || yetToAcceptOffers.length > 0 || evaluateExerciseDetails.length > 0 || evaluateCashOutDetails.length > 0 ?
                  <>
                    {yetToAcceptOffers?.map((offer) => (
                      <Offers offer={offer} employers={employers} key={offer.id} />))}
                    {yetToAcceptOffersWithAmount.map((offer) => (
                      <OffersWithAmount offer={offer} employers={employers} key={offer.id} />
                    ))}
                    {evaluateExerciseDetails.map(exercise =>
                      <ExerciseCard employers={employers} exercise={exercise} key={exercise.name} />)}
                    {evaluateCashOutDetails.map(exercise =>
                      <CashOutCard employers={employers} exercise={exercise} key={exercise.name} />)}
                  </> :
                  <VStack>
                    <Box className=" z-50 px-2 mx-auto mt-24 -mb-12 text-lg font-medium">
                      Nothing found
                    </Box>
                    <IonImg className=" w-[414px] h-[368px]" src={Svg} />
                  </VStack>
              )}
            </Box>
          )}
        </div>
      </IonContent>
      <IonFab horizontal="end" vertical="bottom" slot="fixed" ref={fabRef}>
        <IonFabButton onClick={() => setIsMenuOpen(!isMenuOpen)} >
          <Icon
            icon="material-symbols:add"
            className="w-[56px] h-[36px]"
          />
        </IonFabButton>
        <IonFabList side="top">
          <VStack className="whitespace-nowrap right-44 relative items-end bg-white border-2 divide-y-2 rounded-lg shadow-md">
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/evaluateDetails")
                }}
              >
                Evaluate an offer
              </button>
            </HStack>
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/evaluateExercise");
                }}
              >
                Evaluate an exercise
              </button>
            </HStack>
            <HStack className="px-7 text-dark py-4 font-medium">
              <button
                className="focus:text-primary"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/evaluateCashOut");
                }}
              >
                Evaluate a cash out
              </button>
            </HStack>
          </VStack>
        </IonFabList>
      </IonFab>
    </IonPage>
  );
};

export default EvaluateTab;

function ExerciseCard({ exercise, employers }: { exercise: ExerciseDetails, employers: Employer[] }) {
  const router = useIonRouter();
  const employer = employers.find(e => e.id === exercise.employerId);
  return (
    <Box className="max-w-md mx-4 mt-8 border rounded-lg" key={exercise.name} onClick={() => {
      router.push(`/seeEvaluateExerciseDetails/${exercise.name}`);
    }}>
      <Center className="bg-[#0AC3B9] text-white rounded-t-lg">{exercise.name}</Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" text-dark px-2 text-base font-normal">
            {employer?.name}
          </h6>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated: {format(new Date(exercise?.dateOfExercise || new Date()), "dd MMMM yyyy")}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">No. of Options</p>
          <p className="text-dark text-xl font-medium">
            {exercise.exercisedOptions}
          </p>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Fair Market Value</p>
          <div className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{exercise.fairMarketValue}</span>
          </div>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-10" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeEvaluateExerciseDetails/${exercise.name}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>

  );
}

function CashOutCard({ exercise, employers }: { exercise: CashOutDetails, employers: Employer[] }) {
  const router = useIonRouter();
  const employer = employers.find(e => e.id === exercise.employerId);
  return (
    <Box className="max-w-md mx-4 mt-8 border rounded-lg" key={exercise.name} onClick={() => {
      router.push(`/seeEvaluateCashOutDetails/${exercise.name}`);
    }}>
      <Center className="bg-[#0FA958] text-white rounded-t-lg">{exercise.name}</Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
          {employer?.name?.charAt(0)}
        </Center>
        <VStack>
          <h6 className=" text-dark px-2 text-base font-normal">
            {employer?.name}
          </h6>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated: {format(new Date(exercise?.dateOfExercise || new Date()), "dd MMMM yyyy")}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">No. of Options</p>
          <p className="text-dark text-xl font-medium">
            {exercise.exercisedOptions}
          </p>
        </HStack>
        <div className=" bg-grey w-64 h-[1px] rounded-full" />
        <HStack className="justify-between w-full px-2 font-normal">
          <p className="text-grey text-sm">Fair Market Value</p>
          <p className="text-dark font-medium">
            <span className="font-sans">{employer?.currency}</span>
            <span className="text-xl">{exercise.fairMarketValue}</span>
          </p>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-10" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeEvaluateCashOutDetails/${exercise.name}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>

  );
}

function Offers({ offer, employers }: { offer: Grant, employers: Employer[] }) {
  const router = useIonRouter();
  const employer = employers.find(e => e.id === offer.employerId);
  return (
    <Box className="max-w-md mx-4 mt-8 border rounded-lg" key={offer.id} onClick={() => {
      router.push(`/seeEvaluateDetails/${offer.id}`);
    }}>
      <Center className="bg-secondary text-white rounded-t-lg">{offer.name}</Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <VStack>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated: {format(new Date(offer.grantDate || new Date()), "dd MMMM yyyy")}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="items-center justify-between w-full px-2 font-normal">
          <p className="text-dark font-medium">No. of Options</p>
          <p className="text-dark text-3xl font-medium">
            {offer.noOfOptions}
          </p>
        </HStack>
        <div className=" bg-grey w-64 h-[0.5px] rounded-full" />
        <HStack className="items-center justify-between w-full px-2 font-normal">
          <p className="text-dark font-medium">Strike Price</p>
          <VStack >
            <HStack className="items-center justify-end">
              <span className="font-sans text-xl">{employer?.currency}</span>
              <span className="text-3xl">{offer.strikePrice}</span>
            </HStack>
            <span className="text-grey text-xs">(Per Option)</span>
          </VStack>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-5" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeEvaluateDetails/${offer.id}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>

  );
}

function OffersWithAmount({ offer, employers }: { offer: Grant, employers: Employer[] }) {
  const router = useIonRouter();
  const employer = employers.find(e => e.id === offer.employerId);
  return (
    <Box className="max-w-md mx-4 mt-8 border rounded-lg" key={offer.id} onClick={() => {
      router.push(`/seeEvaluateDetails/${offer.id}`);
    }}>
      <Center className="bg-secondary text-white rounded-t-lg">{offer.name}</Center>
      <HStack className=" items-center justify-center gap-1 px-2 py-4">
        <VStack>
          <h6 className=" text-grey px-2 text-xs font-normal">
            Dated: {format(new Date(offer.grantDate || new Date()), "dd MMMM yyyy")}
          </h6>
        </VStack>
      </HStack>
      <VStack className="gap-2">
        <HStack className="items-center justify-between w-full px-2 font-normal">
          <p className="text-dark font-medium">Compensation Offered</p>
          <VStack >
            <HStack className="items-center justify-end">
              <span className="font-sans text-xl">{employer?.currency}</span>
              <p className="text-dark text-3xl font-medium">
                {offer.noOfOptions}
              </p>
            </HStack>
          </VStack>
        </HStack>
        <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-5" />
      </VStack>
      <HStack className="items-center justify-center gap-1 mt-3 mb-3">
        <h6
          className="text-dark text-xs font-medium underline"
          onClick={() => {
            router.push(`/seeEvaluateDetails/${offer.id}`);
          }}
        >
          See Details
        </h6>
      </HStack>
    </Box>

  );
}