/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { ButtonPrimary, Center, HStack, Input, Label, VStack, Error } from "../components/utils";
import { useEffect, useState } from "react";
import { useDashboard } from "../queries/dashboard";
import { ChevronLeft, ChevronDown } from "akar-icons";
import _ from "lodash";
import { useExerciseForm } from "../store/useExerciseForm";
import { format } from "date-fns";
import { MenuItem, Select } from "@mui/material";
import { useCompany } from "../store/useCompany";
import { Icon } from "@iconify/react";
import { getGrantEventsOnFIFOLogic, getExerciseName, getOpacity, getEmployerIdFromName } from "../utils";
import { EmployerState, GrantEventType } from "../types/onBoarding";

const EvaluateExercise: React.FC = () => {
  const router = useIonRouter();
  const currencies = ["₹", "$", "¥", "€"];
  const { data } = useDashboard();
  const employers = data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [];
  const { setFieldValue, form, errors, touched, setFieldTouched } = useExerciseForm();
  const { state: company, setState: setCompany } = useCompany();
  const employer = employers.find(e => e.name === company);
  const grants = employer?.grants || [];
  // const grantIdMap = new Map<number, Grant>(grants.map(g => ([g.id || -1, g])));

  const [editName, setEditName] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  // const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  // const [grantsOfEvents, setGrantsOfEvents] = useState<Grant[]>([]);
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
    else {
      setFieldValue("employerId", getEmployerIdFromName(company, employers))
    }
  }, [company]);


  useEffect(() => {
    if (form.exercisedOptions > 0 && grants.length > 0) {
      const grantEvents = getGrantEventsOnFIFOLogic(form.exercisedOptions, grants, GrantEventType.EvaluateExercise);
      setIsHidden(false);
      let exerciseCost = 0;
      for (const grantEvent of grantEvents) {
        for (const grant of grants) {
          if (grantEvent.grantId === grant.id) {
            exerciseCost += grant.strikePrice * grantEvent.noOfOptions
          }
        }
      }
      // setGrantsOfEvents(getGrantsFromGrantEvents(grantEvents, grantIdMap))
      setFieldValue("exerciseCost", exerciseCost);
      setFieldValue("exerciseEvents", grantEvents);
    }
  }, [form.exercisedOptions, form.fairMarketValue])

  useEffect(() => {
    if (employer)
      setFieldValue("name", getExerciseName(employer, GrantEventType.EvaluateExercise));
  }, [employer])




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Evaluate Exercise</p>
            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <VStack className="p-2">
            <VStack className="gap-3">
              <h1 className="text-dark font-semibold">Select Company</h1>
              <Select
                className=" h-14 items-center bg-white border rounded-lg shadow-md"
                IconComponent={() => (<ChevronDown
                  className="mx-4 text-black"
                  strokeWidth={3}
                  width={26}
                  height={14}
                />)}
                value={form.employerId === 0 ? "addCompany" : employers?.find((e) => e.id === form.employerId)?.name}
                placeholder={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              >
                {employers.map((employer) => (
                  <MenuItem
                    key={employer?.id}
                    value={employer.name}
                  >
                    <HStack className="items-center w-full gap-4">
                      <Center className="w-[30px] h-[30px] bg-gray-100 rounded-full">
                        {employer.name?.charAt(0)}
                      </Center>
                      {employer.name}
                    </HStack>
                  </MenuItem>
                ))}
              </Select>
            </VStack>
            <span className="text-grey text-[11px] px-4">Please enter the company from which you want to exercise</span>
            <HStack className="items-center gap-4 mt-4">
              <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
                {employer?.name.charAt(0)}
              </Center>
              {!editName ? (<h6 className="text-lg font-semibold text-black underline" onClick={() => { setEditName(!editName) }}>
                {form.name}
              </h6>) : (
                <Input className="text-lg font-semibold text-black underline"
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={() => { setEditName(!editName) }}
                  value={form.name}
                  type="text"
                />)}
            </HStack>
            <h6 className="text-grey text-xs font-medium">Exercise refers to purchasing the shares of the company by paying the exercise price.</h6>
          </VStack>
          <VStack className="px-4 mt-4 border rounded-lg">
            <Label className="mt-5">No. Of Options</Label>
            <h6 className="text-grey mt-1 text-xs font-medium">FIFO Logic, you can edit the no. of options grant wise</h6>
            <Input className="mt-4"
              onChange={(e) => {
                setFieldValue(
                  "exercisedOptions",
                  parseInt(e.target.value, 10)
                );
              }}
              onBlur={(e) => {
                setFieldTouched("exercisedOptions");
              }}
              value={form.exercisedOptions}
              type="number"
            />
            <Error
              visible={
                !!(touched?.exercisedOptions && errors?.exercisedOptions)
              }
              text={errors?.exercisedOptions}
            />
            <HStack className="items-center w-full mt-6 mb-6">
              {!isHidden && form.exerciseEvents.map((event, index) => {
                const total = _.sumBy(form.exerciseEvents, "noOfOptions");
                const width = `${(event.noOfOptions / total) * 100}%`;
                const color = `rgba(0, 44, 164, ${getOpacity(index)})`;
                return (
                  <VStack
                    key={index}
                    className="max-w-md text-center text-white rounded-md"
                    style={{ backgroundColor: color, width }}>
                    <p>{event.noOfOptions}</p>
                  </VStack>
                )
              })}
              {!isHidden && <Icon
                icon="clarity:edit-line"
                className="focus:text-primary ml-4"
                // onClick={() => setIsPopOverOpen(true)}
                width="16"
                height="16" />}
            </HStack>
          </VStack>
          <VStack className="px-4">
            <Label className=" w-full mt-3 font-medium">Date of Exercise</Label>
            <Input
              type="date"
              className="mt-2"
              onChange={(e) => {
                setFieldValue("dateOfExercise", new Date(e.target.value));
              }}
              onBlur={(e) => {
                setFieldTouched("dateOfExercise");
              }}
              value={format(new Date(form?.dateOfExercise || new Date()), "yyyy-MM-dd")}
            />
            <Label className=" w-full mt-4 font-medium">Fair Market Value</Label>
            <h6 className="text-grey mt-1 text-xs">PPS of the company as per the date of exercise</h6>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 mt-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={employer?.currency}
                disabled={true}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                type="number"
                onChange={(e) => {
                  setFieldValue("fairMarketValue", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("fairMarketValue");
                }}
                value={form.fairMarketValue}
              />
            </HStack>
          </VStack>
          <Center className="text-dark mt-4 font-medium">Cost of Exercise</Center>
          <HStack className="text-secondary items-center justify-center">
            <span>
              {employer?.currency}
            </span>
            <span className="text-2xl">
              {(form.exerciseCost).toFixed(2)}
            </span>
          </HStack>
          <Center className="text-grey text-xs font-medium">No. of Options * Strike Price</Center>
          <Center className="mt-3">
            <ButtonPrimary
              className="px-6"
              onClick={() => { router.push("/calculateTax") }}
            >
              Calculate Tax
            </ButtonPrimary>
          </Center>
        </div>
      </IonContent>
      {/* <IonPopover isOpen={isPopOverOpen} mode="md"
        onDidDismiss={e => setIsPopOverOpen(false)}
        trigger="click-trigger" triggerAction="click"
      >
        <IonContent class="ion-padding">
          <VStack className="items-center justify-center w-full">
            <HStack className="text-grey justify-center text-lg">Grants</HStack>
            {grantsOfEvents.map((grant, index) => (
              <>
                Grant {index + 1} (Options:{grant.noOfOptions}; Strike Price: {grant.strikePrice})
              </>
            ))}
          </VStack>
        </IonContent>
      </IonPopover> */}
    </IonPage >
  );
};

export default EvaluateExercise;

