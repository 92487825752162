import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence, MotionValue } from "framer-motion";
import { Box, HStack, ProfilePageInput, VStack } from "../components/utils";
import { Icon } from "@iconify/react";
import { ChevronDown, ChevronUp } from "akar-icons";
import { Employer } from "../types/onBoarding";
import { useProfileEditForm } from "../store/useProfileEditForm";
import { useDeleteEmployer } from "../queries/dashboard";
import { IonSelect, IonSelectOption, useIonAlert } from "@ionic/react";

export type AccordianProps = {
  i: number;
  expanded: Set<number>;
  className?: string;
  header?:
  | React.ReactNode
  | MotionValue<number>
  | MotionValue<string>
  | undefined;
};

export const Accordion = (props: React.PropsWithChildren<AccordianProps>) => {
  const { i, expanded, header, className } = props;
  const isOpen = expanded.has(i);
  const _header = header || <></>;
  return (
    <>
      <motion.header
        initial={false}
        className={className}
        // animate={{ backgroundColor: isOpen ? "red" : "green" }}
        children={_header}
      />
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
              transition={{ duration: 0.8 }}
              className="content-placeholder"
            >
              {props.children}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export function getOldestDateOfGrant(employer?: Employer) {
  let oldestDateOfGrant = new Date();
  const grants = employer?.grants || [];
  grants.forEach((grant) => {
    oldestDateOfGrant =
      oldestDateOfGrant < grant.grantDate ? oldestDateOfGrant : grant.grantDate;
  });
  return oldestDateOfGrant;
}

export const AccordianEmployer = ({
  isEdit,
  setIsEdit,
}: {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [expanded, setExpanded] = useState<Set<number>>(new Set<number>());
  const { form } = useProfileEditForm();
  const employers = form.employers;
  return (
    <>
      {employers &&
        employers.map((employer, index) => (
          <Accordion
            i={employer.id || 0}
            key={employer.id}
            expanded={expanded}
            className="border-rose-100 text-gray-dark bg-rose-50 mt-2 text-lg font-medium border-2 rounded-lg shadow-md"
            header={
              <EmployerHeader
                id={employer.id || 0}
                name={employer.name}
                isOpen={expanded.has(employer.id || -1)}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                setExpanded={setExpanded}
              />
            }
          >
            <EmployerContent
              index={index}
              isEdit={isEdit}
              employers={employers}
            />
          </Accordion>
        ))}
    </>
  );
};

function EmployerHeader({
  id,
  name,
  isOpen,
  isEdit,
  setIsEdit,
  setExpanded,
}: {
  id: number;
  name: string;
  isOpen: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setExpanded: React.Dispatch<React.SetStateAction<Set<number>>>;
}) {
  const { mutate: deleteEmployer } = useDeleteEmployer();
  function handleDelete() {
    deleteEmployer(id, {
      onSuccess: () => {
        setIsEdit(!isEdit);
      },
      onError: (e) => console.error(e),
    });
  }
  const [presentAlert] = useIonAlert();
  return (
    <HStack className=" items-center justify-between w-full p-3 text-black">
      {isEdit ? (
        <Icon
          icon="mdi:delete-outline"
          className="text-primary"
          width="16"
          height="22"
          onClick={() =>
            presentAlert({
              cssClass: "p-4",
              header: "Are you sure you want to delete this company?",
              subHeader: "The grants in the company will also be deleted",
              buttons: [
                {
                  text: "Cancel",
                  handler: () => { },
                },
                {
                  text: "Delete",
                  handler: handleDelete,
                },
              ],
            })
          }
        />
      ) : (
        <div className="w-4"></div>
      )}
      <h6
        className="text-base font-semibold"
        onClick={() =>
          setExpanded((prev) => {
            const nextState = new Set(prev);
            if (nextState.has(id)) {
              nextState.delete(id);
            } else {
              nextState.add(id);
            }
            return nextState;
          })
        }
      >
        {name}{" "}
      </h6>
      {!isOpen ? (
        <ChevronDown
          strokeWidth={2}
          size={12}
          onClick={() =>
            setExpanded((prev) => {
              const nextState = new Set(prev);
              if (nextState.has(id)) {
                nextState.delete(id);
              } else {
                nextState.add(id);
              }
              return nextState;
            })
          }
        />
      ) : (
        <ChevronUp
          strokeWidth={2}
          size={12}
          onClick={() =>
            setExpanded((prev) => {
              const nextState = new Set(prev);
              if (nextState.has(id)) {
                nextState.delete(id);
              } else {
                nextState.add(id);
              }
              return nextState;
            })
          }
        />
      )}
    </HStack>
  );
}

function EmployerContent({
  index,
  isEdit,
  employers,
}: {
  index: number;
  isEdit: boolean;
  employers: Employer[];
}) {
  const { setFieldTouched, setFieldValue } = useProfileEditForm();
  const currencies = ["₹", "$", "¥", "€"];
  return (
    <div className="py-4">
      <HStack className=" justify-center w-full text-sm font-semibold">
        Company Information
      </HStack>
      <Box className="m-2 mt-4 border-2 rounded-lg">
        <VStack className="text-neutral-600 gap-12 p-5">
          <HStack className="justify-between w-full font-normal">
            <h6>Company Name</h6>
            {isEdit ? (
              <ProfilePageInput
                className="h-fit w-44"
                onChange={(e) => {
                  setFieldValue(`employers[${index}].name`, e.target.value);
                }}
                onBlur={(e) => {
                  setFieldTouched(`employers[${index}].name`);
                }}
                value={employers[index]?.name}
              />
            ) : (
              <h6 className="font-medium text-black">
                {employers[index]?.name}
              </h6>
            )}
          </HStack>
          <HStack className="justify-between w-full font-normal">
            <h6>Company Currency</h6>
            {isEdit ? (
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={employers[index].currency}
                onIonChange={(e) => setFieldValue(
                  `employers[${index}].currency`,
                  e.target.value
                )}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            ) : (
              <h6 className="font-medium text-black">
                <span className="font-sans">{employers[index].currency}</span>
              </h6>
            )}
          </HStack>
          <HStack className="justify-between w-full font-normal">
            <h6>Price Per Share</h6>
            {isEdit ? (
              <ProfilePageInput
                className="h-fit w-44"
                onChange={(e) => {
                  setFieldValue(
                    `employers[${index}].latestSharePrice`,
                    e.target.value
                  );
                }}
                onBlur={(e) => {
                  setFieldTouched(`employers[${index}].latestSharePrice`);
                }}
                value={employers[index]?.latestSharePrice}
              />
            ) : (
              <h6 className="font-medium text-black">
                {employers[index]?.latestSharePrice}
              </h6>
            )}
          </HStack>
        </VStack>
      </Box >
    </div >
  );
}
