import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonPopover,
  IonToolbar,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import { HStack, VStack } from "../components/utils";
import { ChevronLeft, MoreVerticalFill } from "akar-icons";
import EvaluateIcon from "../assets/evaluate.svg";
import { useCompany } from "../store/useCompany";
import { useMemo, useState } from "react";
import {
  getTotalVested,
  useDashboard,
  useDeleteGrant,
} from "../queries/dashboard";
import { format } from "date-fns";
import { compactCurrenyFormatter } from "../utils";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { VestingChartGrant } from "./VestingChart";
import { EmployerState, GrantEventType, Schedule } from "../types/onBoarding";

const SeeDetails: React.FC = () => {
  const { data } = useDashboard();
  const router = useIonRouter();
  const [present] = useIonToast();
  let employers = useMemo(
    () =>
      data?.data.employers.filter(
        (employer) => employer.state !== EmployerState.Offered
      ) || [],
    [data]
  );
  const { state: company } = useCompany();
  if (company && company !== "All") {
    employers = employers.filter((employer) => employer.name === company);
  }
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const id = router.routeInfo.pathname.split("/")[2];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const { mutate: deleteGrant } = useDeleteGrant();
  const employer = employers.find(
    (employer) => employer.id === grant?.employerId
  );
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  function getTotalPeriod(schedules: Schedule[]) {
    return _.sumBy(schedules, (schedule) => schedule.period);
  }
  const isExercised = !!grant?.events.filter(
    (event) => event?.type === GrantEventType.Exercise
  ).length;
  const isCustomVestingSchedule =
    _.sumBy(grant?.template?.schedules, (schedule) => schedule.percentage) ===
    0;
  function handleDelete() {
    deleteGrant(grant?.id || 0, {
      onSuccess: () => {
        setIsPopOverOpen(false);
        router.push("/tabs/grants");
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonFab horizontal="end" vertical="bottom">
        <IonFabButton>
          <IonIcon
            icon={EvaluateIcon}
            className="w-[28px] h-[28px]"
            onClick={() => {
              router.push(`/tabs/evaluate/moneyForecast/${grant?.id}`);
            }}
          />
        </IonFabButton>
      </IonFab>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Details</p>
            <button
              className="w-6"
              id="click-trigger"
              onClick={() => setIsPopOverOpen(true)}
            >
              <MoreVerticalFill strokeWidth={2} size={24} />
            </button>
            <IonPopover
              isOpen={isPopOverOpen}
              mode="md"
              onDidDismiss={(e) => setIsPopOverOpen(false)}
              trigger="click-trigger"
              triggerAction="click"
            >
              <IonContent class="ion-padding">
                <VStack className="items-center justify-center w-full gap-6 py-3 divide-y-0">
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="clarity:edit-line"
                      className="focus:text-primary"
                      width="12"
                      height="12"
                    ></Icon>
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={() => {
                        setIsPopOverOpen(false);
                        if (isExercised) {
                          present({
                            message:
                              "Can't Edit! You have exercised options in this grant",
                            duration: 1500,
                            position: "bottom",
                            color: "light",
                            mode: "ios",
                            animated: true,
                          });
                        } else {
                          setIsPopOverOpen(false);
                          router.push(`/editGrant/${id}`);
                        }
                      }}
                    >
                      Edit
                    </button>
                  </HStack>
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="mdi:delete-outline"
                      className="focus:text-primary"
                      width="12"
                      height="14"
                    />
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </HStack>
                </VStack>
              </IonContent>
            </IonPopover>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md px-5 mx-auto">
          <p className="mt-1 text-lg font-medium text-center underline">
            {employer?.name}
          </p>
          <HStack className="items-center justify-between py-2 mt-4 text-center border divide-x-2 rounded-lg">
            <VStack className="w-1/3 gap-1">
              <h6 className="text-dark text-3xl font-medium">
                {grant?.noOfOptions}
              </h6>
              <h6 className="text-grey text-xs">Total no. of options</h6>
            </VStack>
            <VStack className="w-1/3 gap-1">
              <h6 className="text-dark text-3xl font-medium">
                <span className="font-sans">{employer?.currency}</span>
                {grant?.strikePrice}
              </h6>
              <h6 className="text-grey text-xs">Strike price </h6>
              <h6 className="text-grey text-xs">(Per Option) </h6>
            </VStack>
            {employer?.latestSharePrice &&
              parseInt(employer?.latestSharePrice.toString(), 10) !== 0 ? (
              <VStack className="w-1/3 gap-1">
                <h6 className="text-dark text-3xl font-medium">
                  <span className="font-sans">{employer?.currency}</span>
                  {compactCurrenyFormatter.format(
                    Number(
                      (
                        getTotalVested(grant) *
                        (employer?.latestSharePrice - (grant?.strikePrice || 0))
                      ).toFixed(2)
                    )
                  )}
                </h6>
                <h6 className="text-grey text-xs">Current Value</h6>
              </VStack>
            ) : (
              <VStack className="w-1/3 gap-1">
                <h6 className="text-primary text-3xl font-medium">
                  <span className="font-sans">₹</span>XXXX
                </h6>
                <h6 className="text-grey text-xs">Current Value</h6>
                <h6
                  className="text-primary text-xs text-center underline"
                  onClick={() => {
                    router.push(`/editCompany/${employer?.id}`);
                  }}
                >
                  (Fill)
                </h6>
              </VStack>
            )}
          </HStack>
          <VStack className="gap-4 px-4">
            <HStack className="justify-between mt-4">
              <h6 className="text-dark font-medium">Grant Date:</h6>
              <h6 className="">
                {format(
                  new Date(grant?.grantDate || new Date()),
                  "dd MMM yyyy"
                )}
              </h6>
            </HStack>
            <HStack className="justify-between">
              <h6 className="text-dark font-medium">Vesting Start Date:</h6>
              <h6>
                {format(
                  new Date(grant?.grantDate || new Date()),
                  "dd MMM yyyy"
                )}
              </h6>
            </HStack>
            <HStack className="justify-between">
              <h6 className="text-dark font-medium">Vesting Period:</h6>
              <h6>{getTotalPeriod(grant?.template?.schedules || [])} months</h6>
            </HStack>
          </VStack>
          {isCustomVestingSchedule ? (
            <VStack className="mt-7 items-center gap-2 mb-10 border rounded-lg shadow-md">
              <h6 className=" mt-4 text-lg font-medium">Vesting Schedule</h6>
              <h6
                className=" text-primary mb-4 text-sm font-medium underline"
                onClick={() => router.push(`/editGrant/${grant?.id}`)}
              >
                Set a Custom Vesting Schedule
              </h6>
            </VStack>
          ) : (
            <VStack className="mt-9 items-center">
              <h6 className="text-dark mb-4 font-medium">Vesting Schedule</h6>
              <VestingChartGrant grant={grant} />
            </VStack>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SeeDetails;
