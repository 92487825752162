/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import AttachFile from "../assets/attachFile.svg";
import {
  ButtonPrimary,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useOnboardingForm as useEmployerInfoForm } from "../store/useOnboardingForm";
import { format } from "date-fns";
import { useDashboard } from "../queries/dashboard";
import { useCompany } from "../store/useCompany";
import { useEffect, useMemo } from "react";
import { usePersonalInfoForm } from "./ProfileInfo";
import { EmployerState } from "../types/onBoarding";

const vestingScheduleNames = [
  "1 Year Cliff, Monthly Vesting, 4 Years",
  "1 Year Cliff, Quarterly Vesting, 4 Years",
  "1 Year Cliff, Yearly Vesting, 4 Years",
  "Custom(Set as default, fill it out later)",
];
const currencies = ["₹", "$", "¥", "€"];

const GrantInformation: React.FC = () => {
  const router = useIonRouter();
  const { data } = useDashboard();
  const { state: company, setState: setCompany } = useCompany();
  const employers = useMemo(() => data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [data]);
  const { form: personalInfoForm } = usePersonalInfoForm();
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
  }, []);
  const fields = [
    "grant.noOfOptions",
    "currency",
    "grant.strikePrice",
    "grant.vestingScheduleName",
    "grant.dateOfGrant",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }

  function openNextPage() {
    touchAllFields();
    const errors = checkErrors();
    if (!errors) router.push("/companyInformation");
  }
  const { touched, errors, setFieldTouched, setFieldValue, form } =
    useEmployerInfoForm();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-7" size={20} onClick={() => router.goBack()} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md px-2 mx-auto">
          <h3 className="w-full mt-2 text-xl font-bold">
            Hey {personalInfoForm.name}!
          </h3>
          <h4 className="w-full mt-1 text-sm font-normal">
            Please enter your grant information so you can easily track
            everything
          </h4>
          <VStack className="w-full gap-3 mt-4">
            <Label className="font-medium">No. of Options</Label>
            <Input
              onChange={(e) => {
                setFieldValue(
                  "grant.noOfOptions",
                  parseInt(e.target.value, 10)
                );
              }}
              onBlur={(e) => {
                setFieldTouched("grant.noOfOptions");
              }}
              value={form.grant.noOfOptions}
              type="number"
            />
            <Error
              visible={
                !!(touched?.grant?.noOfOptions && errors?.grant?.noOfOptions)
              }
              text={errors?.grant?.noOfOptions}
            />
            <Label className=" font-medium">Strike Price</Label>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) =>
                  setFieldValue("currency", e.target.value)
                }
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue(
                    "grant.strikePrice",
                    parseFloat(e.target.value)
                  );
                }}
                onBlur={(e) => {
                  setFieldTouched("grant.strikePrice");
                }}
                value={form.grant.strikePrice}
                type="number"
              />
            </HStack>
            <Error
              visible={
                !!(touched?.grant?.strikePrice && errors?.grant?.strikePrice)
              }
              text={errors?.grant?.strikePrice}
            />
            <Label className=" w-full font-medium">Vesting Schedule</Label>
            <IonSelect
              aria-label="fruit"
              className="form-select"
              placeholder="Select Vesting Schedule"
              mode="ios"
              interface="popover"
              autoSave="yes"
              value={form.grant?.vestingScheduleName}
              onIonChange={(e) =>
                setFieldValue("grant.vestingScheduleName", e.target.value)
              }
            >
              {vestingScheduleNames.map((name) => (
                <IonSelectOption key={name} value={name} className="text-xs">
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <Label className=" w-full font-medium">Date of Grant</Label>
            <Input
              onChange={(e) => {
                setFieldValue("grant.dateOfGrant", new Date(e.target.value));
              }}
              onBlur={(e) => {
                setFieldTouched("grant.dateOfGrant");
              }}
              value={format(new Date(form?.grant?.dateOfGrant || new Date()), "yyyy-MM-dd")}
              type="date"
            />
            <VStack className="items-center gap-1">
              <HStack className="gap-2 mt-6">
                <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                <p className="text-secondary text-xs font-medium">
                  Attach your grant letter
                </p>
              </HStack>
              <p className="text-xs">(optional)</p>
            </VStack>
            <HStack className="mt-28 items-center justify-center">
              <ButtonPrimary className=" px-8" onClick={openNextPage}>
                Next
              </ButtonPrimary>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GrantInformation;
