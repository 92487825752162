import { IonContent, IonImg, IonPage, useIonRouter, useIonToast } from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  ButtonPrimary,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { SignUp } from "../types/onBoarding";
import { useAuthenticationForm } from "../store/useAuthenticationForm";
import { useGetOtp } from "../queries/dashboard";
import { useAuthStore } from "../store/useAuthStore";

const InviteCode: React.FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();
  const setAuth = useAuthStore().setState;
  const { mutate: getOtp } = useGetOtp();
  const { touched, errors, setFieldTouched, form, setFieldValue } =
    useAuthenticationForm();
  function checkInviteCode() {
    if (!Object.keys(errors).length) {
      const signUpDto: SignUp = {
        phoneNumber: parseInt(form.id, 10)
          ? parseInt(form.id, 10).toString()
          : "",
        referralCode: form.referralCode || "",
        emailId: parseInt(form.id, 10) ? "" : form.id,
      };
      getOtp(signUpDto, {
        onSuccess: (data) => {
          setAuth((prev) => ({ ...prev, userId: data.data.userId }));
          router.push("/otp");
        },
        onError: () => {
          present({
            message: "Something went wrong",
            duration: 1500,
            position: "bottom",
            color: "danger",
            mode: "ios",
            animated: true,
          });
        },
      });
    }
  }
  return (
    <IonPage>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <button className="p-3 mt-1 text-black">
            <ChevronLeft size={20} onClick={() => router.goBack()} />
          </button>
          <VStack className="items-center">
            <IonImg className="w-[139px] h-[50.55px] mt-12" src={HissaIcon} />
            <HStack className="justify-center w-full mt-4">
              <h6 className="text-lg font-medium">Invite-Only</h6>
            </HStack>
            <VStack className="w-full px-4">
              <HStack className="mt-14 w-full">
                <h6 className="whitespace-nowrap text-lg font-medium">
                  Please give the invite code you received
                </h6>
              </HStack>
              <VStack className="mt-9 text-dark w-full gap-2">
                <Label className="font-medium">Invite Code</Label>
                <Input
                  onChange={(e) => {
                    setFieldValue("referralCode", e.target.value);
                  }}
                  onBlur={(e) => {
                    setFieldTouched("referralCode");
                  }}
                  value={form.referralCode}
                />
                <Error
                  visible={!!(touched.referralCode && errors.referralCode)}
                  text={errors?.referralCode}
                />
              </VStack>
            </VStack>
            <ButtonPrimary
              className="mt-44 px-12"
              onClick={
                checkInviteCode
              }
            >
              Submit
            </ButtonPrimary>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default InviteCode;
