import { zustandForm } from "./zustandForm";
import { z } from "zod";
import { GrantEvent, GrantEventType } from "../types/onBoarding";

const eventSchema = z.object({
  noOfOptions: z.number(),
  type: z.string(),
  date: z.date(),
});

const validationSchema = z.object({
  exercisedOptions: z.number(),
  exerciseEvents: eventSchema,
  dateOfExercise: z.date(),
  fairMarketValue: z.number(),
  taxSlab: z.string(),
  taxToBePaid: z.number(),
  exerciseCost: z.number(),
  employerId: z.number(),
  name: z.string(),
});

const initialGrantEventValues: GrantEvent = {
  date: new Date(),
  grantId: 0,
  noOfOptions: 0,
  type: GrantEventType.Exercise,
  name: "",
  fairMarketValue: 0,
  taxSlab: "",
};

const initialValues = {
  exercisedOptions: 0,
  exerciseEvents: [initialGrantEventValues],
  dateOfExercise: new Date(),
  fairMarketValue: 0,
  taxSlab: "",
  taxToBePaid: 0,
  exerciseCost: 0,
  employerId: 0,
  name: "",
};

export type ExerciseDetails = typeof initialValues;

export const useExerciseForm = zustandForm({
  initialValues,
  validationSchema,
});
