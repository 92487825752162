import { EmployerState } from "../types/onBoarding";
import { zustandForm } from "./zustandForm";
import { z } from "zod";

const scheduleSchema = z.object({
  percentage: z.number().default(100),
  interval: z.number().default(12),
  period: z.number().default(48),
});

const templateSchema = z.object({
  srNo: z.number(),
  name: z.string(),
  cliff: z.number(),
  schedules: scheduleSchema,
});

const grantSchema = z.object({
  noOfOptions: z.number().min(1, "Please Enter Number of Options"),
  strikePrice: z.number(),
  vestedOptions: z.number(),
  exercisedOptions: z.number(),
  vestingScheduleName: z.string(),
  dateOfGrant: z.date(),
  lastDateToExercise: z.date(),
  vestingSchedule: templateSchema,
  grantState: z.string(),
});

const initialScheduleValues = {
  srNo: 1,
  percentage: 0,
  interval: 0,
  period: 0,
};

const initialEmployerDetails = {
  name: "",
  latestSharePrice: 0,
  currency: "₹",
  state: EmployerState.Active,
  grant: {
    noOfOptions: 0,
    name: "",
    strikePrice: 0,
    vestedOptions: 0,
    exercisedOptions: 0,
    vestingScheduleName: "",
    vestingSchedule: {
      name: "",
      cliff: 12,
      schedules: [initialScheduleValues],
    },
    dateOfGrant: new Date(),
    lastDateToExercise: new Date(),
    grantState: "Accepted",
  },
};

export type OnboardingDetails = typeof initialEmployerDetails;

const employerSchema = z.object({
  name: z.string(),
  latestSharePrice: z.number({
    invalid_type_error: "Please fill the share price of the company",
  }),
  currency: z.string(),
  state: z.string(),
  grant: grantSchema,
});

export const useOnboardingForm = zustandForm({
  initialValues: initialEmployerDetails,
  validationSchema: employerSchema,
});
