/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import { useIonRouter, IonPage, IonHeader, IonToolbar, IonPopover, IonContent } from "@ionic/react";
import { ChevronLeft, MoreVerticalFill } from "akar-icons";
import { format } from "date-fns";
import _ from "lodash";
import { useState, useEffect } from "react";
import { HStack, VStack, Center } from "../components/utils";
import { useDashboard } from "../queries/dashboard";
import { useCompany } from "../store/useCompany";
import { ExerciseDetails } from "../store/useExerciseForm";
import { EmployerState, Grant, GrantEventType } from "../types/onBoarding";
import { getExerciseCost, getTaxToBePaid, getTaxPercentage } from "../utils";

const SeeExerciseDetails: React.FC = () => {
  const router = useIonRouter();
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const { data } = useDashboard();
  const name = router.routeInfo.pathname.split("/")[2];
  const { state: company } = useCompany();
  let employers = data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [];
  if (company && company !== "All") {
    employers = employers.filter(employer => employer.name === company);
  }
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const grantIdMap = new Map<number, Grant>(grants.map(g => ([g.id || -1, g])));
  const events = grants.map(g => g.events).flat();
  const filteredEvents = events.filter(e => e.name === name && e.type === GrantEventType.Exercise);
  const employer = employers.find(employer => employer.id === grants[0].employerId);
  const [exerciseDetails, setExerciseDetails] = useState<ExerciseDetails>();

  useEffect(() => {
    const exerciseDetails: ExerciseDetails = {
      dateOfExercise: filteredEvents[0]?.date,
      employerId: employer?.id || 0,
      exerciseCost: getExerciseCost(filteredEvents, grantIdMap),
      exercisedOptions: _.sumBy(filteredEvents, (event => event.noOfOptions)),
      exerciseEvents: filteredEvents,
      fairMarketValue: filteredEvents[0]?.fairMarketValue || 0,
      taxSlab: filteredEvents[0]?.taxSlab || "",
      taxToBePaid: getTaxToBePaid(filteredEvents, grantIdMap),
      name: filteredEvents[0]?.name || "",
    }
    setExerciseDetails(exerciseDetails);
  }, [])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between max-w-md mx-auto my-2">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => {
                router.push("/tabs/grants", "back");
              }} />
            )}
            <p className="text-primary text-lg font-semibold">{exerciseDetails?.name}</p>
            <button id="click-trigger" onClick={() => setIsPopOverOpen(true)}>
              <MoreVerticalFill strokeWidth={2} size={24} />
            </button>
            <IonPopover isOpen={isPopOverOpen} mode="md"
              onDidDismiss={e => setIsPopOverOpen(false)}
              trigger="click-trigger" triggerAction="click"
            >
              <IonContent class="ion-padding">
                <VStack className="items-center justify-center w-full gap-6 py-3 divide-y-0">
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="mdi:delete-outline"
                      className="focus:text-primary"
                      width="12"
                      height="14" />
                    <button
                      className="focus:text-primary font-medium text-black"
                    >
                      Delete
                    </button>
                  </HStack>
                </VStack>
              </IonContent>
            </IonPopover>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <HStack className=" items-center justify-center gap-1 px-2 py-4">
            <Center className="w-[40px] h-[40px] bg-gray-100 rounded-full">
              {employer?.name.charAt(0)}
            </Center>
            <VStack>
              <h6 className=" text-dark px-2 text-base font-normal">
                {employer?.name}
              </h6>
              <h6 className=" text-grey px-2 text-xs font-normal">
                Dated: {format(new Date(exerciseDetails?.dateOfExercise || new Date()), "dd MMMM yyyy")}
              </h6>
            </VStack>
          </HStack>
          <VStack className=" items-center px-4 mt-2">
            <HStack className=" rounded-xl w-full py-3 text-center border divide-x-2">
              <VStack className="w-1/2 gap-1">
                <h6 className="text-dark text-3xl font-medium">
                  {exerciseDetails?.exercisedOptions}
                </h6>
                <h6 className="text-grey text-xs font-normal">Total no. of options</h6>
              </VStack>
              <VStack className="w-1/2 gap-1">
                <h6 className="text-dark text-3xl font-medium">
                  <span className="font-sans text-xl">{employer?.currency}</span>
                  {exerciseDetails?.fairMarketValue}
                </h6>
                <h6 className="text-grey text-xs font-normal">Fair Market Value </h6>
              </VStack>
            </HStack>
          </VStack>
          <VStack className="px-4">
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Cost of Exercise:</span>
              <span className="text-dark">{employer?.currency} {(exerciseDetails?.exerciseCost || 0).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">No. of Options * Strike Price</span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="mt-7 justify-between">
              <span className="text-dark font-medium">Tax Slab: </span>
              <span className="text-dark">{getTaxPercentage(exerciseDetails?.taxSlab || "")}</span>
            </HStack>
            <span className="text-grey text-xs">Inclusive of Cost of Exercise and </span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Tax to be Paid: </span>
              <span className="text-dark">{employer?.currency} {(exerciseDetails?.taxToBePaid || 0).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">This is only an estimate of tax based on the salary slab. Final tax may be different and will be intimated by your company.</span>
            <div className=" bg-grey w-64 h-[1px] rounded-full mt-4" />
            <HStack className="justify-between mt-8">
              <span className="text-dark font-medium">Cost Incurred to Exercise: </span>
              <span className="text-dark">{employer?.currency} {((exerciseDetails?.exerciseCost || 0) + (exerciseDetails?.taxToBePaid || 0)).toFixed(2)}</span>
            </HStack>
            <span className="text-grey text-xs">Cost of Exercise + Tax</span>
          </VStack>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default SeeExerciseDetails;
