import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { iosTransitionAnimation } from "@ionic/core";
import "./index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Tabs from "./pages/Tabs";
import Verified from "./pages/Verified";
import ChooseActions from "./pages/ChooseActions";
import ProfileInfo from "./pages/ProfileInfo";
import { IntroSlides } from "./pages/IntroSlides";
import SignUp from "./pages/SignUp";
import CompanyInformation from "./pages/CompanyInformation";
import ExploreJourney from "./pages/ExploreJourney";
import GrantInformation from "./pages/GrantInformation";
import EvaluateGrantDetails from "./pages/EvaluateGrantDetails";
import SeeDetails from "./pages/SeeDetails";
import EvaluateTab from "./pages/EvaluateTab";
import SeeEvaluateDetails from "./pages/SeeEvaluateDetails";
import EvaluateDetails from "./pages/EvaluateDetails";
import CustomVestingSchedule from "./pages/CustomVestingSchedule";
import AddNewGrant from "./pages/AddNewGrant";
import AddNewCompany from "./pages/AddNewCompany";
import EditCompany from "./pages/EditCompany";
import EditGrant from "./pages/EditGrant";
import { init, track, setUserId } from "@amplitude/analytics-browser";
import { decodeAuthToken } from "./utils";
import { useAuthStore } from "./store/useAuthStore";
import OldCompanyGrantInformation from "./pages/OldCompanyGrantInformation";
import OldCompanyInformation from "./pages/OldCompanyInformation";
import AuthPage from "./pages/AuthPage";
import InviteCode from "./pages/InviteCode";
import Otp from "./pages/Otp";
import EvaluateExercise from "./pages/EvaluateExercise";
import CalculateTax from "./pages/CalculateTax";
import ConfirmExerciseDetails from "./pages/ConfirmExerciseDetails";
import EvaluateCashOut from "./pages/EvaluateCashOut";
import CalculateCashOutTax from "./pages/CalculateCashOutTax";
import ConfirmCashOutDetails from "./pages/ConfirmCashOutDetails";
import SeeExerciseDetails from "./pages/SeeExerciseDetails";
import SeeCashOutDetails from "./pages/SeeCashOutDetails";
import Faqs from "./pages/Faqs";
import Faq from "./pages/Faq";
import AddExercise from "./pages/AddExercise";
import AddCashOut from "./pages/AddCashOut";
import ConfirmEvaluateCashOutDetails from "./pages/ConfirmEvaluateCashOutDetails";
import ConfirmEvaluateExerciseDetails from "./pages/ConfirmEvaluateExerciseDetails";
import SeeEvaluateExerciseDetails from "./pages/SeeEvaluateExerciseDetails";
import SeeEvaluateCashOutDetails from "./pages/SeeEvaluateCashOutDetails";
import EditOffer from "./pages/EditOffer";
import SeeOldDetails from "./pages/SeeOldDetails";
import EditOldGrant from "./pages/EditOldGrant";

init("b279f8ad5f7cca38dc3a6d0460513858", undefined, {});
setupIonicReact();

function trackEvent(e: any) {
  if ((e.target as HTMLButtonElement)?.tagName !== "BUTTON") return;
  const actionName =
    (e.target as HTMLButtonElement).getAttribute("data-event-name") ||
    e.target.innerText;
  const screen = window.location.pathname;
  const eventName = screen + " " + actionName;
  const accesstoken = useAuthStore.getState().state.accessToken;
  if (accesstoken) {
    let { emailId } = decodeAuthToken(accesstoken);
    emailId = emailId.padStart(10, "_");
    setUserId(emailId);
  }
  track(eventName, undefined, {});
}

const App: React.FC = () => {
  const { isAuthenticated } = useAuthStore().state;
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet
          onClick={trackEvent}
          animation={iosTransitionAnimation}
        >
          <Route path="/tabs" component={Tabs} />
          <Route path="/signup" component={SignUp} />
          <Route path="/profileInfo" component={ProfileInfo} />
          <Route path="/chooseActions" component={ChooseActions} />
          <Route path="/grantInformation" component={GrantInformation} />
          <Route path="/companyInformation" component={CompanyInformation} />
          <Route path="/exploreJourney" component={ExploreJourney} />
          <Route path="/verified" component={Verified} />
          <Route path="/evaluate" component={EvaluateTab} />
          <Route
            path="/evaluateGrantDetails"
            component={EvaluateGrantDetails}
          />
          <Route path="/evaluateDetails" component={EvaluateDetails} />
          <Route path="/seeDetails/:id" component={SeeDetails} />
          <Route path="/seeOldDetails/:id" component={SeeOldDetails} />
          <Route
            path="/seeEvaluateDetails/:id"
            component={SeeEvaluateDetails}
          />
          <Route path="/addNewGrant" component={AddNewGrant} />
          <Route path="/addNewCompany" component={AddNewCompany} />
          <Route path="/editCompany/:id" component={EditCompany} />
          <Route path="/editGrant/:id" component={EditGrant} />
          <Route path="/editOldGrant/:id" component={EditOldGrant} />
          <Route path="/editOffer/:id" component={EditOffer} />
          <Route
            path="/customVestingSchedule"
            component={CustomVestingSchedule}
          />
          <Route
            path="/oldCompanyGrantInformation"
            component={OldCompanyGrantInformation}
          />
          <Route
            path="/oldCompanyInformation"
            component={OldCompanyInformation}
          />
          <Route path="/authPage" component={AuthPage} />
          <Route path="/inviteCode" component={InviteCode} />
          <Route path="/otp" component={Otp} />
          <Route path="/evaluateExercise" component={EvaluateExercise} />
          <Route path="/addExercise" component={AddExercise} />
          <Route path="/addCashOut" component={AddCashOut} />
          <Route path="/evaluateCashOut" component={EvaluateCashOut} />
          <Route path="/calculateTax" component={CalculateTax} />
          <Route path="/calculateCashOutTax" component={CalculateCashOutTax} />
          <Route path="/confirmExerciseDetails" component={ConfirmExerciseDetails} />
          <Route path="/confirmCashOutDetails" component={ConfirmCashOutDetails} />
          <Route path="/confirmEvaluateExerciseDetails" component={ConfirmEvaluateExerciseDetails} />
          <Route path="/confirmEvaluateCashOutDetails" component={ConfirmEvaluateCashOutDetails} />
          <Route path="/seeExerciseDetails/:name" component={SeeExerciseDetails} />
          <Route path="/seeCashOutDetails/:name" component={SeeCashOutDetails} />
          <Route path="/seeEvaluateExerciseDetails/:name" component={SeeEvaluateExerciseDetails} />
          <Route path="/seeEvaluateCashOutDetails/:name" component={SeeEvaluateCashOutDetails} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/faq/:name" component={Faq} />
          <Route exact path="/">
            <Redirect to="/intro" />
          </Route>
          {isAuthenticated ?
            <>
              <Route path="/intro">
                <Redirect to="/tabs" />
              </Route>
              <Route path="/signup">
                <Redirect to="/tabs" />
              </Route>
            </>
            :
            <Route path="/intro" component={IntroSlides} />
          }
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
