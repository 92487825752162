import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  Box,
  HStack,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useGetFaqs } from "../queries/dashboard";
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { FAQ } from "../types/onBoarding";

const Faqs: React.FC = () => {
  const router = useIonRouter();
  const { data } = useGetFaqs();
  const faqs = data?.data || [];
  const faqsFiltered = _.uniqBy(faqs, e => e.section).filter(faq => faq.section === "Offer Related Queries");
  const [filteredSections, setFilteredSections] = useState<FAQ[]>(faqsFiltered);
  function filterSections(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) {
      setFilteredSections(faqsFiltered);
    }
    setFilteredSections(faqsFiltered.filter(faq => faq.answer.toLowerCase().includes(e.target.value.toLowerCase()) || faq.question.toLowerCase().includes(e.target.value.toLowerCase()) || faq.section.toLowerCase().includes(e.target.value.toLowerCase())));
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-12" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Frequently Asked Questions</p>
            <div className="w-12"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <div className=" items-center mx-4 mt-4 border-2 rounded-lg shadow-md">
            <input className="h-11 focus:outline-none active:outline-none w-full px-2 bg-white outline-none" placeholder="Search" onChange={(e) => filterSections(e)} /></div>
          <Box className="mx-4 mt-6 border-2 rounded-lg">
            <VStack className=" items-center border shadow-md">
              {filteredSections.map((faq) => (
                <HStack key={faq.id} className="items-center justify-between w-full py-6" onClick={() => router.push(`/faq/${faq.section}`)}>
                  <div className="w-12"></div>
                  <h6>{faq.section}</h6>
                  <Icon
                    className="w-12"
                    icon="material-symbols:arrow-forward-ios-rounded"
                    width="20"
                  ></Icon>
                </HStack>
              ))
              }
            </VStack>
          </Box>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Faqs;
