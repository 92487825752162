import {
  IonContent,
  IonHeader,
  IonPage,
  IonPopover,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  HStack,
  VStack,
} from "../components/utils";
import { ChevronLeft, MoreVerticalFill } from "akar-icons";
import { useCompany } from "../store/useCompany";
import { useMemo, useState } from "react";
import { useDashboard, useDeleteGrant } from "../queries/dashboard";
import { format } from "date-fns";
import { Icon } from "@iconify/react";
import { EmployerState, GrantEventType } from "../types/onBoarding";

const SeeOldDetails: React.FC = () => {
  const { data } = useDashboard();
  const router = useIonRouter();
  let employers = useMemo(() => data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [data]);
  const { state: company } = useCompany();
  if (company && company !== "All") {
    employers = employers.filter(employer => employer.name === company);
  }
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const id = router.routeInfo.pathname.split("/")[2];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const { mutate: deleteGrant } = useDeleteGrant();
  const employer = employers.find(employer => employer.id === grant?.employerId);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  function handleDelete() {
    deleteGrant(grant?.id || 0, {
      onSuccess: () => {
        setIsPopOverOpen(false);
        router.push("/tabs/grants");
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">Details</p>
            <button className="w-6" id="click-trigger" onClick={() => setIsPopOverOpen(true)}>
              <MoreVerticalFill strokeWidth={2} size={24} />
            </button>
            <IonPopover isOpen={isPopOverOpen} mode="md"
              onDidDismiss={e => setIsPopOverOpen(false)}
              trigger="click-trigger" triggerAction="click"
            >
              <IonContent class="ion-padding">
                <VStack className="items-center justify-center w-full gap-6 py-3 divide-y-0">
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="clarity:edit-line"
                      className="focus:text-primary"
                      width="12"
                      height="12"
                    ></Icon>
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={() => {
                        setIsPopOverOpen(false);
                        router.push(`/editOldGrant/${id}`);
                      }}
                    >
                      Edit
                    </button>
                  </HStack>
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="mdi:delete-outline"
                      className="focus:text-primary"
                      width="12"
                      height="14" />
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </HStack>
                </VStack>
              </IonContent>
            </IonPopover>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md px-4 mx-auto">
          <p className="mt-1 text-lg font-medium text-center underline">
            {employer?.name}
          </p>
          <HStack className="mt-7 items-center justify-between py-4 text-center border divide-x-2 rounded-lg">
            <VStack className="w-1/2 gap-1">
              <h6 className="text-dark text-3xl font-medium">
                {grant?.noOfOptions}
              </h6>
              <h6 className="text-grey text-xs">Granted Options</h6>
            </VStack>
            <VStack className="w-1/2 gap-1">
              <h6 className="text-dark text-3xl font-medium">
                <span className="font-sans text-2xl">{employer?.currency}</span>
                {grant?.strikePrice}
              </h6>
              <h6 className="text-grey text-xs">Strike Price </h6>
            </VStack>
          </HStack>
          <VStack className="mt-11 gap-7">
            <HStack className="justify-between">
              <VStack>
                <h6 className="text-dark font-medium">Vested Options</h6>
                <h6 className="text-grey text-xs">Ensure vested options are excluding any exercised and cashed out options</h6>
              </VStack>
              <h6 className="text-dark">
                {grant?.events
                  .filter((e) => e.type === GrantEventType.Vesting)
                  .map((e) => e.noOfOptions)}
              </h6>
            </HStack>

            <HStack className="justify-between">
              <VStack>
                <h6 className="text-dark font-medium">Exercised Options</h6>
                <h6 className="text-grey text-xs">Exercise refers to purchasing the shares of the company by paying the exercise price.</h6>
              </VStack>
              <h6 className="text-dark">
                {grant?.events
                  .filter((e) => e.type === GrantEventType.Exercise)
                  .map((e) => e.noOfOptions)}
              </h6>
            </HStack>
            <HStack className="justify-between">
              <h6 className="text-dark font-medium">Last Date to Exercise</h6>
              <h6>{format(
                new Date(grant?.lastDateToExercise || new Date()),
                "dd MMM yyyy"
              )}</h6>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default SeeOldDetails;
