/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import AttachFile from "../assets/attachFile.svg";
import {
  ButtonPrimary,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useEffect, useState } from "react";
import { useDashboard, useUpdateGrant } from "../queries/dashboard";
import { format } from "date-fns";
import {
  GrantOnboarding,
  useAddOrEditGrantForm,
} from "../store/useAddGrantForm";
import {
  getVestingScheduleWithCustomTemplates,
} from "../utils";
import { EmployerState } from "../types/onBoarding";

const EditGrant: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const { mutate: updateGrant } = useUpdateGrant();
  const id = router.routeInfo.pathname.split("/")[2];
  const { data } = useDashboard();
  let employers = data?.data.employers || [];
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const employer = employers.find(
    (employer) => employer.id === grant?.employerId
  );
  const customVestingSchedules = employer?.customTemplates || [];
  const [editName, setEditName] = useState(false);
  const customVestingScheduleNames = customVestingSchedules.map(
    (vestingSchedule) => vestingSchedule.name
  );
  const vestingScheduleNames = [
    ...customVestingScheduleNames,
    "1 Year Cliff, Monthly Vesting, 4 Years",
    "1 Year Cliff, Quarterly Vesting, 4 Years",
    "1 Year Cliff, Yearly Vesting, 4 Years",
    "Custom(Set as default, fill it out later)",
  ];
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useAddOrEditGrantForm();
  useEffect(() => {
    setFieldValue("grantState", "Accepted");
    setFieldValue("name", grant?.name);
    setFieldValue("id", grant?.id);
    setFieldValue("employerId", grant?.employerId);
    setFieldValue("grantState", grant?.grantState);
    if (grant?.grantState && grant?.grantState !== "OfferedInAmount") {
      setFieldValue("noOfOptions", grant?.noOfOptions);
      setFieldValue("strikePrice", grant?.strikePrice);
      setFieldValue("grantDate", grant?.grantDate);
      setFieldValue("vestingScheduleName", grant?.template?.name);
      setFieldValue("vestingSchedule", getVestingScheduleWithCustomTemplates(grant?.template?.name || "", customVestingSchedules))
    }
  }, [grant, setFieldValue]);
  const fields = [
    "noOfOptions",
    "strikePrice",
    "grantDate",
    "vestingScheduleName",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }
  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const grant: GrantOnboarding = {
      id: form.id || 0,
      name: form.name,
      noOfOptions: form.noOfOptions,
      strikePrice: form.strikePrice,
      exercisedOptions: form.exercisedOptions,
      vestedOptions: form.vestedOptions,
      lastDateToExercise: form.lastDateToExercise,
      vestingScheduleName: form.vestingScheduleName || "",
      vestingSchedule: getVestingScheduleWithCustomTemplates(
        form.vestingScheduleName,
        customVestingSchedules
      ),
      grantDate: form.grantDate,
      grantState: form?.grantState,
      employerId: form?.employerId || 0,
      employerName: "",
      employerState: EmployerState.Active,
      currency: form.currency,
      latestSharePrice: 0,
    };
    updateGrant(grant, {
      onSuccess: () => {
        router.push("/tabs/grants");
        clearForm(form);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft
                className="w-6"
                size={20}
                onClick={() => router.goBack()}
              />
            )}
            {grant?.grantState === "Accepted" ?
              <p className="text-primary text-lg font-semibold">
                Edit Grant Details
              </p> : <p className="text-primary text-lg font-semibold">
                Grant Details
              </p>}
            <div className="w-6"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <p className="text-grey px-3 text-xs">You can edit the details before adding it in the grants, if any.</p>
          <HStack className="items-center px-3 mt-1">
            {!editName ? (<VStack><h6 className=" text-dark text-lg font-semibold underline" onClick={() => { setEditName(true) }}>
              {form.name}
            </h6>
              {grant?.grantState === "OfferedInAmount" && <h6 className=" text-dark text-xs">Compensation Amount: {employer?.currency}{grant?.noOfOptions}</h6>}
            </VStack>) : (
              <Input className="h-8 px-2 text-lg font-semibold text-black underline"
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                }}
                onBlur={() => { setEditName(false) }}
                value={form.name}
                type="text"
              />)}
          </HStack>
          <VStack className="w-full gap-3 px-3 mt-4">
            <Label className=" font-medium">No. of Options</Label>
            <Input
              onChange={(e) => {
                setFieldValue("noOfOptions", parseInt(e.target.value, 10));
              }}
              onBlur={(e) => {
                setFieldTouched("noOfOptions");
              }}
              value={form.noOfOptions}
              type="number"
            />
            <Error
              visible={!!(touched.noOfOptions && errors.noOfOptions)}
              text={errors?.noOfOptions}
            />
            <Label className=" font-medium">Strike Price</Label>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) => setFieldValue("currency", e.target.value)}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue("strikePrice", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("strikePrice");
                }}
                value={form.strikePrice}
                type="number"
              />
            </HStack>
            <Label className=" w-full font-medium">Vesting Schedule</Label>
            <IonSelect
              aria-label="fruit"
              className="form-select"
              placeholder="Select Vesting Schedule"
              mode="ios"
              interface="popover"
              autoSave="yes"
              value={form?.vestingScheduleName}
              onIonChange={(e) => {
                if (
                  e.target.value === "Custom(Set as default, fill it out later)"
                ) {
                  router.push("/customVestingSchedule");
                } else {
                  setFieldValue("vestingScheduleName", e.target.value);
                }
              }}
            >
              {vestingScheduleNames.map((name) => (
                <IonSelectOption key={name} value={name} className="text-xs">
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <Label className=" w-full font-medium">Date of Grant</Label>
            <Input
              onChange={(e) => {
                setFieldValue("grantDate", new Date(e.target.value));
              }}
              onBlur={(e) => {
                setFieldTouched("grantDate");
              }}
              value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
              type="date"
            />
            <HStack className="items-center justify-center gap-2 mt-6">
              <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
              <p className="text-secondary text-xs font-medium">
                Attach your grant letter
              </p>
            </HStack>
            <p className="mx-auto -mt-3 text-xs">(optional)</p>
            {grant?.grantState === "Accepted" ? (
              <><HStack className="mt-28 items-center justify-center text-sm">
                <ButtonPrimary className=" px-8" onClick={submitForm}>
                  Submit
                </ButtonPrimary>
              </HStack>
                <HStack className="items-center justify-center mt-8">
                  <button
                    className="text-primary text-sm"
                    onClick={() => {
                      localStorage.setItem("grantDetails", JSON.stringify(form));
                      router.push(`/editCompany/${form?.employerId}`);
                    }}
                  >
                    Edit Company Information
                  </button>
                </HStack>
              </>
            ) : (<HStack className="mt-28 items-center justify-center text-sm">
              <ButtonPrimary className=" px-8" onClick={() => {
                localStorage.setItem("grantDetails", JSON.stringify(form));
                router.push(`/editCompany/${form?.employerId}`);
              }}>
                Next
              </ButtonPrimary>
            </HStack>)}

          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditGrant;
