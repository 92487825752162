import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonImg,
  IonMenuButton,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import "./mySwiper1.css";
import { Bell, ChevronLeft, ChevronRight, Pencil } from "akar-icons";
import { Box, Center, HStack, VStack } from "../components/utils";
import { useDashboard, useGetFaqs, useVestings } from "../queries/dashboard";
import { DoughnutChart } from "./DoughnutChart";
import { VestingChart, VestingChartGrant } from "./VestingChart";
import LockIcon from "../assets/lock.svg";
import { useCompany } from "../store/useCompany";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatToCurrency } from "../utils";
import { Icon } from "@iconify/react";
import { Employer, EmployerState, FAQ, Grant, GrantEventType } from "../types/onBoarding";
import { format } from "date-fns";
import _ from "lodash";
import DOMPurify from "dompurify";

const HomeTab: React.FC = () => {
  const { data } = useDashboard();
  const router = useIonRouter();
  const employers = useMemo(() => data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [data]);
  const { state: company, setState: setCompany } = useCompany();
  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
  });
  const companyNames =
    employers.length > 1
      ? ["All", ...employers.map((e) => e.name)]
      : employers.map((e) => e.name);
  const employer = employers.find((employee) => employee.name === company);
  const grants = employer?.grants || [];
  const events =
    grants?.map((grant) => grant?.events.flat() || []).flat() || [];
  const vestingEvents = events.filter((e) => e.type === GrantEventType.Vesting);
  const grantWithZeroPercentage = grants.find(grant => {
    const schedules = grant.template?.schedules || [];
    const totalPercentage = _.sumBy(schedules, s => s.percentage);
    return totalPercentage === 0;
  });
  const { data: faqs } = useGetFaqs();
  const fabRef = useRef<HTMLIonFabElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const fab = fabRef.current;
      if (fab && !fab.contains(event.target as Node)) {
        if (isMenuOpen) {
          setIsMenuOpen(!isMenuOpen);
        }
        fab.close();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [fabRef, isMenuOpen]);
  return (
    <IonPage >
      <IonFab horizontal="end" vertical="bottom" slot="fixed" ref={fabRef}>
        <IonFabButton onClick={() => setIsMenuOpen(!isMenuOpen)} >
          <Icon
            icon="material-symbols:add"
            className="w-[56px] h-[36px]"
          />
        </IonFabButton>
        <IonFabList side="top">
          <Box className="right-52 relative w-64 whitespace-normal bg-white border-2 divide-y-2 rounded-lg shadow-md">
            <HStack className="justify-end">
              <button
                className="focus:text-primary p-5 text-black"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/evaluateGrantDetails");
                }}
              >
                Evaluate an offer
              </button>
            </HStack>
            <HStack className="justify-end">
              <button
                className="focus:text-primary p-5 text-black"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/grantInformation");
                }}
              >
                Add your current ESOPs
              </button>
            </HStack>
            <HStack className="justify-end">
              <button
                className="focus:text-primary p-4 ml-16 text-black"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen)
                  router.push("/oldCompanyGrantInformation");
                }}
              >
                Add your previous company ESOPs
              </button>
            </HStack>
          </Box>
        </IonFabList>
      </IonFab>
      <IonHeader >
        <IonToolbar>
          <HStack className={`items-center justify-between h-16 max-w-md mx-auto ${isMenuOpen ? "  opacity-10 bg-white" : ""}`}>
            <IonButtons slot="start">
              <IonMenuButton
                autoHide={false}
                className="w-12 text-3xl font-thin text-black"
              >
                <Icon icon="akar-icons:three-line-horizontal" />
              </IonMenuButton>
            </IonButtons>
            <p className="text-primary text-lg font-semibold">Home</p>
            <Bell className="w-12" strokeWidth={2} size={24} />
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={`max-w-md mx-auto mb-5 ${isMenuOpen ? "  opacity-10 bg-white" : ""}`}>
          <HStack className="items-center justify-between p-4 mt-4 align-middle">
            <h6 className="text-xl font-semibold">
              Hey {data?.data.fullName}!
            </h6>
            {employers.length > 0 && <IonSelect
              aria-label="fruit"
              className="h-9 w-40 mr-4 text-center border rounded-md"
              placeholder=""
              mode="ios"
              interface="popover"
              autoSave="yes"
              value={company}
              onIonChange={(e) => setCompany(e.target.value)}
            >
              {companyNames.map((name) => (
                <IonSelectOption key={name} value={name} className="text-xs">
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>}
          </HStack>
          {employers.length === 0 ?
            <>
              <HStack className="justify-center w-full mb-2">
                <h6>
                  No. of Offers: {data?.data.employers.map(e => e.grants).flat().filter(g => g?.grantState === "Offered" || g?.grantState === "OfferedInAmount").length}
                </h6>
              </HStack>
              <OffersCarousel employersData={data?.data.employers || []} />
              <FaqCarousel faqs={faqs?.data || []} />
            </>
            : <>
              <NetWorth />
              <DoughnutChart />
              {vestingEvents.length > 0 ? (
                <div className="mb-10"></div>
              ) : (grantWithZeroPercentage ? <VStack className="mt-7 items-center gap-2 mb-10 border rounded-lg shadow-md">
                <h6 className=" mt-4 text-lg font-medium">Vesting Schedule</h6>
                <h6 className="text-grey text-[11px] font-medium">Please enter vesting schedule to see number of vested options.</h6>
                <h6 className=" text-primary mb-4 text-sm font-medium underline" onClick={() => router.push("/customVestingSchedule")} >Set a Custom Vesting Schedule</h6>
              </VStack> : (
                <VestingScheduleCard />)
              )}
            </>
          }
          <VStack className="items-center mb-10">
            <IonImg className=" w-9 h-9" src={LockIcon} />
            <h6 className="text-secondary text-sm">100% Safe and Secure</h6>
            <h6 className="whitespace-nowrap text-[9px] text-grey">
              We adhere to the strictest security standards and are SOC 1 and
              SOC 2 compliant.
            </h6>
          </VStack>
        </div >
      </IonContent>
    </IonPage >
  );
};

export default HomeTab;

export function NetWorth() {
  const { netWorth } = useVestings();
  const router = useIonRouter();
  const { state: company } = useCompany();
  const { data: _data } = useDashboard();
  const employer = _data?.data.employers.find((e) => e.name === company);
  const sharePrice = !!(employer?.latestSharePrice);
  return (
    <VStack className=" items-center gap-2 py-2 mt-8">
      <h6 className="text-dark font-medium">Your Total Worth</h6>
      {!sharePrice ? (
        <>
          <h6 className="text-primary text-3xl font-medium">
            <span className="font-sans text-2xl">₹</span> XXXXXX.xx
          </h6>
          <button
            className="text-primary text-xs underline"
            onClick={() => {
              router.push(`/editCompany/${employer?.id}`);
            }}
            disabled={company === "All"}
          >
            Please mention your PPS to see your total worth
          </button>
        </>
      ) : (
        <h6 className="text-primary text-4xl font-medium">
          <span className="font-sans">{_data?.data.preferredCurrency}</span>
          {formatToCurrency(netWorth)}
        </h6>
      )}
    </VStack>
  );
}

export function VestingScheduleCard() {
  return (
    <VStack className="mt-7 mb-10 border rounded-lg shadow-md">
      <HStack className=" justify-between gap-2 p-4 align-top">
        <div>
          <h6 className=" text-lg font-medium">Vesting Schedule</h6>
          <h6 className=" text-sm text-gray-500">
            Dated: {format(new Date(), "dd MMMM yyyy")}
          </h6>
        </div>
      </HStack>
      <VestingChart />
    </VStack>
  );
}

function OffersCarousel({ employersData }: { employersData: Employer[] }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const router = useIonRouter();
  return (
    <Swiper
      spaceBetween={-135}
      slidesPerView={1}
      centeredSlides
      pagination={{
        dynamicBullets: true,
      }}
      effect={"coverflow"}
      grabCursor={true}
      modules={[Pagination]}
      onSlideChange={(swiper) => {
        setActiveIndex(swiper.activeIndex);
      }}
    >
      {
        employersData.map(employer => employer.grants || []).flat().filter(grant => grant.grantState === "Offered" || grant.grantState === "OfferedInAmount").map((grant, index) => (
          <SwiperSlide key={grant?.id} onClick={() => {
            router.push(`/seeEvaluateDetails/${grant?.id}`);
          }}>
            {grant.grantState === "Offered" ? (
              <VStack className={`max-w-md mx-16 mb-10 border rounded-lg transition-all duration-300 ${activeIndex === index ? `scale-100` : "scale-75"}`}>
                <Center className="bg-secondary h-5 text-xs text-white rounded-t-lg">{grant?.name}</Center>
                <HStack className=" items-center justify-center mt-1">
                  <h6 className=" text-grey text-xs font-normal">
                    Dated: {format(new Date(grant?.grantDate || new Date()), "dd MMMM yyyy")}
                  </h6>
                </HStack>
                <VStack className="gap-4 px-4">
                  <HStack className="items-center justify-between w-full mt-3 font-normal">
                    <p className="text-dark font-medium">Options:</p>
                    <p className="text-dark text-lg font-medium">
                      {grant?.noOfOptions}
                    </p>
                  </HStack>
                  <HStack className="items-center justify-between w-full mb-6 font-normal">
                    <p className="text-dark font-medium">Strike Price</p>
                    <p className="text-dark text-lg font-medium">{grant?.strikePrice}</p>
                  </HStack>
                </VStack>
              </VStack>) : (
              <VStack className={`max-w-md mx-16 mb-10 border rounded-lg transition-all duration-300 ${activeIndex === index ? `scale-100` : "scale-75"}`}>
                <Center className="bg-secondary h-5 text-xs text-white rounded-t-lg">{grant?.name}</Center>
                <HStack className=" items-center justify-center mt-1">
                  <h6 className=" text-grey text-xs font-normal">
                    Dated: {format(new Date(grant?.grantDate || new Date()), "dd MMMM yyyy")}
                  </h6>
                </HStack>
                <VStack className=" p-4">
                  <HStack className=" items-center justify-center mt-1">
                    <p className="text-dark font-medium">Compensation Amount:</p>
                  </HStack>
                  <HStack className=" items-center justify-center mt-1">
                    <p className="text-dark text-lg font-medium">
                      {employersData.find(employer => employer.id === grant.employerId)?.currency}
                    </p>
                    <p className="text-dark text-lg font-medium">
                      {grant?.noOfOptions}
                    </p>
                  </HStack>
                </VStack>
              </VStack>
            )}
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

function FaqCarousel({ faqs }: { faqs: FAQ[] }) {
  const [activeIndex, setActiveIndex] = useState(0);
  function SwiperButtonPrevious() {
    const swiper = useSwiper();
    return (
      <div className=" text-secondary overflow-hidden">
        <button onClick={() => swiper?.slidePrev()}>
          <ChevronLeft className="w-3 h-3" />
        </button>
      </div>)
  }
  function SwiperButtonNext() {
    const swiper = useSwiper();
    return (
      <div className="text-secondary overflow-hidden">
        <button onClick={() => swiper.slideNext()}>
          <ChevronRight className="w-3 h-3" />
        </button>
      </div>)
  }
  return (
    <div className="faq-carousel-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          clickable: true
        }}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}

      >
        {faqs
          .map((faq) => (
            <SwiperSlide key={faq?.id}>
              <VStack className="max-w-md mx-4 transition-all duration-300 border rounded-lg">
                <VStack className="divide-y">
                  <HStack className=" items-center justify-start gap-2 px-2">
                    <p className="text-dark p-1 text-lg font-medium">{faq.id}</p>
                    <div className=" bg-dark w-[1px] h-6" />
                    <div className="truncate" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.question) }} />
                  </HStack>
                  <HStack className="px-3">
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }} />
                  </HStack>
                </VStack>
                <HStack className="items-center justify-between w-full px-32 mt-4 align-middle">
                  <SwiperButtonPrevious />
                  <div className="text-grey text-xs">{activeIndex + 1}/{faqs.length}</div>
                  <SwiperButtonNext />
                </HStack>
              </VStack>
            </SwiperSlide>
          ))}

      </Swiper>
    </div>
  );
}

export function VestingScheduleCardGrant({ grant }: { grant?: Grant }) {
  const router = useIonRouter();
  return (
    <VStack className="mt-7 mb-10 border rounded-lg shadow-md">
      <HStack className=" justify-between gap-2 p-4 align-top">
        <div>
          <h6 className=" text-lg font-medium">Vesting Schedule</h6>
          <h6 className=" text-sm text-gray-500">
            Dated:{" "}
            {format(new Date(grant?.grantDate || new Date()), "dd MMMM yyyy")}
          </h6>
        </div>
        <Pencil
          strokeWidth={2}
          size={24}
          className="text-dark"
          onClick={() => router.push(`/editGrant/${grant?.id}`)}
        />
      </HStack>
      <VestingChartGrant grant={grant} />
    </VStack>
  );
}