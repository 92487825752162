import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  Box,
  HStack,
  VStack,
} from "../components/utils";
import { ChevronDown, ChevronLeft, ChevronUp } from "akar-icons";
import { Accordion } from "./Accordian";
import { useState } from "react";
import { useGetFaqs } from "../queries/dashboard";
import * as DOMPurify from 'dompurify';
import { FAQ } from "../types/onBoarding";

const Faq: React.FC = () => {
  const router = useIonRouter();
  const [text, setText] = useState<string>("Expand All");
  const [expanded, setExpanded] = useState<Set<number>>(new Set<number>());
  const section = router.routeInfo.pathname.split("/")[2];
  const { data } = useGetFaqs();
  const faqs = data?.data || [];
  const offerFaqs = faqs.filter(faq => faq.section === section);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-12" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">{section}</p>
            <div className="w-12"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <Box className="mx-2 mt-16 border-2 rounded-lg">
            <VStack className=" items-center px-2 border shadow-md">
              <HStack className=" items-center justify-between w-full mt-2">
                <div className="w-12"></div>
                <h6 className="text-dark justify-center font-medium">{section}</h6>
                <h6 className="text-primary text-end w-20 text-xs underline" onClick={() => {
                  if (text === "Expand All") {
                    setText("Collapse All")
                    setExpanded((prev) => {
                      const nextState = new Set(prev);
                      for (const faq of faqs) {
                        nextState.add(faq.id);
                      }
                      return nextState;
                    })
                  }
                  else if (text === "Collapse All") {
                    setText("Expand All")
                    setExpanded((prev) => {
                      const nextState = new Set(prev);
                      for (const faq of faqs) {
                        nextState.delete(faq.id);
                      }
                      return nextState;
                    })
                  }
                }}>{text}</h6>
              </HStack>
              <FAQAccordion expanded={expanded} faqs={offerFaqs} setExpanded={setExpanded} />
            </VStack>
          </Box>
        </div>
      </IonContent>
    </IonPage>
  );
};

const FAQAccordion = ({ expanded,
  faqs,
  setExpanded }: {
    expanded: Set<number>;
    faqs: FAQ[];
    setExpanded: React.Dispatch<React.SetStateAction<Set<number>>>;
  }) => {
  return (
    <>
      {faqs.map((faq) => (
        <div className=" min-w-full mt-2 border rounded-lg">
          <Accordion
            i={faq.id || 0}
            key={faq.id}
            expanded={expanded}
            className=" text-grey text-lg font-medium"
            header={
              <FAQHeader
                id={faq.id}
                question={faq.question}
                isOpen={expanded.has(faq.id || -1)}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            }
          >
            <FAQContent
              answer={faq.answer}
            />
          </Accordion>
        </div>
      ))
      }

    </>)
}

function FAQHeader({
  id,
  question,
  isOpen,
  expanded,
  setExpanded
}: {
  id: number;
  expanded: Set<number>;
  question: string;
  isOpen: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<Set<number>>>;
}) {

  return (
    <>
      <HStack className="items-center justify-between w-full">
        <div
          className={`p-2
        ${expanded.has(id) ? "text-primary" : "text-dark"}`}
          onClick={() =>
            setExpanded((prev) => {
              const nextState = new Set(prev);
              if (nextState.has(id)) {
                nextState.delete(id);
              } else {
                nextState.add(id);
              }
              return nextState;
            })
          }
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }} />
        {!isOpen ? (
          <ChevronDown
            className="mx-2 text-black"
            strokeWidth={2}
            width={16}
            height={10}
            size={20}
          />
        ) : (
          <ChevronUp
            className="mx-2 text-black"
            strokeWidth={2}
            width={16}
            height={10}
            size={20}
          />
        )}
      </HStack>
    </>

  );
}

function FAQContent({
  answer
}: {
  answer: string;
}) {
  return (
    <div
      id="faq-answers"
      className=" p-4"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
  );
}

export default Faq;
