import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { Box, ButtonPrimary, HStack, VStack } from "../components/utils";
import HissaIcon from "../assets/hissa.svg";
import { ChevronLeft } from "akar-icons";

const EvaluateOfferUploaded: React.FC = () => {
  const router = useIonRouter();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-7" size={20} onClick={() => router.goBack()} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <VStack className="max-w-md mx-auto">
          <Box className="text-dark px-4 mx-auto mt-56 text-sm text-center">
            Please wait for few hours till we extract the information and fill
            it for you to see the wealth forecast.
          </Box>
          <Box className="text-dark px-4 mx-auto mt-20 text-sm text-center">
            Till then you can login to track your current company ESOPs.
          </Box>
          <HStack className="items-center justify-center mt-3">
            <ButtonPrimary
              className="px-6"
              onClick={() => router.push("/grantInformation")}
            >
              Track Current Company ESOPs
            </ButtonPrimary>
          </HStack>
          <Box className="text-dark px-4 mx-auto mt-6 text-sm text-center">
            Or,{" "}
            <span
              className="text-primary"
              onClick={() => router.push("/chooseActions")}
            >
              Go back
            </span>
          </Box>
        </VStack>
      </IonContent>
    </IonPage>
  );
};

export default EvaluateOfferUploaded;
