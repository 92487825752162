import { EmployerState } from "../types/onBoarding";
import { zustandForm } from "./zustandForm";
import { z } from "zod";

const initialEmployerDetails = {
  name: "",
  latestSharePrice: 0,
  currency: "₹",
  state: EmployerState.Active,
};

export type OnboardingEmployerDetails = typeof initialEmployerDetails;

const employerSchema = z.object({
  name: z.string(),
  latestSharePrice: z.number({
    invalid_type_error: "Please fill the share price of the company",
  }),
  currency: z.string(),
});

export const useAddOrEditCompanyForm = zustandForm({
  initialValues: initialEmployerDetails,
  validationSchema: employerSchema,
});
