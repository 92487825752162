import { FunctionComponent } from "react";
import { Center, VStack } from "../components/utils";
import HissaIcon from "../assets/hissa.svg";
import Illustration1 from "../assets/illustration1.svg";
import { Icon } from '@iconify/react'
import { useSwiper } from "swiper/react";
import { IonImg, IonRippleEffect } from "@ionic/react";

const IntroScreen1: FunctionComponent = () => {
  const swiper = useSwiper();
  return (
    <Center className="h-screen bg-white">
      <VStack className="h-3/4 items-center gap-4 mx-10 text-black">
        <IonImg className=" w-[139px] h-[50.55px]" src={HissaIcon} />
        <IonImg
          className="sm:w-3/4 md:w-1/2 aspect-square"
          src={Illustration1}
        />
        <h6 className="text-xl font-medium">Track Your Grants</h6>
        <div className="ion-activatable relative flex items-center self-end justify-center w-20 h-20 mt-8 overflow-hidden border-2 rounded-full">
          <IonRippleEffect></IonRippleEffect>
          <button onClick={() => swiper.slideNext()}>
            <Icon
              icon="material-symbols:arrow-forward-ios-rounded"
              color="#E85936"
              width={32}
            />
          </button>
        </div>
      </VStack>
    </Center>
  );
};

export default IntroScreen1;

