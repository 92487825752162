import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { ForecastChart } from "./ForeCastChart";
import { HStack } from "../components/utils";
import { ChevronLeft } from "akar-icons";

const WealthForecast: React.FC = () => {
  const router = useIonRouter();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between max-w-md mx-auto my-2">
            <ChevronLeft
              className="w-7"
              size={20}
              onClick={() => {
                if (router.routeInfo.lastPathname === "/exploreJourney") {
                  router.push("/tabs/evaluate");
                } else {
                  router.goBack();
                }
              }}
            />
            <p className="text-primary text-lg font-semibold">Wealth Forecast</p>
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="max-w-md mx-auto mb-5">
          <ForecastChart />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WealthForecast;
