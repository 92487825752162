import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonPopover,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { HStack, VStack } from "../components/utils";
import { ChevronLeft, MoreVerticalFill } from "akar-icons";
import EvaluateIcon from "../assets/evaluate.svg";
import { VestingChartGrant } from "./VestingChart";
import { format } from "date-fns";
import { useDashboard, useDeleteGrant, useUpdateGrant } from "../queries/dashboard";
import { motion } from "framer-motion";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { EmployerState, Schedule } from "../types/onBoarding";
import _ from "lodash";
import { getVestingScheduleWithCustomTemplates } from "../utils";
import { GrantOnboarding } from "../store/useAddGrantForm";
const SeeEvaluateDetails: React.FC = () => {
  const router = useIonRouter();
  const id = router.routeInfo.pathname.split("/")[2];
  const { data } = useDashboard();
  let employers = data?.data.employers || [];
  const grants = employers.map((emp) => emp.grants || []).flat() || [];
  const grant = grants.find((grant) => (grant.id || 0).toString() === id);
  const employer = employers.find(employer => employer.id === grant?.employerId);
  const customVestingSchedules = employer?.customTemplates || [];
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const { mutate: deleteGrant } = useDeleteGrant();
  const { mutate: updateGrant } = useUpdateGrant();
  function handleDelete() {
    deleteGrant(grant?.id || 0, {
      onSuccess: () => {
        setIsPopOverOpen(false);
        router.push("/tabs/evaluate");
      },
      onError: (e) => console.error(e),
    });
  }

  function acceptGrant() {
    if (grant) {
      const onboardingGrant: GrantOnboarding = {
        id: grant.id || 0,
        name: grant.name,
        noOfOptions: grant.noOfOptions,
        strikePrice: grant.strikePrice,
        exercisedOptions: grant.exercisedOptions,
        vestedOptions: grant.vestedOptions,
        lastDateToExercise: grant.lastDateToExercise,
        vestingScheduleName: grant.template?.name || "",
        vestingSchedule: getVestingScheduleWithCustomTemplates(
          grant.template?.name || "",
          customVestingSchedules
        ),
        grantDate: grant.grantDate,
        grantState: "Accepted",
        employerId: grant?.employerId || 0,
        employerName: employer?.name || "",
        employerState: EmployerState.Active,
        currency: employer?.currency || "₹",
        latestSharePrice: employer?.latestSharePrice || 0,
      };
      updateGrant(onboardingGrant, {
        onSuccess: () => {
          router.push("/tabs/grants");
        },
        onError: (e) => console.error(e),
      });
    }

  }

  function getTotalPeriod(schedules: Schedule[]) {
    return _.sumBy(schedules, (schedule) => schedule.period);
  }
  const [presentAlert] = useIonAlert();
  return (
    <IonPage>
      <IonFab horizontal="end" vertical="bottom">
        <IonFabButton>
          <IonIcon
            icon={EvaluateIcon}
            className="w-[28px] h-[28px]"
            onClick={() => {
              router.push(`/tabs/evaluate/moneyForecast/${grant?.id}`);
            }}
          />
        </IonFabButton>
      </IonFab>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => router.goBack()} />
            )}
            <p className="text-primary text-lg font-semibold">{grant?.name}</p>
            <button className="w-6" id="click-trigger" onClick={() => setIsPopOverOpen(true)}>
              <MoreVerticalFill strokeWidth={2} size={24} />
            </button>
            <IonPopover isOpen={isPopOverOpen} mode="md"
              onDidDismiss={e => setIsPopOverOpen(false)}
              trigger="click-trigger" triggerAction="click"
            >
              <IonContent class="ion-padding">
                <VStack className="items-center justify-center w-full gap-6 py-3 divide-y-0">
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="clarity:edit-line"
                      className="focus:text-primary"
                      width="12"
                      height="12"
                    ></Icon>
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={() => {
                        setIsPopOverOpen(false);
                        router.push(`/editOffer/${id}`)
                      }}
                    >
                      Edit
                    </button>
                  </HStack>
                  <HStack className="items-center justify-center w-full gap-4">
                    <Icon
                      icon="mdi:delete-outline"
                      className="focus:text-primary"
                      width="12"
                      height="14" />
                    <button
                      className="focus:text-primary font-medium text-black"
                      onClick={
                        handleDelete
                      }
                    >
                      Delete
                    </button>
                  </HStack>
                </VStack>
              </IonContent>
            </IonPopover>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          {grant?.grantState === "Offered" ?
            (
              <>
                <VStack className=" items-center gap-2 px-4 mt-2">
                  <HStack className=" rounded-xl w-full p-2 text-center border divide-x-2">
                    <VStack className="w-1/2 gap-1">
                      <h6 className="text-dark text-3xl font-medium">
                        {grant?.noOfOptions}
                      </h6>
                      <h6 className="text-grey text-sm font-normal">Total no. of options</h6>
                    </VStack>
                    <VStack className="w-1/2 gap-1">
                      <h6 className="text-dark text-3xl font-medium">
                        <span className=" font-sans text-2xl">{employer?.currency}</span>
                        {grant?.strikePrice}
                      </h6>
                      <h6 className="text-grey text-sm font-normal">Strike price </h6>
                    </VStack>
                  </HStack>
                </VStack>
                <VStack className="gap-4 px-4">
                  <HStack className="justify-between mt-4">
                    <h6 className="text-dark font-medium">Grant Date:</h6>
                    <h6 className="">
                      {format(
                        new Date(grant?.grantDate || new Date()),
                        "dd MMM yyyy"
                      )}
                    </h6>
                  </HStack>
                  <HStack className="justify-between">
                    <h6 className="text-dark font-medium">Vesting Start Date:</h6>
                    <h6>
                      {format(
                        new Date(
                          grant?.grantDate || new Date()
                        ),
                        "dd MMM yyyy"
                      )}
                    </h6>
                  </HStack>
                  <HStack className="justify-between">
                    <h6 className="text-dark font-medium">Vesting Period:</h6>
                    <h6>{getTotalPeriod(grant?.template?.schedules || [])} months</h6>
                  </HStack>
                </VStack>
                <VStack className="mt-9 items-center">
                  <h6 className="text-dark mb-4 font-medium">Vesting Schedule</h6>
                  <VestingChartGrant grant={grant} />
                </VStack>
                <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-11" />
              </>) : (<>
                <VStack className="gap-4 px-4">
                  <HStack className="justify-between mt-4">
                    <h6 className="text-dark font-medium">Compensation Amount: </h6>
                    <h6 className="">
                      {employer?.currency}{grant?.noOfOptions}
                    </h6>
                  </HStack>
                  <HStack className="justify-between">
                    <h6 className="text-dark font-medium">Grant Date:</h6>
                    <h6>
                      {format(
                        new Date(
                          grant?.grantDate || new Date()
                        ),
                        "dd MMM yyyy"
                      )}
                    </h6>
                  </HStack>
                </VStack>
                <div className=" bg-grey w-full h-[1px] border-[1.6px] border-dashed mt-11" />
              </>)}
          <HStack className="justify-center w-full mt-8 text-xs text-white">
            <motion.button
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
              className="bg-green h-9 w-20 rounded-lg"
              onClick={() => {
                if (grant?.grantState === "Offered") {
                  presentAlert({
                    cssClass: "p-4",
                    header: "Do you want to change any information before converting this offer to a grant?",
                    buttons: [
                      {
                        text: "No, Continue",
                        handler: acceptGrant,
                      },
                      {
                        text: "Yes, Edit",
                        handler: () => router.push(`/editGrant/${grant?.id}`),
                      },
                    ],
                  })
                } else {
                  presentAlert({
                    cssClass: "",
                    mode: "ios",
                    header: "In order to accept and convert this offer into a grant, you will be redirected to fill additional information.",
                    buttons: [
                      {
                        text: "Confirm",
                        handler: () => router.push(`/editGrant/${grant?.id}`),
                      },
                    ],
                  })
                }

              }
              }
            >
              Accept
            </motion.button>
          </HStack>
          <HStack className="text-grey mb-4 px-4 mt-4 text-[11px]">Accepting this offer would change your current company to this.</HStack>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default SeeEvaluateDetails;
