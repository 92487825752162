import { useIonToast } from "@ionic/react";
import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import {
  getAll,
  signIn,
  addEmployer,
  addGrant,
  signUp,
  updateUser,
  updateGrant,
  saveInitialDetails,
  updateEmployer,
  deleteEmployer,
  addCustomTemplate,
  updateAll,
  checkWaitList,
  getOtp,
  verify,
  deleteGrant,
  getFaqs,
  saveEvaluateExerciseDetails,
  saveEvaluateCashOutDetails,
  getCurrencyRates,
  resendOtp,
} from "../api/dashboard";
import { generateProjections } from "../services/onboarding/generateProjectedVesting";
import { useCompany } from "../store/useCompany";
import {
  Currency,
  CustomRes,
  Employer,
  EmployerState,
  Grant,
  GrantEventType,
  VestingProjection,
} from "../types/onBoarding";
import { queryClient } from "./client";

export function useDashboard() {
  return useQuery({
    queryKey: "getAll",
    queryFn: getAll,
  });
}

export function useCurrencyRates() {
  return useQuery({
    queryKey: "getCurrencyRates",
    queryFn: getCurrencyRates,
  });
}

export function useUpdateAll() {
  return useMutation({
    mutationKey: "updateAll",
    mutationFn: updateAll,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useSaveInitialDetails() {
  return useMutation({
    mutationKey: "saveInitialDetails",
    mutationFn: saveInitialDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useSaveEvaluateExerciseDetails() {
  return useMutation({
    mutationKey: "saveEvaluateExerciseDetails",
    mutationFn: saveEvaluateExerciseDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useSaveEvaluateCashOutDetails() {
  return useMutation({
    mutationKey: "saveEvaluateCashOutDetails",
    mutationFn: saveEvaluateCashOutDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useSignUp() {
  return useMutation({
    mutationKey: "signup",
    mutationFn: signUp,
  });
}

export function useCheckWaitList() {
  return useMutation({
    mutationKey: "checkWaitList",
    mutationFn: checkWaitList,
  });
}

export function useGetOtp() {
  return useMutation({
    mutationKey: "getOtp",
    mutationFn: getOtp,
  });
}

export function useResendOtp() {
  return useMutation({
    mutationKey: "resendOtp",
    mutationFn: resendOtp,
  });
}

export function useVerify() {
  return useMutation({
    mutationKey: "verify",
    mutationFn: verify,
  });
}

export function useSignIn() {
  const [present] = useIonToast();
  return useMutation({
    mutationKey: "signIn",
    mutationFn: signIn,
    onError: () => {
      present({
        message: "Something went wrong",
        duration: 1500,
        position: "bottom",
        color: "danger",
        mode: "ios",
        animated: true,
      });
    },
  });
}

export function useUpdateUser() {
  return useMutation({
    mutationKey: "updateUser",
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useAddEmployer() {
  return useMutation({
    mutationKey: "addEmployer",
    mutationFn: addEmployer,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useAddGrant() {
  return useMutation({
    mutationKey: "addGrant",
    mutationFn: addGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useAddCustomTemplate() {
  return useMutation({
    mutationKey: "addCustomTemplate",
    mutationFn: addCustomTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useUpdateGrant() {
  return useMutation({
    mutationKey: "updateGrant",
    mutationFn: updateGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useUpdateEmployer() {
  return useMutation({
    mutationKey: "updateEmployer",
    mutationFn: updateEmployer,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useDeleteEmployer() {
  return useMutation({
    mutationKey: "deleteEmployer",
    mutationFn: deleteEmployer,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useDeleteGrant() {
  return useMutation({
    mutationKey: "deleteGrant",
    mutationFn: deleteGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("getAll");
      queryClient.refetchQueries("getAll");
    },
  });
}

export function useVestings() {
  const { data: _data } = useDashboard();
  const { data: currencyData } = useCurrencyRates();
  const { state: companyName } = useCompany();
  let employers: Employer[] =
    _data?.data.employers.filter(
      (employer) => employer.state !== EmployerState.Offered
    ) || [];
  if (companyName && companyName !== "All") {
    employers =
      _data?.data.employers.filter((e) => e.name === companyName) || [];
  }
  const grants = employers.map((employer) => employer.grants || []).flat();
  const vestings: VestingProjection[] = [];
  grants.forEach((grant) => {
    if (!grant) return;
    const _vestings = generateProjections({
      noOfOptions: grant.noOfOptions,
      cliffPeriod: grant.template?.cliff || 12,
      schedules: grant.template?.schedules || [],
      startDate: new Date(grant.grantDate),
    });
    _vestings.forEach((v) => {
      vestings.push({
        ...v,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Vesting,
      });
    });
    const eventVestings = grant.events.filter(
      (e) => e.type === GrantEventType.Vesting
    );
    const _eventVestings: VestingProjection[] = eventVestings.map((event) => ({
      accumulatedVestedOptionsForGrant: event.noOfOptions,
      accumulatedVestingPercentageForGrant: 1,
      date: event.date,
      cliffDate: true,
      intervalsPassed: 1,
      vestedOptions: event.noOfOptions,
      vestedPercentage: 1,
      isVestDate: true,
      employerId: grant.employerId || 0,
      grantId: grant?.id || 0,
      type: GrantEventType.Vesting,
    }));
    _eventVestings.forEach((v) => {
      vestings.push({
        ...v,
      });
    });
    const exerciseEvents = grant.events.filter(
      (e) => e.type === GrantEventType.Exercise
    );
    const _exerciseEvents: VestingProjection[] = exerciseEvents.map(
      (event) => ({
        accumulatedVestedOptionsForGrant: event.noOfOptions,
        accumulatedVestingPercentageForGrant: 1,
        date: event.date,
        cliffDate: true,
        intervalsPassed: 1,
        vestedOptions: event.noOfOptions,
        vestedPercentage: 1,
        isVestDate: true,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Exercise,
      })
    );
    _exerciseEvents.forEach((v) => {
      vestings.push({
        ...v,
      });
    });
    const cashedOutEvents = grant.events.filter(
      (e) => e.type === GrantEventType.Cashout
    );
    const _cashedOutEvents: VestingProjection[] = cashedOutEvents.map(
      (event) => ({
        accumulatedVestedOptionsForGrant: event.noOfOptions,
        accumulatedVestingPercentageForGrant: 1,
        date: event.date,
        cliffDate: true,
        intervalsPassed: 1,
        vestedOptions: event.noOfOptions,
        vestedPercentage: 1,
        isVestDate: true,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Cashout,
      })
    );
    _cashedOutEvents.forEach((v) => {
      vestings.push({
        ...v,
      });
    });

    const forfeitedEvents = grant.events.filter(
      (e) => e.type === GrantEventType.Lapse
    );
    const surrenderedEvents = grant.events.filter(
      (e) => e.type === GrantEventType.Surrender
    );
    const _surrenderedEvents: VestingProjection[] = surrenderedEvents.map(
      (event) => ({
        accumulatedVestedOptionsForGrant: event.noOfOptions,
        accumulatedVestingPercentageForGrant: 1,
        date: event.date,
        cliffDate: true,
        intervalsPassed: 1,
        vestedOptions: event.noOfOptions,
        vestedPercentage: 1,
        isVestDate: true,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Surrender,
      })
    );
    _surrenderedEvents.forEach((v) => {
      vestings.push({
        ...v,
      });
    });
    const _forfeitedEvents: VestingProjection[] = forfeitedEvents.map(
      (event) => ({
        accumulatedVestedOptionsForGrant: event.noOfOptions,
        accumulatedVestingPercentageForGrant: 1,
        date: event.date,
        cliffDate: true,
        intervalsPassed: 1,
        vestedOptions: event.noOfOptions,
        vestedPercentage: 1,
        isVestDate: true,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Lapse,
      })
    );
    _forfeitedEvents.forEach((v) => {
      vestings.push({
        ...v,
      });
    });
  });
  vestings.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
  let accumulatedVested = 0;
  vestings.forEach((v) => {
    accumulatedVested += v.vestedOptions;
    v.accumulatedVestedOptionsForGrant = accumulatedVested;
  });
  const filteredVesting = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Vesting
  );
  const forfeitedVestings = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Lapse
  );
  const surrenderedVestings = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Surrender
  );
  const exercisedVestings = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Exercise
  );
  const cashedOutVestings = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Cashout
  );
  const totalVested = _.sumBy(filteredVesting, (v) =>
    new Date(v.date) <= new Date() ? v.vestedOptions : 0
  );
  const totalForfeited = _.sumBy(forfeitedVestings, (f) => f.vestedOptions);
  const totalSurrendered = _.sumBy(surrenderedVestings, (s) => s.vestedOptions);
  const totalExercised = _.sumBy(exercisedVestings, (e) => e.vestedOptions);
  const totalCashedOut = _.sumBy(cashedOutVestings, (e) => e.vestedOptions);
  const totalGranted = _.sumBy(
    employers.map((employer) => employer.grants).flat(),
    (g) => g?.noOfOptions || 0
  );
  let netWorth = 0;
  filteredVesting.forEach((vesting) => {
    const grant = grants.find((grant) => grant.id === vesting.grantId);
    const employer = employers.find(
      (employer) => employer.id === vesting.employerId
    );
    if (grant && employer && new Date(vesting.date) <= new Date()) {
      netWorth +=
        vesting.vestedOptions *
        (employer.latestSharePrice - grant.strikePrice) *
        getCurrencyMultiplier(
          _data?.data.preferredCurrency || "₹",
          employer.currency,
          currencyData
        );
    }
  });
  return {
    filteredVesting,
    totalVested,
    totalGranted,
    totalSurrendered,
    totalForfeited,
    totalExercised,
    totalCashedOut,
    netWorth,
  };
}

export function useVestingsForForeCastChart() {
  const { data: dashBoardData, isLoading } = useDashboard();
  const { state: companyName } = useCompany();
  let employers: Employer[] =
    dashBoardData?.data.employers.filter(
      (employer) => employer.state === EmployerState.Active
    ) || [];
  if (companyName && companyName !== "All") {
    employers =
      dashBoardData?.data.employers.filter((e) => e.name === companyName) || [];
  }
  const grants = employers.map((employer) => employer.grants || []).flat();
  const vestings: VestingProjection[] = [];
  grants.forEach((grant) => {
    if (!grant) return;
    const _vestings = generateProjections({
      noOfOptions: grant.noOfOptions,
      cliffPeriod: grant.template?.cliff || 12,
      schedules: grant.template?.schedules || [],
      startDate: new Date(grant.grantDate),
    });
    _vestings.forEach((v) => {
      vestings.push({
        ...v,
        employerId: grant.employerId || 0,
        grantId: grant?.id || 0,
        type: GrantEventType.Vesting,
      });
    });
    const eventVestings = grant.events.filter(
      (e) => e.type === GrantEventType.Vesting
    );
    const _eventVestings: VestingProjection[] = eventVestings.map((event) => ({
      accumulatedVestedOptionsForGrant: event.noOfOptions,
      accumulatedVestingPercentageForGrant: 1,
      date: event.date,
      cliffDate: true,
      intervalsPassed: 1,
      vestedOptions: event.noOfOptions,
      vestedPercentage: 1,
      isVestDate: true,
      employerId: grant.employerId || 0,
      grantId: grant?.id || 0,
      type: GrantEventType.Vesting,
    }));
    _eventVestings.forEach((v) => {
      vestings.push({
        ...v,
      });
    });
  });
  vestings.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
  let accumulatedVested = 0;
  vestings.forEach((v) => {
    accumulatedVested += v.vestedOptions;
    v.accumulatedVestedOptionsForGrant = accumulatedVested;
  });
  const filteredVesting = vestings.filter(
    (vesting) => vesting.type === GrantEventType.Vesting
  );
  const totalGranted = _.sumBy(
    employers.map((employer) => employer.grants).flat(),
    (g) => g?.noOfOptions || 0
  );
  return {
    filteredVesting,
    totalGranted,
    isLoading,
  };
}

export function getVestingsForGrant(grant?: Grant) {
  const vestings: VestingProjection[] = [];
  const _vestings = generateProjections({
    noOfOptions: grant?.noOfOptions || 100,
    cliffPeriod: grant?.template?.cliff || 12,
    schedules: grant?.template?.schedules || [],
    startDate: new Date(grant?.grantDate || new Date()),
  });
  _vestings.forEach((v) => {
    vestings.push({
      ...v,
      employerId: grant?.employerId || 0,
      grantId: grant?.id || 0,
      type: GrantEventType.Vesting,
    });
  });
  vestings.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
  let accumulatedVested = 0;
  vestings.forEach((v) => {
    accumulatedVested += v.vestedOptions;
    v.accumulatedVestedOptionsForGrant = accumulatedVested;
  });
  return vestings;
}

export function getTotalVested(grant?: Grant) {
  const vestings: VestingProjection[] = [];
  const _vestings = generateProjections({
    noOfOptions: grant?.noOfOptions || 100,
    cliffPeriod: grant?.template?.cliff || 12,
    schedules: grant?.template?.schedules || [],
    startDate: new Date(grant?.grantDate || new Date()),
  });
  _vestings.forEach((v) => {
    vestings.push({
      ...v,
      employerId: grant?.employerId || 0,
      grantId: grant?.id || 0,
      type: GrantEventType.Vesting,
    });
  });
  vestings.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );
  let accumulatedVested = 0;
  vestings.forEach((v) => {
    accumulatedVested += v.vestedOptions;
    v.accumulatedVestedOptionsForGrant = accumulatedVested;
  });
  const totalVested = _.sumBy(vestings, (v) =>
    new Date(v.date) <= new Date() ? v.vestedOptions : 0
  );
  return totalVested;
}

export function useGetFaqs() {
  return useQuery({
    queryKey: "getFaqs",
    queryFn: getFaqs,
  });
}

function getCurrencyInWord(currency: string) {
  if (currency === "₹") {
    return "INR";
  } else if (currency === "¥") {
    return "CNY";
  } else if (currency === "€") {
    return "EUR";
  } else if (currency === "$") {
    return "USD";
  } else {
    return "INR";
  }
}

export const getCurrencyMultiplier = (
  currency: string,
  employerCurrency: string,
  currencyData: CustomRes<Currency[]> | undefined
) => {
  const currencies = currencyData?.data || [];
  const currencyMap = new Map<string, Currency>(
    currencies.map((currency) => [currency.currency, currency])
  );
  const currencyInWord = getCurrencyInWord(currency);
  const employerCurrencyInWord = getCurrencyInWord(employerCurrency);
  const currencyConversion = currencyMap.get(currencyInWord)?.rate || 1;
  const employerCurrencyConversion =
    currencyMap.get(employerCurrencyInWord)?.rate || 1;
  return currencyConversion / employerCurrencyConversion;
};
