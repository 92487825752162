import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
  useIonRouter,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import {
  VStack,
  Label,
  Input,
  HStack,
  ButtonPrimary,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import { useAddOrEditCompanyForm } from "../store/useAddCompanyForm";
import _ from "lodash";
import { useAddEmployer, useAddGrant } from "../queries/dashboard";
import { useAddOrEditGrantForm } from "../store/useAddGrantForm";

const AddNewCompany: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const router = useIonRouter();
  const { mutate: addGrant } = useAddGrant();
  const fields = ["currency", "latestSharePrice", "name"];
  const { mutate: addEmployer } = useAddEmployer();
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }
  const { form, setFieldValue, setFieldTouched, clearForm, errors } =
    useAddOrEditCompanyForm();
  const { form: addGrantForm, clearForm: clearGrantForm, setFieldValue: setGrantFieldValue, setFieldTouched: setGrantFieldTouched } = useAddOrEditGrantForm();
  function handleSubmit() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    if (addGrantForm && addGrantForm.noOfOptions > 0) {
      const grantData = _.cloneDeep(addGrantForm)
      grantData.name = "Grant 1";
      grantData.employerName = form.name;
      grantData.currency = form.currency;
      grantData.latestSharePrice = form.latestSharePrice;
      grantData.employerState = form.state;
      addGrant(grantData, {
        onSuccess: () => {
          router.push("/tabs/grants");
          clearGrantForm(addGrantForm);
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
    else {
      const data = _.cloneDeep(form);
      addEmployer(data, {
        onSuccess: () => {
          router.push("/tabs/profile");
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
  }

  function handleSkip() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    form.currency = "₹";
    form.latestSharePrice = 0;
    form.name = "";
    if (addGrantForm.noOfOptions > 0) {
      const grantData = _.cloneDeep(addGrantForm)
      grantData.name = "Grant 1";
      addGrant(grantData, {
        onSuccess: () => {
          router.push("/tabs/grants");
          clearGrantForm(addGrantForm);
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
    else {
      const data = _.cloneDeep(form);
      addEmployer(data, {
        onSuccess: () => {
          router.push("/tabs/profile");
          clearForm(form);
        },
        onError: (e) => console.error(e),
      });
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => {
                clearForm(form)
                router.goBack()
              }} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <h3 className="w-full px-1 mt-2 text-xl font-bold">Add Company</h3>
          <VStack className="mt-7 w-full h-full gap-3 px-3">
            <Label className="w-full font-medium">Company Name</Label>
            <Input
              onChange={(e) => {
                setFieldValue("name", e.target.value);
                setGrantFieldValue("employerName", e.target.value);
              }}
              onBlur={(e) => {
                setFieldTouched("name");
                setGrantFieldTouched("employerName");
              }}
              type="text"
            />
            <Label className="w-full mt-5 font-medium">Price Per Share</Label>
            <p className="text-grey text-start w-full text-xs font-light">
              A share price is the price of a single share of a number of
              saleable equity shares of a company.
            </p>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) => setFieldValue("currency", e.target.value)}
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue("latestSharePrice", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("latestSharePrice");
                }}
                value={form.latestSharePrice}
                type="number"
              />
            </HStack>
            <HStack className="mt-72 items-center justify-center">
              <ButtonPrimary className="px-12" onClick={handleSubmit}>
                Submit
              </ButtonPrimary>
            </HStack>
            <HStack className="items-center justify-center mt-8">
              <button className="text-primary" onClick={handleSkip}>
                Skip
              </button>
            </HStack>
          </VStack>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AddNewCompany;
