/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import HissaIcon from "../assets/hissa.svg";
import AttachFile from "../assets/attachFile.svg";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Center,
  Error,
  HStack,
  Input,
  Label,
  VStack,
} from "../components/utils";
import { ChevronLeft } from "akar-icons";
import _ from "lodash";
import { useAddOrEditGrantForm } from "../store/useAddGrantForm";
import { usePersonalInfoForm } from "./ProfileInfo";
import { format } from "date-fns";
import { useAddGrant, useDashboard } from "../queries/dashboard";
import { useCompany } from "../store/useCompany";
import { useEffect, useMemo, useState } from "react";
import { getOfferName, getVestingScheduleWithCustomTemplates } from "../utils";
import { EmployerState } from "../types/onBoarding";

const EvaluateGrantDetails: React.FC = () => {
  const currencies = ["₹", "$", "¥", "€"];
  const { data: _data } = useDashboard();
  const { mutate: addGrant } = useAddGrant();
  const { state: company, setState: setCompany } = useCompany();
  const employers = useMemo(() => _data?.data.employers.filter(employer => employer.state !== EmployerState.Offered) || [], [_data]);
  const section = "Offer Related Queries";
  const [editName, setEditName] = useState(false);
  const [isGrantedInAmount, setIsGrantedInAmount] = useState(false);

  useEffect(() => {
    if (!company) {
      if (employers.length > 0)
        setCompany(employers[employers.length - 1].name);
    }
  }, []);

  const router = useIonRouter();
  const vestingScheduleNames = [
    "1 Year Cliff, Monthly Vesting, 4 Years",
    "1 Year Cliff, Quarterly Vesting, 4 Years",
    "1 Year Cliff, Yearly Vesting, 4 Years",
  ];
  const { touched, errors, setFieldTouched, setFieldValue, form, clearForm } =
    useAddOrEditGrantForm();
  const { form: personalInfo, clearForm: clearPersonalInfo } =
    usePersonalInfoForm();
  const fields = [
    "noOfOptions",
    "currency",
    "strikePrice",
    "dateOfGrant",
    "vestingScheduleName",
  ];
  function touchAllFields() {
    fields.forEach((field) => setFieldTouched(field));
  }

  function checkErrors() {
    fields.forEach((field) => {
      if (Object.keys(errors).includes(field)) {
        return true;
      }
    });
    return false;
  }

  useEffect(() => {
    if (!form.name) setFieldValue("name", getOfferName(_data?.data.employers || []))
  }, [])

  function submitForm() {
    touchAllFields();
    const errors = checkErrors();
    if (errors) {
      // show some toast
      return;
    }
    const data = _.cloneDeep(form);
    data.employerId = 0;
    data.strikePrice = isGrantedInAmount ? 0 : data.strikePrice;
    data.vestingSchedule = isGrantedInAmount ? getVestingScheduleWithCustomTemplates("1 Year Cliff, Monthly Vesting, 4 Years", []) : getVestingScheduleWithCustomTemplates(form.vestingScheduleName, []);
    data.grantState = isGrantedInAmount ? "OfferedInAmount" : "Offered";
    data.employerState = EmployerState.Offered;
    addGrant(data, {
      onSuccess: (data) => {
        localStorage.setItem("offerId", data?.id?.toString() || "0");
        router.push("/exploreJourney");
        clearForm(form);
        clearPersonalInfo(personalInfo);
      },
      onError: (e) => console.error(e),
    });
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HStack className="items-center justify-between h-16 max-w-md mx-auto">
            {router.canGoBack() && (
              <ChevronLeft className="w-6" size={20} onClick={() => {
                clearForm(form)
                router.goBack()
              }} />
            )}
            <IonImg className=" w-[100.58px] h-[36.07px]" src={HissaIcon} />
            <div className="w-7"></div>
          </HStack>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="max-w-md mx-auto mb-5">
          <h3 className="w-full px-4 mt-1 text-xl font-bold">
            Hey {_data?.data.fullName}!
          </h3>
          <HStack className="items-center px-4 mt-3">
            {!editName ? (<h6 className=" text-lg font-semibold text-black underline" onClick={() => { setEditName(true) }}>
              {form.name}
            </h6>) : (
              <Input className="h-8 text-lg font-semibold text-black underline"
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                }}
                onBlur={() => { setEditName(false) }}
                value={form.name}
                type="text"
              />)}
          </HStack>
          <h4 className=" w-full px-4 mt-1 text-xs font-normal">
            <span className="text-grey">
              What are some helpful questions to ask my employer if I want to understand my ESOP offer better?
            </span>
            <span className="text-secondary underline" onClick={() => router.push(`/faq/${section}`)}>
              Click here to gain a better understanding
            </span>
          </h4>
          {!isGrantedInAmount ? (<VStack className="w-full gap-3 px-4 mt-4">
            <Label className=" w-full font-medium">No. of Options</Label>
            <Input
              onChange={(e) => {
                setFieldValue("noOfOptions", parseInt(e.target.value, 10));
              }}
              onBlur={(e) => {
                setFieldTouched("noOfOptions");
              }}
              value={form.noOfOptions}
              type="number"
            />
            <Error
              visible={!!(touched.noOfOptions && errors.noOfOptions)}
              text={errors?.noOfOptions}
            />
            <h6 className="text-secondary text-xs underline" onClick={() => { setIsGrantedInAmount(true) }}>Have you been granted stock options as an amount?</h6>
            <Label className=" w-full font-medium">Strike Price</Label>
            <HStack className=" min-w-max border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
              <IonSelect
                aria-label="fruit"
                className="w-1/5 h-10 font-sans min-w-[60px]"
                mode="ios"
                interface="popover"
                autoSave="yes"
                value={form.currency}
                onIonChange={(e) =>
                  setFieldValue("currency", e.target.value)
                }
              >
                {currencies.map((name) => (
                  <IonSelectOption key={name} value={name} className="text-xs">
                    {name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <input
                className="focus:outline-none active:outline-none w-full bg-white border-none outline-none"
                onChange={(e) => {
                  setFieldValue("strikePrice", parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("strikePrice");
                }}
                value={form.strikePrice}
                type="number"
              />
            </HStack>
            <Error
              visible={!!(touched.strikePrice && errors.strikePrice)}
              text={errors?.strikePrice}
            />
            <Label className=" w-full font-medium">
              Potential Joining Date
            </Label>
            <Input
              onChange={(e) => {
                setFieldValue("grantDate", new Date(e.target.value));
              }}
              onBlur={(e) => {
                setFieldTouched("grantDate");
              }}
              value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
              type="date"
            />
            <Label className=" w-full font-medium">Vesting Schedule</Label>
            <IonSelect
              aria-label="fruit"
              className="form-select"
              placeholder="Select Vesting Schedule"
              mode="ios"
              interface="popover"
              autoSave="yes"
              value={form.vestingScheduleName}
              onIonChange={(e) =>
                setFieldValue("vestingScheduleName", e.target.value)
              }
            >
              {vestingScheduleNames.map((name) => (
                <IonSelectOption key={name} value={name} className="text-xs">
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <Error
              visible={
                !!(touched.vestingScheduleName && errors.vestingScheduleName)
              }
              text={errors?.vestingScheduleName}
            />
            <HStack className="items-center justify-center mt-8">
              <ButtonPrimary className="px-6" onClick={submitForm}>
                Evaluate
              </ButtonPrimary>
            </HStack>
            <hr className="mt-6 border border-dashed"></hr>
            <p className="text-grey text-start w-full px-2 text-xs font-normal">
              If you're unaware of any above information, please provide your
              grant letter and we'll manually fill the information for you
              within hours.
            </p>
            <ButtonPrimary1 className=" w-full">
              <Center className=" mt-1 text-center">
                <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                <p className="text-secondary mx-2 font-semibold">
                  Attach your offer letter
                </p>
              </Center>
            </ButtonPrimary1>
          </VStack>) :
            (<VStack className="w-full gap-3 px-4 mt-4">
              <Label className=" font-medium">Compensation Offered</Label>
              <HStack className=" border-secondary focus:outline-secondary justify-between w-full px-2 border rounded-lg">
                <IonSelect
                  aria-label="fruit"
                  className="w-1/5 h-10 font-sans min-w-[60px]"
                  mode="ios"
                  interface="popover"
                  autoSave="yes"
                  value={form.currency}
                  onIonChange={(e) =>
                    setFieldValue("currency", e.target.value)
                  }
                >
                  {currencies.map((name) => (
                    <IonSelectOption key={name} value={name} className="text-xs">
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <Input
                  onChange={(e) => {
                    setFieldValue("noOfOptions", parseInt(e.target.value, 10));
                  }}
                  onBlur={(e) => {
                    setFieldTouched("noOfOptions");
                  }}
                  value={form.noOfOptions}
                  type="number"
                  className="focus:outline-none active:outline-none w-4/5 border-none outline-none"
                />
                <Error
                  visible={!!(touched.noOfOptions && errors.noOfOptions)}
                  text={errors?.noOfOptions}
                />
              </HStack>
              <h6 className="text-secondary text-xs underline" onClick={() => { setIsGrantedInAmount(false) }}>I have been granted ESOP Options</h6>
              <Label className=" w-full font-medium">
                Potential Joining Date
              </Label>
              <Input
                onChange={(e) => {
                  setFieldValue("grantDate", new Date(e.target.value));
                }}
                onBlur={(e) => {
                  setFieldTouched("grantDate");
                }}
                value={format(new Date(form?.grantDate || new Date()), "yyyy-MM-dd")}
                type="date"
              />
              <p className="text-dark text-xs font-medium">
                Disclaimer
              </p>
              <p className="text-grey text-xs">
                1: Please note that we have made an assumption that the strike price and price per share of the company is ₹1.
              </p>
              <p className="text-grey text-xs">
                2: Vesting Schedule will be set as default i.e. 1 Year Cliff, Monthly Vesting, 4 Years
              </p>
              <HStack className="items-center justify-center mt-8">
                <ButtonPrimary className="px-6" onClick={submitForm}>
                  Evaluate
                </ButtonPrimary>
              </HStack>
              <hr className="mt-6 border border-dashed"></hr>
              <p className="text-grey text-start w-full px-2 text-xs font-normal">
                If you're unaware of any above information, please provide your
                grant letter and we'll manually fill the information for you
                within hours.
              </p>
              <ButtonPrimary1 className=" w-full">
                <Center className=" mt-1 text-center">
                  <IonImg className=" w-[10px] h-[10px]" src={AttachFile} />
                  <p className="text-secondary mx-2 font-semibold">
                    Attach your offer letter
                  </p>
                </Center>
              </ButtonPrimary1>
            </VStack>)}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EvaluateGrantDetails;
