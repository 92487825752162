import { EmployerState } from "../types/onBoarding";
import { zustandForm } from "./zustandForm";
import { z } from "zod";

const initialEmployerDetails = {
  id: 0,
  latestSharePrice: 0,
  currency: "₹",
  name: "",
  state: EmployerState.Active,
};

const scheduleSchema = z.object({
  percentage: z.number().default(100),
  interval: z.number().default(12),
  period: z.number().default(48),
});

const initialValues = {
  id: "",
  dateOfBirth: "",
  designation: "",
  fullName: "",
  gender: "",
  phoneNumber: "",
  numberOfLoginTries: 0,
  isDeactivated: false,
  referralCode: "",
  profilePictureUrl: "",
  preferredCurrency: "₹",
  employers: [initialEmployerDetails],
};

const grantSchema = z.object({
  noOfOptions: z.number().min(1),
  strikePrice: z.number(),
  vestingScheduleName: z.string(),
  dateOfGrant: z.date(),
  vestingSchedule: scheduleSchema,
  grantState: z.string(),
});

const employerSchema = z.object({
  name: z.string(),
  latestSharePrice: z.number({
    invalid_type_error: "Please fill the share price of the company",
  }),
  currency: z.string(),
  state: z.string(),
  grant: grantSchema,
});

const validationSchema = z.object({
  id: z.string(),
  dateOfBirth: z.string(),
  designation: z.string(),
  fullName: z.string(),
  gender: z.string(),
  phoneNumber: z.string(),
  numberOfLoginTries: z.number(),
  isDeactivated: z.boolean(),
  profilePictureUrl: z.string(),
  referralCode: z.string(),
  preferredCurrency: z.string(),
  employers: employerSchema,
});

export type ProfileEditDetails = typeof initialValues;

export const useProfileEditForm = zustandForm({
  initialValues,
  validationSchema,
});
